import { useState } from 'react'

import { useMutation } from '@tanstack/react-query'

import { OpsCopilotWorkflow } from '@pages/ops-copilot/types'

import useAuth from 'hooks/useAuth'
import { queryClient } from 'queries/query-client'
import { queryErrorToast, querySuccessToast } from 'queries/query-toasts'
import { useWebSocketSubscription } from 'socket/useWebSocketSubscription'
import axiosServices from 'utils/axios'

export const useTriggerAutomap = () => {
  const { user } = useAuth()
  const [awaitingCopilotResponse, setAwaitingCopilotResponse] = useState(false)

  useWebSocketSubscription({
    channelName: 'OpsCopilotWorkflowCompletionChannel',
    subscriptionParams: {
      workflow: OpsCopilotWorkflow.AUTO_MAP_GL_ACCOUNTS,
      business_id: user?.business_id
    },
    onReceived: (data: any) => {
      setAwaitingCopilotResponse(false)
      querySuccessToast('Co-pilot Automap GL Accounts Completed')

      querySuccessToast('Automapped Successfully!')
      queryClient.invalidateQueries({ queryKey: ['fetch-auto-mapped'] })
      queryClient.invalidateQueries({
        queryKey: ['fetch-unmapped', user?.business_id]
      })
      queryClient.invalidateQueries({
        queryKey: ['fetch-gl-accounts-in-hierarchy', user?.business_id]
      })
      queryClient.invalidateQueries({
        queryKey: ['fetch-all-hierarchies-with-nodes', user?.business_id]
      })
    }
  })

  const mutation = useMutation({
    mutationFn: ({
      coaType
    }: {
      coaType: 'Income Statement' | 'Balance Sheet'
      hierarchyId?: number
    }) => {
      setAwaitingCopilotResponse(true)
      return axiosServices.post(
        `/businesses/${user?.business_id}/general_ledger_accounts/automap`,
        {
          coa_type: coaType
        }
      )
    },
    onError: (error) => {
      setAwaitingCopilotResponse(false)

      queryErrorToast(error.message || 'Automap failed!')
      queryClient.invalidateQueries({ queryKey: ['fetch-auto-mapped'] })
      queryClient.invalidateQueries({ queryKey: ['fetch-unmapped'] })
    }
  })

  const isPending = mutation.isPending || awaitingCopilotResponse

  return {
    ...mutation,
    isPending
  }
}
