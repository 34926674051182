import React from 'react'
import { createPortal } from 'react-dom'

import { DragOverlay, DropAnimation, useDndContext, useDraggable } from '@dnd-kit/core'

import { cn } from '@utils/style-utils'

import Button from '@components/core/button'
import { Text } from '@components/core/text'
import { DragIndicator, Icon } from '@components/icons'

interface DraggableItemProps<T> {
  items: T[]
  id: string
  getDragData: (items: T[]) => any
  renderText: (items: T[]) => React.ReactNode
  dropAnimation?: DropAnimation
}

export const DraggableItems = <T,>({
  items,
  id,
  getDragData,
  renderText
}: DraggableItemProps<T>) => {
  return (
    <div>
      <DraggableItem items={items} id={id} getDragData={getDragData} renderText={renderText} />
      <DraggableOverlay items={items} id={id} getDragData={getDragData} renderText={renderText} />
    </div>
  )
}

const DraggableOverlay = <T,>({
  items,
  id,
  getDragData,
  renderText,
  dropAnimation = {}
}: DraggableItemProps<T>) => {
  const { active } = useDndContext()

  return createPortal(
    <DragOverlay dropAnimation={dropAnimation}>
      {active && active?.data?.current?.type !== 'sort' ? (
        <DraggableItem
          items={items}
          id={id}
          getDragData={getDragData}
          renderText={renderText}
          dragging
          dragOverlay
        />
      ) : null}
    </DragOverlay>,
    document.body
  )
}

const DraggableItem = <T,>({
  items,
  id,
  getDragData,
  renderText,
  dragging,
  dragOverlay
}: DraggableItemProps<T> & { id: string; dragging?: boolean; dragOverlay?: boolean }) => {
  const { setNodeRef, listeners } = useDraggable({
    id,
    data: getDragData(items)
  })

  return (
    <div
      ref={setNodeRef}
      className={cn(
        'my-1 flex min-h-8 items-center rounded-[4px] border border-solid px-2',
        items.length > 0 ? 'border-primary bg-primary' : 'border-grey-lighter'
      )}
    >
      <Button
        variant='text'
        size='extra-small'
        {...(items.length > 0 && listeners)}
        className='p-0 focus:border-hidden'
      >
        <Icon
          icon={<DragIndicator />}
          className={cn(items.length > 0 ? 'text-white' : 'text-primary-darker')}
        />
      </Button>

      <Text
        variant='body'
        className={cn(
          'ml-1 align-middle text-[14px]',
          items.length > 0 && 'font-semibold text-white'
        )}
      >
        {renderText(items)}
      </Text>
    </div>
  )
}
