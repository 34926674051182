import React from 'react'

import { ICellRendererParams } from 'ag-grid-community'

import { Button } from '@components/core/button'
import NewWindow from '@components/icons/new-window.svg?react'

interface OpenInNewTabCellRendererProps extends ICellRendererParams {
  onActionButtonClick?: (params: ICellRendererParams) => void
  displayType?: 'icon' | 'text'
  displayText?: any
}

const OpenInNewTabCellRenderer: React.FC<OpenInNewTabCellRendererProps> = (params) => {
  const { displayType = 'icon', displayText } = params

  const onActionButtonClick = () => {
    if (params.onActionButtonClick) {
      params.onActionButtonClick(params)
    } else {
      window.open(`${window.location.href}/${params.node.data.id}`)
      params.api.deselectAll()
    }
  }

  const renderText = typeof displayText === 'function' ? displayText(params) : displayText

  return (
    <div className='flex size-full items-center'>
      <Button
        onClick={onActionButtonClick}
        variant={params.node.isSelected() ? 'primary' : 'text'}
        className='cursor-pointer p-0'
      >
        {displayType === 'icon' ? <NewWindow /> : renderText}
      </Button>
    </div>
  )
}

export default OpenInNewTabCellRenderer
