import { useQuery } from '@tanstack/react-query'

import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'

export interface Subsidiary {
  id: string
  name: string
  path: string
  name_id: number
  include_in_consolidation: boolean
  is_visible: boolean
  sort_order: number
  source_system: string
}

export const fetchSubsidiaries = async (businessId: number | undefined): Promise<Subsidiary[]> => {
  const response = await axiosServices.get(`/businesses/${businessId}/subsidiaries`)
  return response.data
}

export const useFetchSubsidiaries = () => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-subsidiaries', user?.business_id],
    queryFn: () => fetchSubsidiaries(user?.business_id)
  })
}
