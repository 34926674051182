import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router'

import { DisplayedColumnsChangedEvent, SideBarDef } from 'ag-grid-community'

import { useToggle } from '@uidotdev/usehooks'
import { isNotEqual } from '@utils/lodash'

import useChangeDetection from '@hooks/use-change-detection'

import { useDispatch, useSelector } from '@store/index'
import { selectTitle } from '@store/slices/component/basic-config'
import { selectPaginated } from '@store/slices/component/query-config'
import { selectTableConfig } from '@store/slices/component/table-config'
import {
  modifyColumnLayout,
  selectColumns,
  selectPivotMode
} from '@store/slices/component/table-config'

import { useQueryTransformedResultContext } from '../contexts/query-transformed-result-context'
import TableWidget from './table-widget'
import { getColumnLayoutChanges } from './utils'

export default function ResultTable() {
  const { data, headers, metadata, isFetching, resultsCount } = useQueryTransformedResultContext()

  const componentId = useParams<{ id: string }>().id as string
  const title = useSelector(selectTitle)
  const config = useSelector(selectTableConfig)
  const dispatch = useDispatch()
  const columns = useSelector(selectColumns)
  const pivotMode = useSelector(selectPivotMode)
  const [changeKey, triggerChange] = useChangeDetection()
  const [toolPanelVisible, toggleToolPanelVisible] = useToggle(true)
  const paginated = useSelector(selectPaginated)

  useEffect(() => triggerChange(), [config, triggerChange]) // refresh the grid when the config changes

  const sideBar = useMemo(() => {
    const sideBardDef: SideBarDef = {
      toolPanels: [
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            // Tool panel ordering does not work with pivot, so we disable it
            suppressSyncLayoutWithGrid: true,
            suppressColumnMove: true
          }
        }
      ],
      defaultToolPanel: toolPanelVisible ? 'columns' : ''
    }
    return sideBardDef
  }, [toolPanelVisible])

  const onDisplayedColumnsChanged = (event: DisplayedColumnsChangedEvent) => {
    const gridState = event.api.getState()
    const changes = getColumnLayoutChanges(gridState, columns, pivotMode)
    if (!changes) return

    dispatch(modifyColumnLayout(gridState))
  }

  return (
    <div className='flex size-full min-h-96 flex-col'>
      <TableWidget
        key={changeKey}
        componentId={+componentId}
        title={title}
        errorMessage=''
        loading={isFetching}
        isFetching={isFetching}
        data={{
          title,
          headers,
          config,
          metadata,
          results: data,
          results_count: resultsCount,
          drilldowns: [],
          paginated
        }}
        gridOptions={{
          sideBar,
          onDisplayedColumnsChanged,
          onToolPanelVisibleChanged: (event) => {
            if (isNotEqual(event.visible, toolPanelVisible)) toggleToolPanelVisible()
          }
        }}
      />
    </div>
  )
}
