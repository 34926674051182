import { useMemo } from 'react'
import { RefObject } from 'react'

import { IServerSideDatasource } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { useFetchDimensions } from '../../../queries/fetch-dimensions'
import { AUTO_FIT_TIMEOUT_MS } from '../../ag-grid'
import { autoFitColumnsWithAPI } from '../../ag-grid/utils'
import { Dimensions } from '../types'

interface UseDimensionDataSourceProps {
  dimensionKey: string
  gridRef: RefObject<AgGridReact>
  hasAutoFitRun: boolean
  setHasAutoFitRun: (value: boolean) => void
  selectedValues: string[]
  shouldBeServerSide: boolean
  quickFilterText?: string
}

export const useDimensionDataSource = ({
  dimensionKey,
  gridRef,
  hasAutoFitRun,
  setHasAutoFitRun,
  selectedValues,
  shouldBeServerSide,
  quickFilterText
}: UseDimensionDataSourceProps) => {
  const { data: dimensions } = useFetchDimensions()

  return useMemo(() => {
    let allRowData: Array<{ id: string; name: string }> = []

    return {
      getRows: (params: any) => {
        if (!dimensions) {
          params.failCallback()
          return
        }

        // Only compute allRowData once
        if (allRowData.length === 0) {
          const dimensionValues = dimensions[dimensionKey as keyof Dimensions] || {}
          allRowData = Object.entries(dimensionValues).map(([id, name]) => ({
            id,
            name
          }))
        }

        // Apply quick filter if text exists
        let filteredData = allRowData
        if (quickFilterText) {
          const searchText = quickFilterText.toLowerCase()
          filteredData = allRowData.filter((row) => row.name.toLowerCase().includes(searchText))
        }

        const startRow = params.request.startRow || 0
        const endRow = Math.min(params.request.endRow || filteredData.length, filteredData.length)
        const rowsThisPage = filteredData.slice(startRow, endRow)

        if (!hasAutoFitRun && gridRef.current?.api) {
          setTimeout(() => {
            autoFitColumnsWithAPI(gridRef.current!.api)
          }, AUTO_FIT_TIMEOUT_MS)
          setHasAutoFitRun(true)
        }

        params.success({
          rowData: rowsThisPage,
          rowCount: filteredData.length
        })
      }
    } as IServerSideDatasource
  }, [dimensions, dimensionKey, hasAutoFitRun, gridRef, setHasAutoFitRun, quickFilterText])
}
