import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootState } from '..'
import { rehydrateStore } from '../actions/rehydrate-store'

interface PropertyRenamingState {
  currentModel: string
  isModelFetchCompleted?: boolean
  hideAllFinalLabels?: boolean
  showStatisticsColumns?: boolean
  adjustHierarchy?: boolean
  showPath?: boolean
}

const initialState: PropertyRenamingState = {
  currentModel: '',
  isModelFetchCompleted: false,
  hideAllFinalLabels: false,
  showStatisticsColumns: false,
  adjustHierarchy: false,
  showPath: false
}

const propertyRenamingSlice = createSlice({
  name: '@PROPERTY_RENAMING',
  initialState,
  reducers: {
    updateCurrentModel: (state, action: PayloadAction<string>) => {
      state.currentModel = action.payload
    },
    updateIsModelFetchCompleted: (state) => {
      state.isModelFetchCompleted = true
    },
    toggleHideAllFinalLabels: (state) => {
      state.hideAllFinalLabels = !state.hideAllFinalLabels
    },
    toggleShowStatisticsColumns: (state) => {
      state.showStatisticsColumns = !state.showStatisticsColumns
    },
    toggleAdjustHierarchy: (state) => {
      state.adjustHierarchy = !state.adjustHierarchy
    },
    toggleShowPath: (state) => {
      state.showPath = !state.showPath
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrateStore, (state, action) => {
      return { ...initialState, ...action.payload.propertyRenaming, isModelFetchCompleted: false }
    })
  }
})

export const {
  updateCurrentModel,
  updateIsModelFetchCompleted,
  toggleHideAllFinalLabels,
  toggleShowStatisticsColumns,
  toggleAdjustHierarchy,
  toggleShowPath
} = propertyRenamingSlice.actions

export const propertyRenamingReducer = propertyRenamingSlice.reducer

export const selectCurrentModel = (state: RootState) => state.propertyRenaming.currentModel
export const selectIsFetchModelCompleted = (state: RootState) =>
  state.propertyRenaming.isModelFetchCompleted
export const selectHideAllFinalLabels = (state: RootState) =>
  state.propertyRenaming.hideAllFinalLabels
export const selectShowStatisticsColumns = (state: RootState) =>
  state.propertyRenaming.showStatisticsColumns

export const selectAdjustHierarchy = (state: RootState) => state.propertyRenaming.adjustHierarchy
export const selectShowPath = (state: RootState) => state.propertyRenaming.showPath
