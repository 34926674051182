import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import { FilterModel } from 'ag-grid-community'

import { useDebounce } from '@uidotdev/usehooks'
import { isNotBlank } from '@utils/lodash'

import { selectFilterModel } from '@store/slices/component/table-parameters'

import { IComponentResultsData } from 'pages/component-management/types'

import { IParameterizedConfig } from '../../types/query-builder-types'
import { IResultRow } from '../../types/shared-types'
import { ITableColumn, ITableConfig } from '../../types/table-builder-types'

const SHOW_FILTERS_THRESHOLD = 50

function isServerSideTable(
  config?: ITableConfig,
  extras?: {
    previousValue?: boolean
    rowData?: IResultRow[]
    parametersFilters?: FilterModel | null
    isFetching?: boolean
    parameterizedConfig?: IParameterizedConfig
    paginated?: boolean
    serversideTransformation?: boolean
  }
) {
  const {
    previousValue,
    rowData,
    parametersFilters,
    isFetching,
    parameterizedConfig,
    paginated,
    serversideTransformation
  } = extras || {}

  if (paginated) return true
  if (serversideTransformation) return false

  // initially, always show server side table: because if we switch from client side to server side,
  // the datasource will be registered and it will show loading spinner for a while even if the data is already there
  if (!config) return true

  const pivotMode = _.get(config, 'pivotMode', false)
  const columns = _.get(config, 'columns', []) as ITableColumn[]
  const grouping = _.some(columns, (column) => column.rowGroup)
  const externalFilters = _.get(config, 'externalFilters', {})

  // pivoting, grouping and external filters are not implemented in backend
  // if parameterized config and serverside filters are both enabled it makes UI confusing
  // so we disable serverside table in these cases
  if (pivotMode || grouping || isNotBlank(parameterizedConfig) || isNotBlank(externalFilters))
    return false

  // if we are fetching, keep the previous value
  if (isFetching) return previousValue

  // if row data exceeds the threshold, show server side table with filters
  if (rowData && rowData.length > SHOW_FILTERS_THRESHOLD) return true

  // if data is being filtered, show the previous type of table
  if (isNotBlank(parametersFilters)) return previousValue

  // if row data is less than the threshold, show client side table
  return false
}

export function useIsServerSideTable({
  data,
  isFetching,
  componentId
}: {
  data?: IComponentResultsData
  componentId: number
  isFetching: boolean
}) {
  const config = useMemo(() => _.get(data, 'config'), [data]) as ITableConfig
  const results = useMemo(() => _.get(data, 'results', [] as IResultRow[]), [data])
  const parameterizedConfig = useMemo(
    () => _.get(data, 'parameterized_config', {} as IParameterizedConfig),
    [data]
  )
  const paginated = _.get(data, 'paginated', false)
  const serversideTransformation = _.get(config, 'serversideTransformation', false)

  const parametersFilters = useSelector(selectFilterModel(`${componentId}`))

  const extraParams = useMemo(
    () => ({
      rowData: results,
      parametersFilters,
      isFetching,
      parameterizedConfig,
      paginated,
      serversideTransformation
    }),
    [
      results,
      parametersFilters,
      isFetching,
      parameterizedConfig,
      paginated,
      serversideTransformation
    ]
  )
  const debouncedExtras = useDebounce(extraParams, 0)

  const [serverSideTable, setServerSideTable] = useState(() =>
    isServerSideTable(config, { previousValue: true, ...debouncedExtras })
  )
  useEffect(() => {
    setServerSideTable((previousValue) =>
      isServerSideTable(config, { previousValue, ...debouncedExtras })
    )
  }, [config, debouncedExtras, paginated])

  return serverSideTable
}
