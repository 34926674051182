import { Dispatch, MutableRefObject, SetStateAction } from 'react'

import { ColDef, ICellRendererParams, IServerSideGetRowsParams } from 'ag-grid-community'

import { QueryClient } from '@tanstack/react-query'

import { LogGroup, logError } from '@store/actions/log'
import { MODEL_DOES_NOT_EXIST } from '@store/slices/component/query-config'

import { isEmptyAdvancedFilter } from 'pages/component-management/utils/is-empty-advanced-filter'
import { format as formatSql } from 'sql-formatter'
import { dispatch } from 'store'

import {
  StagedComponentBody,
  fetchComponentStagedData
} from '../../queries/fetch-component-staged-data'
import {
  IClientComponentParameters,
  IComponentAdvancedFilters,
  IComponentFormula,
  IComponentHiddenFilters,
  IComponentSortItem,
  IDataType,
  IHeader
} from '../../types/component-types'
import { IParameterizedConfig, IQueryData, ModelType } from '../../types/query-builder-types'

export default class ServerSideDatasource {
  firstLoad = true

  constructor(
    private client: QueryClient,
    private businessIdRef: MutableRefObject<number>,
    private queryDataRef: MutableRefObject<IQueryData>,
    private parametersRef: MutableRefObject<Partial<IClientComponentParameters>>,
    private hiddenFitlersRef: MutableRefObject<IComponentHiddenFilters>,
    private filtersStateRef: MutableRefObject<IComponentAdvancedFilters>,
    private sortStateRef: MutableRefObject<IComponentSortItem[] | null>,
    private formulasStateRef: MutableRefObject<IComponentFormula[]>,
    private parameterizedConfigRef: MutableRefObject<IParameterizedConfig>,
    private limitStateRef: MutableRefObject<number>,
    private setSqlQuery: Dispatch<SetStateAction<string>>,
    private setHiddenColumsDefs: Dispatch<SetStateAction<ColDef[]>>
  ) {}

  getRows(params: IServerSideGetRowsParams) {
    const { filterModel, sortModel } = params.request

    const queryData = this.queryData
    const filtersState = this.filtersState
    const sortState = this.sortState
    const hiddenFilters = this.hiddenFilters
    const formulasState = this.formulasState
    const parameterizedConfig = this.parameterizedConfig

    const currentFilterModel = filterModel as unknown as IComponentAdvancedFilters

    const body: StagedComponentBody = {
      query: queryData,
      filters: this.firstLoad
        ? filtersState
        : isEmptyAdvancedFilter(currentFilterModel)
          ? null
          : currentFilterModel,
      sorts: this.firstLoad ? sortState : sortModel,
      hidden_filters: hiddenFilters,
      parameters: this.parametersRef.current,
      formulas: formulasState,
      limit: this.limitStateRef.current,
      parameterized_config: parameterizedConfig
    }

    if (!body.query?.model || body.query?.model === MODEL_DOES_NOT_EXIST) {
      params.api.setGridOption('columnDefs', [])
      params.success({ rowData: [], rowCount: 0 })
      return
    }

    this.client
      .fetchQuery({
        queryKey: ['fetch-component-staged-data', this.businessIdRef.current, body],
        queryFn: () => {
          return fetchComponentStagedData(body, this.businessIdRef.current)
        }
      })
      .then((response) => {
        const { results, headers, metadata } = response
        this.formatAndSetSqlQuery(metadata.sql)
        const rowCount = results?.length || 0
        const columnDefs = this.getColumnDefs(headers)
        params.api.setGridOption('columnDefs', columnDefs)
        const hiddenColumnDefs = this.getHiddenColumnDefs(headers)
        this.setHiddenColumsDefs(hiddenColumnDefs)

        if (this.firstLoad) {
          this.firstLoad = false

          // sortModel is loaded first because it does not re-trigger the grid to fetch data
          this.initializeSorts(params)
          this.initializeFilters(params)
        }

        params.success({ rowData: results, rowCount })
      })
      .catch((err) => {
        params.fail()
      })
  }

  getColumnDefs(headers: IHeader[]) {
    return headers
      .filter((item) => !item.is_hidden)
      .map((item) => {
        const header: ColDef = {
          field: item.name,
          cellDataType: item.data_type,
          filterParams: {},
          suppressHeaderMenuButton: false,
          menuTabs: ['generalMenuTab']
        }

        if (item.data_type === IDataType.BOOLEAN) {
          header.filterParams.filterOptions = ['true', 'false', 'blank']
        }

        if (item.data_type === IDataType.DATE_STRING) {
          header.cellRenderer = (params: ICellRendererParams) => params.value
        }

        if (item.data_type === IDataType.NUMBER) {
          header.valueGetter = (params) => {
            if (!params.data) {
              return null
            }
            const value = params.data[item.name]
            if ([null, undefined, ''].includes(value)) {
              return null
            }
            return +value
          }

          header.valueFormatter = (params) => {
            return params.value === null ? '-' : params.value
          }
        }

        if (item.previous_period) {
          header.headerClass = 'ag-header-cell-previous-period'
        } else if (item.is_aggregate) {
          header.headerClass = 'ag-header-cell-aggregate'
        } else if (item.formula) {
          header.headerClass = 'ag-header-cell-formula'
        }

        return header
      })
  }

  getHiddenColumnDefs(headers: IHeader[]) {
    return headers
      .filter((item) => item.is_hidden)
      .map((item) => {
        const header: ColDef = {
          field: item.name,
          hide: true,
          cellDataType: item.data_type,
          filterParams: {},
          suppressHeaderMenuButton: true
        }

        switch (item.data_type) {
          case IDataType.NUMBER:
            header.filter = 'agNumberColumnFilter'
            break
          case IDataType.BOOLEAN:
            header.filter = 'agSetColumnFilter'
            header.filterParams.values = ['true', 'false', 'blank']
            header.filterParams.suppressSorting = true
            header.cellDataType = 'text'
            break
          case IDataType.DATE_STRING:
            header.filter = 'agDateColumnFilter'
            break
          case IDataType.TEXT:
          default:
            header.filter = 'agTextColumnFilter'
            break
        }
        header.filterParams.debounceMs = 1000

        return header
      })
  }

  initializeFilters(params: IServerSideGetRowsParams) {
    const filtersState = this.filtersStateRef.current

    // This will trigger onFilterChanged event, and make another request to the server.
    // https://stackoverflow.com/a/52781490/6892277
    params.api.setAdvancedFilterModel(filtersState || null)
  }

  initializeSorts(params: IServerSideGetRowsParams) {
    // this does not re-trigger the grid to fetch data
    params.api.applyColumnState({
      state: this.sortStateRef.current || [],
      defaultState: { sort: null }
    })
  }

  formatAndSetSqlQuery(sql?: string) {
    try {
      this.setSqlQuery(formatSql((sql || '').replace('\\"', '"'), { language: 'postgresql' }))
    } catch (error) {
      dispatch(
        logError({
          group: LogGroup.COMPONENT_MANAGEMENT,
          message: 'Error formatting SQL query',
          tags: { type: 'FORMAT_SQL', error: (error as Error)?.message || 'Unknown error' }
        })
      )
    }
  }

  get queryData() {
    return this.queryDataRef.current
  }

  get parameters() {
    return this.parametersRef.current
  }

  get hiddenFilters() {
    return this.hiddenFitlersRef.current
  }

  get filtersState() {
    return this.filtersStateRef.current
  }

  get sortState() {
    return this.sortStateRef.current
  }

  get formulasState() {
    return this.formulasStateRef.current
  }

  get parameterizedConfig() {
    return this.parameterizedConfigRef.current
  }

  get selectedModelType() {
    return this.queryData?.modelType || ModelType.table
  }
}
