import { useEffect, useMemo, useRef } from 'react'

import { ColDef, IServerSideGetRowsParams } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { QueryClient, useQueryClient } from '@tanstack/react-query'

import { toClassName } from '@utils/string-utils'

import StatusBar from '@components/ag-grid/status-bar'

import useAuth from '@hooks/useAuth'
import { useLatestRef } from '@hooks/useLatestRef'

import { useDispatch, usePageDispatch } from '@store/index'
import { setDataExportAsyncMode } from '@store/slices/action-bar'
import { modifyFilterModel, modifySortModel } from '@store/slices/component/table-parameters'

import AgGrid from 'components/ag-grid'
import ComponentWidget from 'components/cards/component-widget'
import { QUERY_STALE_TIME_SHORT } from 'queries/constants'

import {
  QUERY_KEY_PREFIX as RESULT_QUERY_KEY_PREFIX,
  fetchComponentResults
} from '../queries/fetch-component-result'
import {
  QUERY_KEY_PREFIX as STAGED_QUERY_KEY_PREFIX,
  fetchComponentStagedData
} from '../queries/fetch-component-staged-data'
import { IComponentResultsData } from '../types'
import { IComponentSorts } from '../types/component-types'
import { ITableColumn, ITableConfig } from '../types/table-builder-types'
import { ComponentPreviewProps } from '../viewer/types'
import { useBroadcastValue } from './hooks/use-broadcast-value'
import { useColDefs } from './hooks/use-col-defs'

class ServerSideDatasource {
  firstLoad = true

  constructor(
    private client: QueryClient,
    private queryBodyRef: any,
    private businessIdRef: React.MutableRefObject<number | undefined>,
    private componentId: number,
    private fromViewer: boolean,
    private selectRowOnDataRefreshedRef: React.MutableRefObject<() => void>
  ) {}

  async getRows(params: IServerSideGetRowsParams) {
    const offset = params.request.startRow || 0
    try {
      const response = this.fromViewer
        ? await this.fetchResultsData(offset)
        : await this.fetchStagedData(offset)

      const { results, results_count } = response
      const rowCount = results_count || results?.length || 0
      params.success({ rowData: results, rowCount })
      setTimeout(() => this.selectRowOnDataRefreshed(), 0)
    } catch (error) {
      params.fail()
    }

    if (this.firstLoad) {
      params.api.setFilterModel(this.initialFilterModel)
      params.api.applyColumnState({
        state: this.initialSortModel || [],
        defaultState: { sort: null }
      })
      this.firstLoad = false
    }
  }

  fetchStagedData = async (offset: number) => {
    const queryBody = _.cloneDeep(this.queryBody)
    if (queryBody.parameters) {
      queryBody.parameters.offset = offset
    }

    let response = this.client.getQueryData([
      STAGED_QUERY_KEY_PREFIX,
      this.businessIdRef.current,
      queryBody
    ]) as IComponentResultsData

    if (!response) {
      response = await this.client.fetchQuery({
        queryKey: [STAGED_QUERY_KEY_PREFIX, this.businessIdRef.current, queryBody],
        queryFn: () => fetchComponentStagedData(queryBody, this.businessIdRef.current as number),
        staleTime: QUERY_STALE_TIME_SHORT
      })
    }

    return response
  }

  fetchResultsData = async (offset: number) => {
    const queryBody = _.cloneDeep(this.queryBody)
    queryBody.offset = offset

    let response = this.client.getQueryData([
      RESULT_QUERY_KEY_PREFIX,
      this.businessIdRef.current,
      queryBody
    ]) as IComponentResultsData

    if (!response) {
      response = await this.client.fetchQuery({
        queryKey: [
          RESULT_QUERY_KEY_PREFIX,
          this.componentId,
          this.businessIdRef.current,
          queryBody
        ],
        queryFn: () =>
          fetchComponentResults(this.componentId, this.businessIdRef.current as number, queryBody),
        staleTime: QUERY_STALE_TIME_SHORT
      })
    }

    return response
  }

  get queryBody() {
    return this.queryBodyRef.current
  }

  get initialFilterModel() {
    return _.get(this.queryBody, 'filterModel', null)
  }

  get initialSortModel() {
    return _.get(this.queryBody, 'sortModel', [])
  }

  get selectRowOnDataRefreshed() {
    return this.selectRowOnDataRefreshedRef.current
  }
}

export function ServerSideTable(props: ComponentPreviewProps) {
  const { data, title, isFetching, gridOptions, queryBody, fromViewer, componentId } = props
  const dispatch = useDispatch()
  const pageDispatch = usePageDispatch()
  const queryClient = useQueryClient()
  const { user } = useAuth()
  const businessIdRef = useRef(user?.business_id)
  const queryBodyRef = useLatestRef(queryBody)

  const gridRef = useRef<AgGridReact | null>(null)
  const config = useMemo(() => _.get(data, 'config') as ITableConfig, [data])
  const paginated = _.get(data, 'paginated', false)

  const columns = useMemo(() => _.get(config, 'columns', []) as ITableColumn[], [config])
  const columnDefs = useColDefs({
    columns,
    gridRef,
    externalFiltersChanged: 0,
    showFilters: true,
    paginated: paginated
  })

  const { getBroadcastGridOptions, broadcastClassNames, selectRowOnDataRefreshed } =
    useBroadcastValue({ columns, gridRef })
  const selectRowOnDataRefreshedRef = useLatestRef(selectRowOnDataRefreshed)

  useEffect(() => {
    if (queryBody) {
      gridRef.current?.api?.refreshServerSide({ purge: true })
    }
  }, [queryBody])

  const defaultColDef = useMemo<ColDef>(
    () => ({
      filter: true,
      floatingFilter: true,
      sortable: true,
      suppressHeaderMenuButton: true
    }),
    []
  )

  useEffect(() => {
    if (paginated) {
      pageDispatch(setDataExportAsyncMode({}))
    }
  }, [paginated, pageDispatch])

  return (
    <ComponentWidget loading={isFetching} title={title}>
      <div className='size-full pb-3'>
        <AgGrid
          id={toClassName(`${props.componentId}-${title}`)}
          className={broadcastClassNames}
          style={{ height: '100%' }}
          ref={gridRef}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          removePivotHeaderRowWhenSingleValueColumn
          suppressServerSideFullWidthLoadingRow
          rowModelType='serverSide'
          rowBuffer={0}
          cacheBlockSize={500}
          cacheOverflowSize={5}
          maxConcurrentDatasourceRequests={1}
          infiniteInitialRowCount={500}
          maxBlocksInCache={10}
          onGridReady={(event) => {
            const dataSource = new ServerSideDatasource(
              queryClient,
              queryBodyRef,
              businessIdRef,
              componentId,
              fromViewer || false,
              selectRowOnDataRefreshedRef
            )
            event.api.setGridOption('serverSideDatasource', dataSource)
          }}
          onFilterChanged={(event) => {
            dispatch(
              modifyFilterModel({
                filterModel: event.api.getFilterModel(),
                componentId: `${props.componentId}`
              })
            )
          }}
          onSortChanged={(event) => {
            const sortModel = _.map(
              _.filter(event.api.getColumnState(), (s) => !!s.sort),
              (s) => _.pick(s, ['colId', 'sort', 'sortIndex'])
            ) as IComponentSorts
            dispatch(modifySortModel({ sortModel, componentId: `${props.componentId}` }))
          }}
          allowDragFromColumnsToolPanel
          suppressDragLeaveHidesColumns
          autoSizeStrategy={{ type: 'fitCellContents' }}
          persistColumnState
          {...gridOptions}
          {...getBroadcastGridOptions()}
          statusBar={{
            statusPanels: [
              {
                statusPanel: StatusBar,
                statusPanelParams: { aggFuncs: ['sum', 'avg', 'count'] }
              }
            ]
          }}
        />
      </div>
    </ComponentWidget>
  )
}
