import { useSelector } from '@store/index'
import { selectGenre } from '@store/slices/component/basic-config'
import { selectChartConfig } from '@store/slices/component/chart-config'
import { selectTableConfig } from '@store/slices/component/table-config'

import { IAxis, IChartConfig } from '../types/chart-builder-types'
import { ComponentGenre } from '../types/component-types'
import { ITableConfig } from '../types/table-builder-types'

function chartChangeDetectionKey(chartData: IChartConfig) {
  const keys: string[] = []
  const placeholder = '-'

  const xAxes = chartData.xAxes || []
  const yAxes = chartData.yAxes || []

  ;[...xAxes, ...yAxes].forEach((axis: IAxis) => {
    // basic axis properties
    keys.push(axis.name || placeholder)
    keys.push(axis.seriesType || placeholder)
    keys.push(axis.aggregate || placeholder)
    keys.push(axis.breakdown || placeholder)

    // waterfall x-axis bucket
    keys.push(axis.begin ? JSON.stringify(axis.begin) : placeholder)
    keys.push(axis.end ? JSON.stringify(axis.end) : placeholder)
    keys.push(axis.intermediate_nodes_type || placeholder)
    keys.push(JSON.stringify(axis.intermediate_nodes || []))
  })

  const key = keys.join('|')

  return `chart-${key}`
}

function tableChangeDetectionKey(tableData: ITableConfig) {
  const keys: string[] = []
  if (tableData.serversideTransformation) {
    keys.push(JSON.stringify(tableData.columns))
  }
  const key = keys.join('|')

  return `table-${key}`
}

export default function useServersideTransformKey({
  applyTransformations = false
}: { applyTransformations?: boolean } = {}) {
  const genre = useSelector(selectGenre)
  const chartConfig = useSelector(selectChartConfig)
  const tableConfig = useSelector(selectTableConfig)
  if (!applyTransformations) return ''

  switch (genre) {
    case ComponentGenre.chart:
      return chartChangeDetectionKey(chartConfig)
    case ComponentGenre.table:
      return tableChangeDetectionKey(tableConfig)
    default:
      return `${genre}-change-detection`
  }
}
