import React from 'react'

import { enumToOptions } from '@utils/obj-utils'

import { Tabs, TabsList, TabsTrigger } from '@core/tab'

interface EnumTabToggleProps<T extends Record<string, string>> {
  id: string
  enumObject: T
  value: T[keyof T]
  onChange: (value: T[keyof T]) => void
  renderLabel?: (value: T[keyof T], label: string) => React.ReactNode
}

export const EnumTabToggle = <T extends Record<string, string>>({
  id,
  enumObject,
  value,
  onChange,
  renderLabel
}: EnumTabToggleProps<T>) => {
  const options = enumToOptions(enumObject)

  return (
    <Tabs id={id} value={value as string} onValueChange={(val) => onChange(val as T[keyof T])}>
      <TabsList className='gap-2 p-1'>
        {options.map(({ value: optionValue, label }) => (
          <TabsTrigger
            key={optionValue}
            value={optionValue}
            className='w-36 border-none data-[state=active]:shadow-sm'
          >
            {renderLabel ? renderLabel(optionValue as T[keyof T], label) : label}
          </TabsTrigger>
        ))}
      </TabsList>
    </Tabs>
  )
}
