import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'

import { usePageSelector } from '@store/index'
import { selectParameterizedFilters } from '@store/slices/component/parameterized-filters'
import { selectFilterModel, selectSortModel } from '@store/slices/component/table-parameters'
import { selectExpandedDateFilterInfo } from '@store/slices/date-time-filter'
import { selectDimensions } from '@store/slices/dimension'

import ErrorBoundary from 'components/ErrorBoundary'
import { ComponentErrorWidget } from 'components/cards/component-widget'
import { DrilldownProvider, useActiveDrilldownDimensions } from 'contexts/drilldown-context'

import { useFetchComponentResults } from '../queries/fetch-component-result'
import { IComponentResultsData } from '../types'
import { ComponentGenre, IClientComponentParameters } from '../types/component-types'
import { IParameterizedConfig } from '../types/query-builder-types'
import { getComponentForGenre } from './utils'

export interface ViewerProps {
  id: number
  genre?: ComponentGenre
}

export const useParameterizedFilters = ({ firstFetch }: { firstFetch: boolean }) => {
  // parameterizedFilters is an object that contains current values for all the parameters
  // some components might not need all the parameters, so we need to filter out the ones that are not needed
  const parameterizedFilters = usePageSelector(selectParameterizedFilters)
  const parameterizedConfigRef = useRef<IParameterizedConfig>()
  const stringifiedParameterizedFilters = JSON.stringify(parameterizedFilters)

  const resolvedParameterizedFilters = useMemo(() => {
    return firstFetch
      ? parameterizedFilters
      : _.pick(parameterizedFilters, _.keys(parameterizedConfigRef.current))
    // eslint-disable-next-line react-hooks/exhaustive-deps -- recomputing only when stringified filters changes
  }, [stringifiedParameterizedFilters])

  return {
    setLatestParameterizedConfig: (config?: IParameterizedConfig) => {
      parameterizedConfigRef.current = config
    },
    resolvedParameterizedFilters
  }
}

function Index(props: ViewerProps) {
  const { id, genre = ComponentGenre.chart } = props

  const dateRange = usePageSelector(selectExpandedDateFilterInfo)
  const dimensionFilters = usePageSelector(selectDimensions)
  const [firstFetch, setFirstFetch] = useState(true)
  const [isCurrentFetching, setIsCurrentFetching] = useState(true)
  const [cachedData, setCachedData] = useState<IComponentResultsData>()
  const activeDrilldownDimensions = useActiveDrilldownDimensions()
  const { setLatestParameterizedConfig, resolvedParameterizedFilters } = useParameterizedFilters({
    firstFetch
  })
  const filterModel = useSelector(selectFilterModel(`${id}`))
  const sortModel = useSelector(selectSortModel(`${id}`))

  const [queryBody, setQueryBody] = useState<Partial<IClientComponentParameters>>({
    start_date: dateRange?.startDate,
    end_date: dateRange?.endDate,
    date_truncation: dateRange?.frequency,
    active_drilldowns: activeDrilldownDimensions,
    dimension_filters: dimensionFilters,
    parameterized_filters: resolvedParameterizedFilters,
    filter_model: filterModel,
    sort_model: sortModel,
    offset: 0
  })

  useEffect(() => {
    const newQueryBody = {
      start_date: dateRange?.startDate,
      end_date: dateRange?.endDate,
      date_truncation: dateRange?.frequency,
      active_drilldowns: activeDrilldownDimensions,
      dimension_filters: dimensionFilters,
      parameterized_filters: resolvedParameterizedFilters,
      filter_model: filterModel,
      sort_model: sortModel,
      offset: 0
    }

    if (!_.isEqual(queryBody, newQueryBody)) {
      setQueryBody(newQueryBody)
    }
  }, [
    queryBody,
    dateRange,
    dimensionFilters,
    activeDrilldownDimensions,
    resolvedParameterizedFilters,
    filterModel,
    sortModel
  ])

  const { data, isFetching, isFetched, isError, error } = useFetchComponentResults(id, queryBody)

  setLatestParameterizedConfig(
    _.get(
      data,
      'parameterized_config',
      // during loading, data is undefined so we need to get it from cachedData
      _.get(cachedData, 'parameterized_config')
    )
  )

  useEffect(() => {
    if (isFetched && cachedData) {
      setFirstFetch(false)
    }
  }, [isFetched, cachedData])

  useEffect(() => setIsCurrentFetching(isFetching), [isFetching])

  useEffect(() => {
    if (data) {
      setCachedData(data)
    }
  }, [data])

  useEffect(() => {
    if (isError) {
      setCachedData(undefined)
    }
  }, [isError])

  const title = _.get(cachedData, 'title', '')

  if (isError) {
    return (
      <ComponentErrorWidget
        errorMessage={error?.message || 'Some error occurred while fetching the data'}
        title={title}
      />
    )
  }

  const Component = getComponentForGenre(genre)

  return (
    <ErrorBoundary
      fallback={
        <ComponentErrorWidget
          errorMessage={`Some error occurred while rendering the ${genre}`}
          title={title}
        />
      }
    >
      <Component
        componentId={id}
        title={title}
        loading={firstFetch}
        data={cachedData}
        queryBody={queryBody}
        isFetching={isCurrentFetching}
        errorMessage=''
        fromViewer
      />
    </ErrorBoundary>
  )
}

const MemoViewer = memo(Index)

export default function Viewer(props: ViewerProps) {
  return (
    <DrilldownProvider componentId={`component_${props.id}`}>
      <MemoViewer {...props} />
    </DrilldownProvider>
  )
}
