import { useRef } from 'react'

import Button from '@core/button'
import { Popover, PopoverContent, PopoverTrigger } from '@core/popover'

import { Separator } from '@components/core/separator'
import { SwitchWithTitle } from '@components/core/switch-with-title'
import { ChargerForm, closePopover } from '@components/form/charger-form'
import { FieldTypes, FormLayoutTypes } from '@components/form/types'
import { ArrowDropdown, Icon } from '@components/icons'

import { useDispatch, useSelector } from '@store/index'
import {
  selectLimit,
  selectPaginated,
  setLimit,
  togglePaginated
} from '@store/slices/component/query-config'
import { resetTableConfig } from '@store/slices/component/table-config'

import * as Yup from 'yup'

const PAGINATION_LIMIT = 500

export function LimitButton({ onSubmit }: { onSubmit: () => void }) {
  const dispatch = useDispatch()
  const limit = useSelector(selectLimit)
  const paginated = useSelector(selectPaginated)
  const popoverTriggerRef = useRef<HTMLButtonElement>(null)

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button variant='outline' ref={popoverTriggerRef} className='pr-1'>
          {paginated ? (
            <>
              Infinite Scroll <Icon icon={<ArrowDropdown />} />
            </>
          ) : (
            <>
              Limit {limit} <Icon icon={<ArrowDropdown />} />
            </>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className='p-4'>
        <SwitchWithTitle
          id='infinite-scroll-setting'
          title='Infinite Scroll'
          checked={paginated}
          onCheckedChange={(value) => {
            dispatch(togglePaginated())
            if (value) {
              dispatch(setLimit(PAGINATION_LIMIT))
              dispatch(resetTableConfig())
            }
          }}
        />
        <Separator className='my-2' />
        <ChargerForm
          layout={FormLayoutTypes.FULL_WIDTH}
          fieldsConfig={[
            {
              label: 'Total Rows',
              name: 'totalRows',
              type: FieldTypes.NUMBER,
              required: true,
              placeholder: 'Enter Total Rows',
              validationSchema: Yup.number()
                .required('Required')
                .max(10_000, 'Cannot exceed 10,000 rows')
                .min(1, 'Must be at least 1 row'),
              defaultValue: `${limit}`,
              readOnly: paginated
            }
          ]}
          onSubmit={(values) => {
            dispatch(setLimit(_.toNumber(values.totalRows)))
            closePopover(popoverTriggerRef.current)
            onSubmit()
          }}
        />
      </PopoverContent>
    </Popover>
  )
}
