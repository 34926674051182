import { createContext, useContext } from 'react'

import useResultData from '../hooks/useResultData'
import { StagedComponentBody } from '../queries/fetch-component-staged-data'
import { IHeader, IMetadata } from '../types/component-types'
import { IResultRow } from '../types/shared-types'

export const QueryResultContext = createContext<{
  componentConfig?: StagedComponentBody
  dataRef: React.MutableRefObject<IResultRow[]>
  data: IResultRow[]
  resultsCount: number
  headers: IHeader[]
  visibleHeaders: IHeader[]
  metadata: IMetadata
  drilldowns: string[]
  dimensions: IHeader[]
  measures: IHeader[]
  dates: IHeader[]
  isFetching: boolean
  isSuccess: boolean
  isError?: boolean
  errorMessage?: string
} | null>(null)

export function useQueryResultContext() {
  const context = useContext(QueryResultContext)

  if (!context) {
    throw new Error('useQueryResultContext must be used within QueryResultProvider')
  }

  return context
}

export function QueryResultProvider({ children }: { children: React.ReactNode }) {
  const result = useResultData()

  return <QueryResultContext.Provider value={result}>{children}</QueryResultContext.Provider>
}
