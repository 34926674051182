import { Month } from './date'

export interface YearWeekInfo {
  year: number
  week: number
}

export interface YearInfo {
  year: number
}

export interface YearMonthInfo {
  year: number
  month: Month
}

export interface YearMonthDayInfo {
  year: number
  day: number
  month: Month
}

export enum Quarter {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4'
}

export interface YearQuarterInfo {
  year: number
  quarter: Quarter
}

export type DateInfo = YearInfo | YearMonthInfo | YearWeekInfo | YearQuarterInfo | YearMonthDayInfo

export type RangeType<T = DateInfo> = {
  startDate: T
  endDate: T
}

export type DateConfig = Partial<{
  [Frequency.Yearly]: RangeType<YearInfo>
  [Frequency.Monthly]: RangeType<YearMonthInfo>
  [Frequency.Weekly]: RangeType<YearWeekInfo>
  [Frequency.Quarterly]: RangeType<YearQuarterInfo>
  [Frequency.Daily]: RangeType<YearMonthInfo>
  [Frequency.MonthlyQuarterly]: RangeType<YearQuarterInfo>
}>

export enum Frequency {
  Yearly = 'Yearly',
  Quarterly = 'Quarterly',
  Monthly = 'Monthly',
  Weekly = 'Weekly',
  Daily = 'Daily',
  MonthlyQuarterly = 'MonthlyQuarterly'
}

export enum FrequencyAbbreviated {
  Yearly = 'Y',
  Quarterly = 'Q',
  Monthly = 'M',
  Weekly = 'W',
  Daily = 'D',
  MonthlyQuarterly = 'MQ'
}

export interface FrequencyInfo {
  [Frequency.Yearly]: YearInfo
  [Frequency.Monthly]: YearMonthInfo
  [Frequency.Weekly]: YearWeekInfo
  [Frequency.Quarterly]: YearQuarterInfo
  [Frequency.Daily]: YearMonthDayInfo
  [Frequency.MonthlyQuarterly]: YearQuarterInfo
}

export type DateRange =
  | RangeType<YearInfo>
  | RangeType<YearWeekInfo>
  | RangeType<YearMonthInfo>
  | RangeType<YearQuarterInfo>
  | RangeType<YearMonthDayInfo>

export enum DateRangeType {
  AsOfDate = 'asOfDate',
  Range = 'range'
}
export const isDateRangeTypeRange = (type: DateRangeType) => type === DateRangeType.Range
export const isDateRangeTypeAsOfDate = (type: DateRangeType) => type === DateRangeType.AsOfDate

export type DateFilterInfo = {
  frequency: Frequency
  fiscalYearStart?: Month
} & (
  | {
      rangeType: DateRangeType.AsOfDate
      endDate: DateInfo
    }
  | {
      rangeType: DateRangeType.Range
      startDate: DateInfo
      endDate: DateInfo
    }
)

export type FilterProps = {
  date: {
    active: boolean
    filterInfo?: DateFilterInfo
  }
}

export interface ExpandedDateFilterInfo {
  rangeType: DateRangeType
  startDate?: string
  endDate: string
  frequency: Frequency
  originalInfo: DateFilterInfo
}
