'use client'

import React, { useEffect, useState } from 'react'

import { getPropsRange, getTrailing12Months, getYearToDateRange } from '@utils/date-frequency-utils'

import { DateComponentPicker } from '@components/control-panel/calendar-filter-popover/date-component-picker'
import { ComponentRenderMap } from '@components/control-panel/calendar-filter-popover/types'

import { usePageDispatch, usePageSelector } from '@store/index'
import { selectExpandedDateFilterInfo, updateDateFilter } from '@store/slices/date-time-filter'

import {
  DateConfig,
  DateFilterInfo,
  DateInfo,
  DateRangeType,
  ExpandedDateFilterInfo,
  Frequency,
  isDateRangeTypeAsOfDate
} from '../../../types/filter'
import { BaseFilterProps } from '../types'

const DefaultFrequency: Frequency = Frequency.Monthly

interface DateRangeQuickSelectProps extends BaseFilterProps {
  onChange?: (dateFilterInfo: DateFilterInfo) => void
}

const DateRangeQuickSelect = (props: DateRangeQuickSelectProps) => {
  const { onChange } = props
  const pageDispatch = usePageDispatch()

  const expandedDateFilterInfo: ExpandedDateFilterInfo = usePageSelector(
    selectExpandedDateFilterInfo
  )
  const dateFilter: DateFilterInfo = _.get(expandedDateFilterInfo, 'originalInfo')

  const frequency: Frequency = dateFilter?.frequency || DefaultFrequency
  const fiscalYearStart = dateFilter.fiscalYearStart
  const asOfDate = isDateRangeTypeAsOfDate(dateFilter?.rangeType)

  const [dateConfig, setDateConfig] = useState<DateConfig>(() => {
    if (asOfDate) {
      return { [frequency]: { endDate: dateFilter.endDate } }
    }

    return { [frequency]: { startDate: dateFilter.startDate, endDate: dateFilter.endDate } }
  })

  useEffect(() => {
    setDateConfig(() => {
      if (asOfDate) {
        return { [frequency]: { endDate: dateFilter.endDate } }
      }
      return { [frequency]: { startDate: dateFilter.startDate, endDate: dateFilter.endDate } }
    })
  }, [dateFilter, asOfDate, frequency])

  const updatePickerDate = (
    frequency: Frequency,
    field: 'startDate' | 'endDate',
    newDate: DateInfo
  ) => {
    const updatedDate = {
      ...dateConfig,
      [frequency]: {
        ...dateConfig[frequency],
        [field]: newDate
      }
    }

    setDateConfig(updatedDate)

    const freqDate =
      updatedDate[frequency] ||
      (frequency === Frequency.Monthly
        ? getTrailing12Months(fiscalYearStart)
        : getYearToDateRange(frequency, fiscalYearStart))

    const updatedFilter: DateFilterInfo = {
      frequency,
      fiscalYearStart: fiscalYearStart,
      rangeType: DateRangeType.Range,
      startDate: freqDate?.startDate,
      endDate: freqDate?.endDate
    }

    if (onChange) {
      onChange(updatedFilter)
    } else {
      pageDispatch(updateDateFilter(updatedFilter))
    }
  }

  return (
    <>
      {['startDate', 'endDate']
        .filter((dateType) => dateType !== 'startDate' || !asOfDate)
        .flatMap((elem, index) => (index ? ['-', elem] : [elem]))
        .map((dateType) => {
          if (dateType === '-') {
            return (
              <div className='mx-2 flex items-center' key={dateType}>
                <span className='text-h5 text-grey-details'>-</span>
              </div>
            )
          }

          const propsRange = getPropsRange(frequency)

          const commonProps = {
            frequency: frequency,
            fiscalYearStart,
            value: dateConfig[frequency]?.[dateType as 'startDate' | 'endDate'],
            minAllowed:
              dateType === 'endDate' ? dateConfig[frequency]?.startDate : propsRange.minAllowed,
            maxAllowed:
              dateType === 'startDate' ? dateConfig[frequency]?.endDate : propsRange.maxAllowed,
            onChange: (info: DateInfo) => updatePickerDate(frequency, dateType as any, info),
            render: fiscalYearStart
              ? ((ComponentRenderMap as any)[frequency] as (info: DateInfo) => React.ReactNode)
              : void 0,
            className: 'h-6 text-details p-1'
          }

          return (
            <div key={dateType}>
              <DateComponentPicker key={dateType} {...commonProps} />
            </div>
          )
        })}
    </>
  )
}

export default DateRangeQuickSelect
