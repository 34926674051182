import { createContext, useContext } from 'react'

import useResultData from '../hooks/useResultData'
import { StagedComponentBody } from '../queries/fetch-component-staged-data'
import { IHeader, IMetadata } from '../types/component-types'
import { IResultRow } from '../types/shared-types'

export const QueryTransformedResultContext = createContext<{
  componentConfig?: StagedComponentBody
  dataRef: React.MutableRefObject<IResultRow[]>
  data: IResultRow[]
  resultsCount: number
  headers: IHeader[]
  visibleHeaders: IHeader[]
  metadata: IMetadata
  drilldowns: string[]
  dimensions: IHeader[]
  measures: IHeader[]
  dates: IHeader[]
  isFetching: boolean
  isSuccess: boolean
  isError?: boolean
  errorMessage?: string
} | null>(null)

export function useQueryTransformedResultContext() {
  const context = useContext(QueryTransformedResultContext)

  if (!context) {
    throw new Error(
      'useQueryTransformedResultContext must be used within QueryTransformedResultProvider'
    )
  }

  return context
}

export function QueryTransformedResultProvider({ children }: { children: React.ReactNode }) {
  const result = useResultData({ applyTransformations: true })

  return (
    <QueryTransformedResultContext.Provider value={result}>
      {children}
    </QueryTransformedResultContext.Provider>
  )
}
