import { useState } from 'react'

import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'

import { getDisplayName } from '@utils/schema-renaming'

import { useSelector } from '@store/index'
import { associationsPath, selectIsAssociationSelected } from '@store/slices/component/query-config'

import { useFetchRenamings } from '@pages/configuration/queries/fetch-renamings'

import type { IAssociationData, QueryBuilderPlacement } from '../../types/query-builder-types'
import FieldsChooser from './fields-chooser'

function Association({
  item,
  parent,
  placement
}: {
  item: IAssociationData
  parent: string
  placement?: QueryBuilderPlacement
}) {
  const isAssociationSelected = useSelector(
    selectIsAssociationSelected(item.name, parent, placement)
  )
  const [open, setOpen] = useState(isAssociationSelected)

  const { data: renamingsData } = useFetchRenamings(true)
  const renamings = renamingsData || {}

  const renamedModel = getDisplayName(renamings, item.model, undefined)
  const displayModelName = renamedModel ? `${item.name} (${renamedModel})` : item.name

  const handleChange = () => setOpen((x) => !x)

  return (
    <Accordion key={item.name} expanded={open} onChange={() => handleChange()}>
      <AccordionSummary sx={{ justifyContent: 'space-between', alignItems: 'center', pr: '12px' }}>
        <Typography variant='body1'>{displayModelName}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 1 }}>
        {open && (
          <FieldsChooser
            columns={item.columns}
            associations={item.associations}
            modelName={item.model}
            parent={`${associationsPath(parent)}.${item.name}`}
            placement={placement}
          />
        )}
      </AccordionDetails>
    </Accordion>
  )
}

export default function Associations({
  data,
  parent,
  placement
}: {
  data: IAssociationData[]
  parent: string
  placement?: QueryBuilderPlacement
}) {
  return (
    <>
      {data.map((item) => (
        <Association key={item.name} item={item} parent={parent} placement={placement} />
      ))}
    </>
  )
}
