import React, { useCallback, useEffect, useRef, useState } from 'react'

import { useDebounce } from '@uidotdev/usehooks'
import { DisplayNameSchema, getDisplayName } from '@utils/schema-renaming'
import { cn } from '@utils/style-utils'

import { SQLEditor } from '@components/code-editors'
import { ColumnSelector } from '@components/component-management/ColumnSelector'
import { CustomFieldPanel } from '@components/component-management/CustomFieldPanel'
import { DataPanel } from '@components/component-management/DataPanel'
import { DataTabs } from '@components/component-management/DataTabs'
import { ModelSelector } from '@components/component-management/ModelSelector'
import { ResizablePanel } from '@components/component-management/ResizablePanel'

import useAuth from '@hooks/useAuth'
import { useLatestRef } from '@hooks/useLatestRef'

import { useDispatch, useSelector } from '@store/index'
import { selectPending } from '@store/slices/component/basic-config'
import {
  MODEL_CUSTOM_SQL_QUERY,
  MODEL_KPI,
  chooseField,
  chooseModel,
  initQueryData,
  selectCurrentSelectedModel,
  selectHideUnusedColumns,
  selectHideUnusedTables,
  selectQueryData,
  selectSearchText,
  selectShowForeignKeys,
  setCustomSqlQuery as setCustomSqlQueryAction,
  setQueryData,
  setSearchText
} from '@store/slices/component/query-config'

import { Panel } from '@pages/component-management/common/panel'
import { PreviewPanelBody } from '@pages/component-management/data-panel/preview-panel-body'
import { UnderlyingDataPanel } from '@pages/component-management/data-preview/underlying-data-panel'

import { useFetchCustomField } from 'pages/component-management/queries/fetch-custom-field'
import { useFetchModels } from 'pages/component-management/queries/fetch-models'
import { useFetchModelsDetail } from 'pages/component-management/queries/fetch-models-detail'
import { useFetchRenamings } from 'pages/configuration/queries/fetch-renamings'

import { Wrapper } from '../common/wrapper'
import { IColumnData, ICustomField, IDataTab, ModelType } from '../types/query-builder-types'
import { selectDataTabsFromModel } from '../utils/select-data-tabs-from-model'
import { ModelPreview } from './model-preview'

export enum SelectionMode {
  Normal,
  Custom
}

function DataSelection() {
  const [selectionMode, setSelectionMode] = useState<SelectionMode>(SelectionMode.Normal)

  const { isPrimaryBusinessCharger } = useAuth()

  const { data: models, isLoading } = useFetchModels()
  const isComponentLoading = useSelector(selectPending)

  const dispatch = useDispatch()
  const selectedModelName = useSelector(selectCurrentSelectedModel())
  const { data: renamingsData } = useFetchRenamings(true)
  const renamings = (renamingsData || {}) as DisplayNameSchema
  const searchText = useSelector(selectSearchText)

  const hasCustomSqlQueryPermission = isPrimaryBusinessCharger()

  let filteredModels = models
  if (models && searchText) {
    filteredModels = _.filter(
      models,
      (model) =>
        model.name === selectedModelName ||
        model.name.toLowerCase().includes(searchText.toLowerCase())
    )
  }

  if (isLoading || !models || isComponentLoading) {
    return <div className='p-4'>Loading...</div>
  }

  if (!selectedModelName) {
    return (
      <div className='h-full p-4'>
        <ModelSelector
          models={filteredModels!}
          renderName={(name) => getDisplayName(renamings, name)}
          onSelect={(model) => {
            dispatch(chooseModel({ modelName: model.name, modelType: model.type }))
            dispatch(setSearchText(''))
          }}
          onNewSql={
            hasCustomSqlQueryPermission
              ? () =>
                  dispatch(
                    chooseModel({
                      modelName: MODEL_CUSTOM_SQL_QUERY,
                      modelType: ModelType.custom_sql_query
                    })
                  )
              : undefined
          }
        />
      </div>
    )
  }

  return (
    <ColumnSelectorView
      key={selectedModelName} // force the view to re-render when selected model changes
      modelName={selectedModelName}
      renderName={(modelName: string, columnName?: string) =>
        getDisplayName(renamings, modelName, columnName)
      }
      selectionMode={selectionMode}
      setSelectionMode={setSelectionMode}
      searchText={searchText}
      hasCustomSqlQueryPermission={hasCustomSqlQueryPermission}
    />
  )
}

function ColumnSelectorView({
  modelName,
  renderName,
  selectionMode,
  setSelectionMode,
  searchText = '',
  hasCustomSqlQueryPermission = false
}: {
  modelName: string
  renderName?: (modelName: string, columnName?: string) => string | undefined
  selectionMode: SelectionMode
  setSelectionMode: (mode: SelectionMode) => void
  searchText?: string
  hasCustomSqlQueryPermission: boolean
}) {
  const [selectedCustomFieldId, setSelectedCustomFieldId] = useState<number | undefined>()

  // tabs
  const [tabs, setTabs] = useState<IDataTab[]>([{ modelName, associationName: modelName }])
  const [pinnedTab, setPinnedTab] = useState<IDataTab>({ modelName, associationName: modelName })
  const [selectedTab, setSelectedTab] = useState<IDataTab | undefined>()

  const onTableExpand = useCallback((tab: IDataTab | undefined) => {
    setTabs((tabs) => _.compact(_.uniqBy([...tabs, tab], (curr) => curr?.associationName)))
    setSelectedTab(tab)
  }, [])

  const isRegularModel = modelName !== MODEL_KPI && modelName !== MODEL_CUSTOM_SQL_QUERY

  const isCustomMode = selectionMode === SelectionMode.Custom

  const { data, isLoading } = useFetchModelsDetail(modelName)

  const dispatch = useDispatch()
  const showForeignKeys = useSelector(selectShowForeignKeys)
  const hideUnusedTables = useSelector(selectHideUnusedTables)
  const hideUnusedColumns = useSelector(selectHideUnusedColumns)
  const selectedData = useSelector(selectQueryData())
  const selectedDataRef = useLatestRef(selectedData)
  const selectedCustomData = useSelector(selectQueryData('custom-field'))

  const [customSqlQuery, setCustomSqlQuery] = useState(
    selectedData?.customSqlQuery || '-- SQL Query Not Found'
  )
  const debouncedCustomSqlQuery = useDebounce(customSqlQuery, 500)
  const firstRender = useRef(true)
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }
    dispatch(setCustomSqlQueryAction({ customSqlQuery: debouncedCustomSqlQuery }))
  }, [debouncedCustomSqlQuery, dispatch])

  const onSelect = (column: IColumnData, path: string) =>
    dispatch(
      chooseField({
        field: { ...column, configs: [{}] },
        path,
        placement: isCustomMode ? 'custom-field' : undefined
      })
    )

  const dismissCustomFieldPanel = () => {
    setSelectedCustomFieldId(undefined)
    dispatch(initQueryData({ placement: 'custom-field' })) // clear working query data state
    setSelectionMode(SelectionMode.Normal) // then close the CustomFieldPanel
  }

  useEffect(() => {
    if (!data) return

    setTabs((tabs) =>
      _.uniqBy(
        _.concat(tabs, selectDataTabsFromModel(data, selectedDataRef.current)),
        (tab) => tab.associationName
      )
    )
  }, [data, selectedDataRef])

  const columnSelectorView = (
    <div
      className={cn(
        'h-full overflow-auto p-4',
        'pb-12' /* prevent content clipping with DataTabs */
      )}
    >
      <ColumnSelector
        model={data}
        modelName={modelName}
        renderName={renderName}
        showForeignKeys={showForeignKeys}
        hideUnusedTables={hideUnusedTables}
        hideUnusedColumns={hideUnusedColumns}
        loading={isLoading}
        onTableExpand={onTableExpand}
        onSelect={onSelect}
        onColumnEdit={(modelName: string, columnId?: number) => {
          setSelectedCustomFieldId(columnId)

          if (!columnId) {
            dispatch(
              chooseModel({
                modelName: modelName,
                modelType: selectedData?.modelType || ModelType.table,
                placement: 'custom-field'
              })
            )
          }

          setSelectionMode(SelectionMode.Custom)
        }}
        selectedData={selectedData}
        selectedCustomData={selectedCustomData}
        selectionMode={selectionMode}
        selectedCustomFieldId={selectedCustomFieldId}
        searchText={searchText}
      />
    </div>
  )

  const customSqlQueryView = (
    <div className='flex h-full flex-col overflow-hidden p-4'>
      <Panel title='Custom SQL Query' className='mb-4 min-h-0 flex-1 overflow-auto'>
        <SQLEditor
          value={customSqlQuery}
          onChange={setCustomSqlQuery}
          readOnly={!hasCustomSqlQueryPermission}
        />
      </Panel>
      <UnderlyingDataPanel />
    </div>
  )

  return (
    <ResizablePanel
      direction='vertical'
      primaryContent={
        <div className='relative flex size-full justify-between p-4'>
          <div className='-my-4 -ml-4 min-w-0 flex-1'>
            {modelName === MODEL_CUSTOM_SQL_QUERY ? customSqlQueryView : columnSelectorView}
          </div>

          {isCustomMode ? (
            <QueryCustomField customFieldId={selectedCustomFieldId}>
              {({ data, isLoading }) => (
                <CustomFieldPanel
                  modelName={selectedCustomData?.model}
                  data={data}
                  isLoading={isLoading}
                  selectedCustomData={selectedCustomData}
                  onSelect={onSelect}
                  renderName={renderName}
                  onCancel={dismissCustomFieldPanel}
                  onSave={dismissCustomFieldPanel}
                />
              )}
            </QueryCustomField>
          ) : (
            <DataPanel>
              <PreviewPanelBody />
            </DataPanel>
          )}

          {isRegularModel && (
            <div className='absolute bottom-0 left-0'>
              <DataTabs
                className='ml-[42px]'
                tabs={tabs}
                renderName={renderName}
                pinnedTab={pinnedTab}
                selectedTab={selectedTab}
                onPin={setPinnedTab}
                onChange={onTableExpand}
              />
            </div>
          )}
        </div>
      }
      secondaryContent={
        isRegularModel ? (
          <ResizablePanel
            direction='horizontal'
            primaryContent={<ModelPreview modelName={pinnedTab.modelName} />}
            secondaryContent={
              _.isEqual(pinnedTab, selectedTab) ? undefined : selectedTab?.modelName ? ( // no need to show duplicate panels for same tab
                <ModelPreview modelName={selectedTab.modelName} />
              ) : undefined
            }
          />
        ) : undefined
      }
      secondaryDefaultSize={20}
      minSize={2}
      autoSaveId='data-selection-bottom-panel'
      showToggle
    />
  )
}

function QueryCustomField({
  customFieldId,
  children
}: {
  customFieldId?: number
  children: (props: { data?: ICustomField; isLoading: boolean }) => React.ReactNode
}) {
  const dispatch = useDispatch()

  const { data, isLoading } = useFetchCustomField(customFieldId)

  useEffect(() => {
    if (data?.path) {
      // load data from the server into the working query data redux state
      dispatch(setQueryData({ queryData: data.path, placement: 'custom-field' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return <>{children({ data, isLoading })}</>
}

const withWrapper =
  <T extends React.ComponentType<any>>(WrappedComponent: T) =>
  (props: React.ComponentProps<T>) => (
    <Wrapper scrollable noPadding>
      <WrappedComponent {...props} />
    </Wrapper>
  )

export default withWrapper(DataSelection)
