import AgGrid from '@components/ag-grid'
import ComponentWidget from '@components/cards/component-widget'

import { ComponentPreviewProps } from '../viewer/types'
import { ClientSideTable } from './clientside-table'
import { useIsServerSideTable } from './hooks/use-is-server-side-table'
import { ServerSideTable } from './serverside-table'

export default function TableWidget(props: ComponentPreviewProps) {
  const serverSideTable = useIsServerSideTable({
    data: props.data,
    isFetching: props.isFetching,
    componentId: props.componentId
  })

  if (!props.data) {
    return (
      <ComponentWidget loading>
        <AgGrid loading className='size-full' />
      </ComponentWidget>
    )
  }

  if (serverSideTable) {
    return <ServerSideTable {...props} />
  } else {
    return <ClientSideTable {...props} />
  }
}
