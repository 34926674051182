import { useSelector } from '@store/index'
import { selectColumns } from '@store/slices/component/table-config'

import { SettingsRow, SettingsTable } from '../common/settings-table'
import { Broadcast } from './styling/broadcast'
import { Formatting } from './styling/formatting'
import { Heatmap } from './styling/heatmap'
import { Pinning } from './styling/pinning'
import { Rounding } from './styling/rounding'
import { ShowInGroup } from './styling/show-in-group'

export const TableStyling = () => {
  const columns = useSelector(selectColumns)

  return (
    <SettingsTable
      data={columns}
      titleColumnLabel='Table Formatting'
      renderHeaderName={(item) => item.field}
      idKey='field'
    >
      {(rowProps) => (
        <>
          <SettingsRow {...rowProps} title='Pinning' renderField={Pinning} />
          <SettingsRow {...rowProps} title='Broadcast' renderField={Broadcast} />
          <SettingsRow {...rowProps} title='Heatmap' renderField={Heatmap} />
          <SettingsRow {...rowProps} title='Formatting' renderField={Formatting} />
          <SettingsRow {...rowProps} title='Rounding' renderField={Rounding} />
          <SettingsRow {...rowProps} title='Show In Group' renderField={ShowInGroup} />
        </>
      )}
    </SettingsTable>
  )
}
