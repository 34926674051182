import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState
} from 'react'

import { ColDef, GridApi, GridReadyEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { useQueryClient } from '@tanstack/react-query'

import { isNotEqual } from '@utils/lodash'

import AgGrid from '@components/ag-grid'
import StatusBar from '@components/ag-grid/status-bar'
import { getSkeletonColumns } from '@components/ag-grid/utils'
import DimensionExplorer from '@components/control-panel/dimensions/dimension-explorer'

import { useLatestRef } from '@hooks/useLatestRef'

import { useDispatch, useSelector } from '@store/index'
import {
  selectLimit,
  setFilters,
  setHiddenFilters,
  setSorts
} from '@store/slices/component/query-config'

import useAuth from 'hooks/useAuth'

import { ActionBarControls } from '../common/action-bar-controls'
import { useQueryResultContext } from '../contexts/query-result-context'
import { useComponentAttrRef } from '../hooks/use-component-attr-refs'
import { QUERY_KEY_PREFIX } from '../queries/fetch-component-staged-data'
import { IComponentSortItem } from '../types/component-types'
import { ServerSideDatasource } from './datasource'

import '../common/query-builder-ag-grid.css'

export interface ITableViewerRef {
  refreshData: (force?: boolean) => void
  toggleAdvancedFilterBuilder: () => void
}

export const TableViewer = forwardRef<
  ITableViewerRef,
  { showHidden: boolean; showFiltersAfterGridReadyRef: React.MutableRefObject<boolean> }
>(function TableViewer({ showHidden, showFiltersAfterGridReadyRef }, ref) {
  const dispatch = useDispatch()
  const [dimensionFilterOpen, setDimensionFilterOpen] = useState(false)

  const { hiddenFiltersRef } = useComponentAttrRef()
  const { componentConfig } = useQueryResultContext()
  const componentConfigRef = useLatestRef(componentConfig)
  const queryClient = useQueryClient()
  const limit = useSelector(selectLimit)

  const { user } = useAuth()
  const businessIdRef = useRef(user?.business_id)
  const gridRef = useRef<AgGridReact>(null)
  const columnDefs = useMemo<ColDef[]>(() => getSkeletonColumns(15), []) // columnDefs will be replaced with the actual data in the datasource
  const advancedFilterBuilderVisibleRef = useRef(false)

  const hiddenGridRef = useRef<AgGridReact>(null)
  const [hiddenColumnsDefs, setHiddenColumsDefs] = useState<ColDef[]>([])

  const saveFilters = () => {
    if (!gridRef.current?.api) return

    const filterModel = gridRef.current.api.getAdvancedFilterModel()
    dispatch(setFilters(filterModel))
  }

  const saveHiddenFitlers = () => {
    if (!hiddenGridRef.current?.api) return

    const filterModel = hiddenGridRef.current.api.getFilterModel()
    if (isNotEqual(filterModel, hiddenFiltersRef.current)) {
      dispatch(setHiddenFilters(filterModel))
    }
  }

  const defaultColDef = useMemo<ColDef>(
    () => ({
      filter: true,
      sortable: true
    }),
    []
  )

  const onGridReady = (params: GridReadyEvent) => {
    const datasource = new ServerSideDatasource(
      queryClient,
      businessIdRef,
      componentConfigRef,
      setHiddenColumsDefs,
      showFiltersAfterGridReadyRef
    )
    params.api.setGridOption('serverSideDatasource', datasource)
    params.api.setGridOption(
      'advancedFilterParent',
      document.getElementById('advancedFilterParent')
    )
  }

  const refreshData = (force = false) => {
    if (!gridRef.current?.api) return

    if (force) {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_PREFIX, businessIdRef.current] })
    }

    gridRef.current.api.refreshServerSide({ purge: true })
  }

  const saveSort = () => {
    if (!gridRef.current?.api) return

    const colState = gridRef.current.api.getColumnState()
    const sortState = colState
      .filter((s) => s.sort != null)
      .sort((a, b) => (a.sortIndex || 0) - (b.sortIndex || 0))
      .map((s) => ({ colId: s.colId, sort: s.sort }))

    dispatch(setSorts(sortState as IComponentSortItem[]))
  }

  const initializeHiddenFilters = useCallback(
    (api: GridApi) => api.setFilterModel(hiddenFiltersRef.current),
    [hiddenFiltersRef]
  )

  useEffect(() => {
    if (!hiddenColumnsDefs.length || !hiddenGridRef.current?.api) return

    initializeHiddenFilters(hiddenGridRef.current.api)
  }, [hiddenColumnsDefs, initializeHiddenFilters, showHidden])

  useEffect(() => {
    refreshData()
    // eslint-disable-next-line react-hooks/exhaustive-deps -- refreshData function has refs as dependencies
  }, [componentConfig])

  useImperativeHandle(ref, () => ({
    refreshData,
    toggleAdvancedFilterBuilder() {
      if (!gridRef.current?.api) return

      if (advancedFilterBuilderVisibleRef.current) {
        gridRef.current.api.hideAdvancedFilterBuilder()
      } else {
        gridRef.current.api.showAdvancedFilterBuilder()
      }
    }
  }))

  return (
    <div
      className='h-full'
      style={{ display: 'grid', gridTemplateColumns: dimensionFilterOpen ? '70% 30%' : '100%' }}
    >
      <div className='flex size-full flex-col overflow-hidden'>
        <ActionBarControls
          dimensionFilterOpen={dimensionFilterOpen}
          onToggleDimensionFilters={() => setDimensionFilterOpen((prev) => !prev)}
        />
        <div className='flex size-full pb-4'>
          <div id='advancedFilterParent' className='hidden' />
          <AgGrid
            className='ag-component-query-builder size-full flex-1'
            ref={gridRef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            onGridReady={onGridReady}
            autoSizeStrategy={{
              type: 'fitCellContents'
            }}
            // serverside
            rowModelType='serverSide'
            rowBuffer={0}
            cacheBlockSize={limit}
            cacheOverflowSize={5}
            maxConcurrentDatasourceRequests={1}
            infiniteInitialRowCount={limit}
            maxBlocksInCache={10}
            // filters
            enableAdvancedFilter
            includeHiddenColumnsInAdvancedFilter={false}
            onFilterChanged={saveFilters}
            onAdvancedFilterBuilderVisibleChanged={(event) =>
              (advancedFilterBuilderVisibleRef.current = event.visible)
            }
            // sort
            onSortChanged={saveSort}
            // suppress
            suppressServerSideFullWidthLoadingRow
            suppressContextMenu
            statusBar={{
              statusPanels: [
                {
                  statusPanel: StatusBar,
                  statusPanelParams: { aggFuncs: ['sum', 'avg', 'count'] }
                }
              ]
            }}
          />
          {showHidden && (
            <AgGridReact
              className='ag-theme-alpine ag-component-hidden-fields h-full w-[250px]'
              ref={hiddenGridRef}
              columnDefs={hiddenColumnsDefs}
              rowData={[]}
              sideBar={{ toolPanels: ['filters'], defaultToolPanel: 'filters' }}
              onFilterChanged={saveHiddenFitlers}
              onGridReady={(event) => initializeHiddenFilters(event.api)}
              statusBar={{
                statusPanels: [
                  {
                    statusPanel: StatusBar,
                    statusPanelParams: { aggFuncs: ['sum', 'avg', 'count'] }
                  }
                ]
              }}
            />
          )}
        </div>
      </div>
      {dimensionFilterOpen && (
        <div className='size-full overflow-hidden pl-4'>
          <div className='size-full rounded-xl rounded-r-none border border-solid border-grey'>
            <DimensionExplorer
              classNames='size-full overflow-scroll m-0'
              onClose={() => {
                setDimensionFilterOpen(false)
              }}
            />
          </div>
        </div>
      )}
    </div>
  )
})
