import { shortUniqueID } from '@utils/string-utils'

import { DollarUnitEnum, DollarUnits } from '@components/control-panel/types'

import grid1Col from 'assets/images/layout-builder/grid-1-cols.png'
import { FILTER_IDENTIFIER } from 'types/page-filters'

import {
  COMP,
  FILTER_CATEGORY,
  FILTER_PRIORITY,
  GROUP,
  SCOPE,
  SIDEBAR_ITEM,
  SOURCE
} from './constants'

export const TEMPLATE_COMPONENTS = [
  {
    id: shortUniqueID('sidebar-item-freestyle-row-'),
    group: GROUP.BASIC,
    category: SIDEBAR_ITEM,
    component: {
      id: 'sidebar-comp-freestyle-row',
      source: SOURCE.TEMPLATE,
      category: COMP.ROW.SPECIFIC.FREE_STYLE_ROW.CATEGORY,
      name: COMP.ROW.SPECIFIC.FREE_STYLE_ROW.NAME,
      title: COMP.ROW.SPECIFIC.FREE_STYLE_ROW.TITLE,
      imgSrc: grid1Col
    },
    enabled: false
  },
  {
    id: shortUniqueID('sidebar-item-kpi-'),
    group: GROUP.WIDGET,
    category: SIDEBAR_ITEM,
    component: {
      id: 'sidebar-comp-kpi',
      source: SOURCE.TEMPLATE,
      category: COMP.WIDGET.CATEGORY,
      genre: COMP.WIDGET.SPECIFIC.KPI.GENRE,
      name: COMP.WIDGET.SPECIFIC.KPI.GENRE, // unique-widget-name
      title: COMP.WIDGET.SPECIFIC.KPI.GENRE, // unique-widget-title
      imgSrc: grid1Col
    },
    enabled: false
  },
  {
    id: shortUniqueID('sidebar-item-image-'),
    group: GROUP.WIDGET,
    category: SIDEBAR_ITEM,
    component: {
      id: 'sidebar-comp-image',
      source: SOURCE.TEMPLATE,
      category: COMP.WIDGET.CATEGORY,
      genre: COMP.WIDGET.SPECIFIC.IMAGE.GENRE,
      name: COMP.WIDGET.SPECIFIC.IMAGE.GENRE, // unique-widget-name
      title: COMP.WIDGET.SPECIFIC.IMAGE.GENRE, // unique-widget-title
      imgSrc: grid1Col
    },
    enabled: false
  },
  {
    id: shortUniqueID('sidebar-item-chart-'),
    group: GROUP.WIDGET,
    category: SIDEBAR_ITEM,
    component: {
      id: 'sidebar-comp-chart',
      source: SOURCE.TEMPLATE,
      category: COMP.WIDGET.CATEGORY,
      genre: COMP.WIDGET.SPECIFIC.CHART.GENRE,
      name: COMP.WIDGET.SPECIFIC.CHART.GENRE, // unique-widget-title
      title: COMP.WIDGET.SPECIFIC.CHART.GENRE, // unique-widget-title
      imgSrc: grid1Col
    },
    enabled: true
  },
  {
    id: shortUniqueID('sidebar-item-table-'),
    group: GROUP.WIDGET,
    category: SIDEBAR_ITEM,
    component: {
      id: 'sidebar-comp-table',
      source: SOURCE.TEMPLATE,
      category: COMP.WIDGET.CATEGORY,
      genre: COMP.WIDGET.SPECIFIC.TABLE.GENRE,
      name: COMP.WIDGET.SPECIFIC.TABLE.GENRE, // unique-widget-name
      title: COMP.WIDGET.SPECIFIC.TABLE.GENRE, // unique-widget-title
      imgSrc: grid1Col
    },
    enabled: false
  },
  {
    id: shortUniqueID('sidebar-item-embedding-'),
    group: GROUP.WIDGET,
    category: SIDEBAR_ITEM,
    component: {
      id: 'sidebar-comp-embedding',
      source: SOURCE.TEMPLATE,
      category: COMP.WIDGET.CATEGORY,
      genre: COMP.WIDGET.SPECIFIC.EMBEDDING.GENRE,
      name: COMP.WIDGET.SPECIFIC.EMBEDDING.GENRE, // unique-widget-name
      title: COMP.WIDGET.SPECIFIC.EMBEDDING.GENRE, // unique-widget-title
      imgSrc: grid1Col
    },
    enabled: false
  },
  {
    id: shortUniqueID('sidebar-item-divider-'),
    group: GROUP.WIDGET,
    category: SIDEBAR_ITEM,
    component: {
      id: 'sidebar-comp-divider',
      source: SOURCE.TEMPLATE,
      category: COMP.WIDGET.CATEGORY,
      genre: COMP.WIDGET.SPECIFIC.DIVIDER.GENRE,
      name: COMP.WIDGET.SPECIFIC.DIVIDER.GENRE, // unique-widget-title
      title: COMP.WIDGET.SPECIFIC.DIVIDER.GENRE, // unique-widget-title
      imgSrc: grid1Col,
      static: true,
      component_attributes: {}
    },
    enabled: false
  },
  {
    id: shortUniqueID('sidebar-item-financial-statement-'),
    group: GROUP.WIDGET,
    category: SIDEBAR_ITEM,
    component: {
      id: 'sidebar-comp-financial-statement',
      source: SOURCE.TEMPLATE,
      category: COMP.WIDGET.CATEGORY,
      genre: COMP.WIDGET.SPECIFIC.TABLE.GENRE,
      name: COMP.WIDGET.SPECIFIC.FINANCIAL_STATEMENT.NAME,
      title: COMP.WIDGET.SPECIFIC.FINANCIAL_STATEMENT.TITLE,
      imgSrc: grid1Col,
      component_attributes: {
        category: 'income-statement',
        general: {
          title: 'Income Statement',
          desc: 'Simple income statement'
        },
        permissions: {
          admin: 'r|w',
          dev: 'r|w',
          client: 'r'
        },
        data_source: {
          format: 'api-json'
        },
        data_display: {},
        styles: {
          color: 'black',
          background: 'orange'
        },
        filter: {
          priority: FILTER_PRIORITY.GLOBAL_FIRST,
          filters: {
            'collapse-control': {
              identifier: FILTER_IDENTIFIER.GRID_TOGGLE,
              klassName: 'collapse-control',
              category: FILTER_CATEGORY.GRID,
              order: 1
            },
            'expand-control': {
              identifier: FILTER_IDENTIFIER.GRID_TOGGLE,
              klassName: 'expand-control',
              category: FILTER_CATEGORY.GRID,
              order: 2
            },
            'data-exporter': {
              identifier: FILTER_IDENTIFIER.DATA_EXPORTER,
              options: ['XLSX', 'CSV'],
              klassName: 'data-exporter',
              order: 3
            },
            'dollar-units-toggle': {
              identifier: FILTER_IDENTIFIER.DOLLAR_UNITS,
              options: DollarUnits,
              defaultValue: DollarUnitEnum.E,
              klassName: 'dollar-unit-toggle',
              order: 4
            },
            'reverse-column-order-control': {
              identifier: FILTER_IDENTIFIER.GRID_COL_ORDER,
              klassName: 'reverse-column-order-control',
              category: FILTER_CATEGORY.GRID,
              order: 5
            },
            'refresh-data-control': {
              identifier: FILTER_IDENTIFIER.REFRESH_DATA,
              klassName: 'refresh-data-control',
              category: FILTER_CATEGORY.GRID,
              order: 6
            }
          }
        }
      }
    },
    enabled: false
  }
]

export const COMPONENT_REGISTRY = [
  {
    id: shortUniqueID('sidebar-item-income-statement-'),
    group: GROUP.WIDGET,
    category: SIDEBAR_ITEM,
    component: {
      id: 'sidebar-comp-income-statement',
      source: SOURCE.STATIC,
      category: COMP.WIDGET.CATEGORY,
      genre: COMP.WIDGET.SPECIFIC.TABLE.GENRE,
      scope: SCOPE.GENERAL,
      name: 'income-statement', // unique-widget-title
      title: 'Income Statement', // unique-widget-title
      imgSrc: grid1Col
    },
    enabled: false
  },
  {
    id: shortUniqueID('sidebar-item-balance-sheet-'),
    group: GROUP.WIDGET,
    category: SIDEBAR_ITEM,
    component: {
      id: 'sidebar-comp-balance-sheet',
      scope: SCOPE.GENERAL,
      category: COMP.WIDGET.CATEGORY,
      genre: COMP.WIDGET.SPECIFIC.TABLE.GENRE,
      source: SOURCE.STATIC,
      name: 'balance-sheet', // unique-widget-title
      title: 'Balance Sheet', // unique-widget-title
      imgSrc: grid1Col
    },
    enabled: false
  },
  {
    id: shortUniqueID('sidebar-item-sales-'),
    group: GROUP.WIDGET,
    category: SIDEBAR_ITEM,
    component: {
      id: 'sidebar-comp-sales',
      scope: SCOPE.GENERAL,
      category: COMP.WIDGET.CATEGORY,
      genre: COMP.WIDGET.SPECIFIC.TABLE.GENRE,
      source: SOURCE.STATIC,
      name: 'sales', // unique-widget-title, should match folder name
      title: 'Sales', // unique-widget-title
      imgSrc: grid1Col
    },
    enabled: false
  },
  {
    id: shortUniqueID('sidebar-item-insights-'),
    group: GROUP.WIDGET,
    category: SIDEBAR_ITEM,
    component: {
      id: 'sidebar-comp-insights',
      scope: SCOPE.GENERAL,
      category: COMP.WIDGET.CATEGORY,
      genre: COMP.WIDGET.SPECIFIC.TABLE.GENRE,
      source: SOURCE.STATIC,
      name: 'insights', // unique-widget-title, should match folder name
      title: 'Insights', // unique-widget-title
      imgSrc: grid1Col
    },
    enabled: false
  }
]

// Group Sidebar items by group name
export const SIDEBAR_GROUPED_ITEMS = TEMPLATE_COMPONENTS.concat(COMPONENT_REGISTRY).reduce(
  (groups, item) => {
    // @ts-ignore
    if (!groups[item.group]) {
      // @ts-ignore
      groups[item.group] = []
    }
    // @ts-ignore
    if (item.enabled) {
      // @ts-ignore
      groups[item.group].push(item)
    }

    return groups
  },
  {}
)

// Get only groups having children
export const SIDEBAR_GROUPED_ITEMS_FILTERED = Object.fromEntries(
  // @ts-ignore
  Object.entries(SIDEBAR_GROUPED_ITEMS).filter(([group, items]) => items.length > 0)
)
