import { ReactNode, useState } from 'react'

import { Menu, useMediaQuery } from '@mui/material'
import { Theme } from '@mui/material/styles'

import { sortSearchFilters } from '@utils/obj-utils'

import { FinancialReportType } from '@components/financial/types'
import { ChargerFormFieldConfig } from '@components/form/types'
import { DotsVertical, Icon } from '@components/icons'

import { SearchFilters } from '@pages/layout-view/types'

import DropDownIconButton from 'components/DropDownIconButton'
import { Frequency } from 'types/filter'

import { FILTER_IDENTIFIER } from '../../types/page-filters'
import ControlComponent from './control-component'
import FlyOverMenu from './fly-over-menu'
import { ControlPanel } from './index'

import './filters.css'

interface FilterPanelProps {
  searchFilters: SearchFilters
  financialReportType?: FinancialReportType
  children?: ReactNode
  exporterFieldsConfig?: ChargerFormFieldConfig[]
  backendExportHandler?: (data: any, exportFilters: any) => void
  onRefresh?: () => void
  dateFilterFrequenciesToOmit?: Frequency[]
}

// NOTE: This is just a basic replication of filters.tsx.
// TODO: Remove filters.tsx once components are fully migrated to the zustand store.
const FilterPanel = ({
  searchFilters = {},
  financialReportType,
  exporterFieldsConfig,
  backendExportHandler,
  onRefresh,
  dateFilterFrequenciesToOmit,
  children
}: FilterPanelProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const isLargeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))

  // NOTE:
  // Here we are simply filtering out dimension-filter from action-bar for now and displaying it in the right-side bar
  // Ideally we can define a location in the filter config ( src/pages/admin/layouts/data/filter-options.ts ) to tell whether
  // a filter resides in the action-bar or the right side bar
  const sortedFilters = _.omit<SearchFilters>(sortSearchFilters(searchFilters), [
    _.camelCase(FILTER_IDENTIFIER.DIMENSION_FILTERS),
    // TODO: `fetch-browsable-pages` response is camelCased, whereas `fetch-page` response is not.
    // So, until we have a consistent response, we need to use both the below keys.
    FILTER_IDENTIFIER.DIMENSION_FILTERS
  ])

  const allFilters = _.keys(sortedFilters)

  const mobileFilters = _.filter(allFilters, (filterName) => sortedFilters[filterName]?.mobileView)
  const nonMobileFilters = _.filter(
    allFilters,
    (filterName) => !sortedFilters[filterName]?.mobileView
  )

  const handleMenuOpen = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={`filter-inputs-container flex items-center gap-2`}>
      <ControlPanel>
        {isLargeScreen &&
          allFilters.map((filterName) => {
            return (
              <ControlComponent
                key={filterName}
                name={filterName}
                shouldRender={true}
                componentProps={{
                  filter: sortedFilters[filterName],
                  financialReportType: financialReportType,
                  exporterFieldsConfig: exporterFieldsConfig,
                  backendExportHandler: backendExportHandler,
                  type: financialReportType,
                  onRefresh: onRefresh,
                  frequenciesToOmit: dateFilterFrequenciesToOmit
                }}
              />
            )
          })}

        {!isLargeScreen &&
          nonMobileFilters.map((filterName) => {
            return (
              <ControlComponent
                key={filterName}
                name={filterName}
                shouldRender={true}
                componentProps={{
                  filter: sortedFilters[filterName],
                  financialReportType: financialReportType,
                  exporterFieldsConfig: exporterFieldsConfig,
                  backendExportHandler: backendExportHandler,
                  type: financialReportType,
                  onRefresh: onRefresh,
                  frequenciesToOmit: dateFilterFrequenciesToOmit
                }}
              />
            )
          })}

        {children && children}
      </ControlPanel>

      {!isLargeScreen && (
        <DropDownIconButton
          size='action-bar'
          variant='ghost'
          open={open}
          onClick={handleMenuOpen}
          className='m-1'
        >
          <Icon icon={<DotsVertical />} />
        </DropDownIconButton>
      )}
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <FlyOverMenu
          filters={mobileFilters}
          financialReportType={financialReportType}
          exporterFieldsConfig={exporterFieldsConfig}
          backendExportHandler={backendExportHandler}
          onRefresh={onRefresh}
        />
      </Menu>
    </div>
  )
}

export default FilterPanel
