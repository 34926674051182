import { useDispatch, useSelector } from 'react-redux'

import { sumOfValues } from '@utils/obj-utils'

import { Icon, ToggleLeft } from '@components/icons'

import { usePageSelector } from '@store/index'
import { selectDimensions } from '@store/slices/dimension'
import {
  selectCurrentPage,
  selectRightDrawerOpen,
  toggleRightDrawer
} from '@store/slices/navigation'

import Button from 'components/core/button'
import { isUnregisteredPage } from 'types/page'
import { cn } from 'utils/style-utils'

export const ToggleDimensionFilterButton = ({
  dimensionFilterOpen,
  onToggleDimensionFilter
}: {
  dimensionFilterOpen: boolean
  onToggleDimensionFilter: () => void
}) => {
  const selectedDimensions = usePageSelector(selectDimensions)
  const totalCount = sumOfValues(selectedDimensions)

  return (
    <Button
      aria-describedby={'dimension-filters'}
      className='border-none'
      onClick={onToggleDimensionFilter}
      variant={totalCount > 0 ? 'primary' : 'text'}
    >
      {`(${totalCount}) Dimension Filters`}
      <div className={cn('transition-transform', dimensionFilterOpen && 'rotate-180')}>
        <Icon icon={<ToggleLeft />} />
      </div>
    </Button>
  )
}

const ToggleDimensionFilters = () => {
  const dispatch = useDispatch()
  const currentPage = useSelector(selectCurrentPage)

  const rightDrawerOpen = useSelector(selectRightDrawerOpen)

  const searchFilters = currentPage?.pageSettings?.settings?.search_filters

  const onToggleDimensionFilter = () => {
    dispatch(toggleRightDrawer())
  }

  if (isUnregisteredPage(currentPage?.pageSettings?.layout_type)) {
    return <></>
  }

  if (typeof searchFilters === 'undefined') {
    return <></>
  }

  return (
    <ToggleDimensionFilterButton
      dimensionFilterOpen={rightDrawerOpen}
      onToggleDimensionFilter={onToggleDimensionFilter}
    />
  )
}

export default ToggleDimensionFilters
