import { useCallback, useMemo, useRef, useState } from 'react'

import { ColDef, GetDataPath } from 'ag-grid-community'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import { AgGridReact } from 'ag-grid-react'

import Filters from '@components/control-panel/filters'

import StatusBar from '@pages/financial-statements/status-bar'

import { sample_data } from './sample_data'

interface TableProps {
  layout_item: any
  widget: any
  item_index: number
  rowHeight: any
}

const Index = ({ layout_item, widget, rowHeight }: TableProps) => {
  const filters = widget?.component_attributes?.filter?.filters

  const gridRef = useRef<AgGridReact>(null)

  const [rowData] = useState<any[]>(sample_data)
  const [columnDefs] = useState<ColDef[]>([
    { field: 'account_id' },
    { field: 'amount' },
    { field: 'date' }
  ])
  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1
    }
  }, [])
  const autoGroupColumnDef = useMemo<ColDef>(() => {
    return {
      headerName: 'Header',
      minWidth: 300,
      cellRendererParams: {
        suppressCount: true
      }
    }
  }, [])
  const getDataPath = useMemo<GetDataPath>(() => {
    return (data: any) => {
      return data.header.split('.')
    }
  }, [])

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current!.api.updateGridOptions({
      quickFilterText: (document.getElementById('filter-text-box') as any).value
    })
  }, [])

  return (
    <div className='layout-widget-item layout-widget-table'>
      <Filters renderer={'component'} filters={filters} />
      <div style={{ marginBottom: '5px' }}>
        <input
          type='text'
          id='filter-text-box'
          placeholder='Filter...'
          onInput={onFilterTextBoxChanged}
        />
      </div>

      <div
        className={'ag-theme-alpine layout-table'}
        style={{ height: rowHeight * 3, paddingBottom: '24px' }}
      >
        {/* @ts-ignore */}
        <AgGridReact
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          autoGroupColumnDef={autoGroupColumnDef}
          treeData={true}
          animateRows={true}
          groupDefaultExpanded={-1}
          getDataPath={getDataPath}
          statusBar={{
            statusPanels: [
              {
                statusPanel: StatusBar,
                statusPanelParams: { aggFuncs: ['sum', 'avg', 'count'] }
              }
            ]
          }}
        />
      </div>
    </div>
  )
}

export default Index
