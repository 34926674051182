import { useQuery } from '@tanstack/react-query'

import { MODEL_DOES_NOT_EXIST } from '@store/slices/component/query-config'

import useAuth from 'hooks/useAuth'
import { QUERY_STALE_TIME_SHORT } from 'queries/constants'
import { queryErrorToast } from 'queries/query-toasts'
import axiosServices from 'utils/axios'

import { IComponentResultsData } from '../types'
import { IChartConfig } from '../types/chart-builder-types'
import {
  ComponentGenre,
  IClientComponentParameters,
  IComponentAdvancedFilters,
  IComponentFormula,
  IComponentHiddenFilters,
  IComponentSortItem,
  IServerComponentParamaters
} from '../types/component-types'
import { IParameterizedConfig, IQueryData } from '../types/query-builder-types'
import { ITableConfig } from '../types/table-builder-types'
import { ITreemapConfig } from '../types/treemap-builder-types'
import { frequencyToDateTrunc } from '../utils/frequency-date-trunc'

export type StagedComponentBody = {
  query: IQueryData
  filters?: IComponentAdvancedFilters
  hidden_filters?: IComponentHiddenFilters
  sorts?: IComponentSortItem[] | null
  limit?: number
  formulas?: IComponentFormula[]
  parameters: Partial<IClientComponentParameters>
  apply_transformations?: boolean
  genre?: ComponentGenre
  parameterized_config?: IParameterizedConfig
  config?: IChartConfig | ITableConfig | ITreemapConfig
  paginated?: boolean
}

export const QUERY_KEY_PREFIX = 'fetch-component-staged-data'

export const fetchComponentStagedData = async (
  body: StagedComponentBody,
  business_id: number
): Promise<IComponentResultsData> => {
  const { parameters, ...rest } = body
  const modifiedParameters: Partial<IServerComponentParamaters> = _.assign(
    {},
    _.omit(parameters, 'date_truncation'),
    parameters.date_truncation && {
      date_truncation: frequencyToDateTrunc(parameters.date_truncation)
    }
  )
  const modifiedBody = _.assign({}, rest, { parameters: modifiedParameters })

  try {
    const response = await axiosServices.post(
      `/businesses/${business_id}/components/run_query`,
      modifiedBody
    )
    return response.data
  } catch (error) {
    const message = _.get(error, 'message', 'Error running query')
    queryErrorToast(message)
    throw error // re-throw to let React Query handle the error state
  }
}

export const useFetchComponentStagedData = (
  body?: StagedComponentBody,
  enabled: boolean = true
) => {
  const { user } = useAuth()
  const business_id = user?.business_id!

  return useQuery({
    queryKey: [QUERY_KEY_PREFIX, business_id, body],
    queryFn: () => fetchComponentStagedData(body!, business_id),
    enabled:
      enabled &&
      !!body &&
      !!business_id &&
      !!body?.query?.model &&
      body.query.model !== MODEL_DOES_NOT_EXIST,
    retry: 0,
    staleTime: QUERY_STALE_TIME_SHORT
  })
}
