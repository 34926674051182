import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { todayYMD } from '@utils/date-utils'

import { rehydrateStore } from '@store/actions/rehydrate-store'

import { YearInfo } from 'types/filter'

import { RootState } from '..'

interface CurrencyRatesView {
  yearInfo: YearInfo
}

const initialState: CurrencyRatesView = {
  yearInfo: todayYMD(undefined)
}

const currencyRatesSlice = createSlice({
  name: '@CURRENCY_RATES',
  initialState,
  reducers: {
    setYear: (state, action: PayloadAction<YearInfo>) => {
      state.yearInfo = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(rehydrateStore, (_state, action) => {
      return { ...initialState, ...action.payload.currencyRates }
    })
  }
})

export const { setYear } = currencyRatesSlice.actions

export const currencyRatesReducer = currencyRatesSlice.reducer

export const selectYear = (state: RootState) =>
  _.get(state, ['currencyRates', 'yearInfo'], initialState.yearInfo)
