import React from 'react'

import { getPriorYear } from '@utils/date-frequency-utils'
import { Month } from '@utils/date-utils'

import Button from '@components/core/button'
import { Separator } from '@components/core/separator'
import { Text } from '@components/core/text'

import useAuth from '@hooks/useAuth'

import { endOfMonth, format, startOfMonth, subDays } from 'date-fns'

import { DateRange, Frequency } from '../../../types/filter'

const QuickActionsPanel = ({
  fiscalYearStart,
  updateFrequencyDateRange,
  setFrequency
}: {
  fiscalYearStart: Month | undefined
  updateFrequencyDateRange: (frequency: Frequency, newDateRange: DateRange) => void
  setFrequency: (frequency: Frequency) => void
}) => {
  const { user } = useAuth()

  const handleYearToDate = () => {
    const newFreq = Frequency.Monthly
    const adjustedDate = subDays(new Date(), user?.business_prior_month_closing_days ?? 0)
    updateFrequencyDateRange(newFreq, {
      startDate: {
        year: adjustedDate.getFullYear(),
        month: Month.Jan,
        day: 1
      },
      endDate: {
        year: adjustedDate.getFullYear(),
        month: format(adjustedDate, 'MMM').toUpperCase() as Month,
        day: adjustedDate.getDate()
      }
    })
    setFrequency(newFreq)
  }

  const handlePriorYear = () => {
    const newFreq = Frequency.Monthly
    updateFrequencyDateRange(newFreq, getPriorYear(newFreq, fiscalYearStart))
    setFrequency(newFreq)
  }

  const handleTrailing12Months = () => {
    const newFreq = Frequency.Monthly
    const adjustedDate = subDays(new Date(), user?.business_prior_month_closing_days ?? 0)
    const startDate = subDays(adjustedDate, 365)
    updateFrequencyDateRange(newFreq, {
      startDate: {
        year: startDate.getFullYear(),
        month: format(startDate, 'MMM').toUpperCase() as Month,
        day: startDate.getDate()
      },
      endDate: {
        year: adjustedDate.getFullYear(),
        month: format(adjustedDate, 'MMM').toUpperCase() as Month,
        day: adjustedDate.getDate()
      }
    })
    setFrequency(newFreq)
  }

  const handleLastMonth = () => {
    const newFreq = Frequency.Monthly
    const adjustedDate = subDays(new Date(), user?.business_prior_month_closing_days ?? 0)
    const startOfMonthDate = startOfMonth(adjustedDate)
    const endOfMonthDate = endOfMonth(adjustedDate)
    updateFrequencyDateRange(newFreq, {
      startDate: {
        year: startOfMonthDate.getFullYear(),
        month: format(startOfMonthDate, 'MMM').toUpperCase() as Month,
        day: startOfMonthDate.getDate()
      },
      endDate: {
        year: endOfMonthDate.getFullYear(),
        month: format(endOfMonthDate, 'MMM').toUpperCase() as Month,
        day: endOfMonthDate.getDate()
      }
    })
    setFrequency(newFreq)
  }

  return (
    <div className='flex basis-48 flex-col bg-grey-background p-4'>
      <div className='flex flex-1 flex-col items-center'>
        <Button variant='outline' className='mb-2 h-7 w-28' onClick={handleYearToDate}>
          Year To Date
        </Button>
        <Button variant='outline' className='mb-2 h-7 w-28' onClick={handlePriorYear}>
          Previous Year
        </Button>
        <Button variant='outline' className='mb-2 h-7 w-28' onClick={handleTrailing12Months}>
          TTM
        </Button>
        <Button variant='outline' className='mb-2 h-7 w-28' onClick={handleLastMonth}>
          Last Month
        </Button>
      </div>
      <div className='flex flex-col items-center'>
        <Separator className='my-2 bg-grey'></Separator>
        <Text variant='details'>Quick Actions</Text>
      </div>
    </div>
  )
}

export default QuickActionsPanel
