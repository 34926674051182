import { useQuery } from '@tanstack/react-query'

import { camelCaseKeys } from '@utils/case-conversion'

import useAuth from 'hooks/useAuth'
import { Page } from 'types/page'
import axiosServices from 'utils/axios'

const fetchUnassignedPages = async (
  businessId: number | undefined,
  activeStatus?: 'active' | 'inactive'
): Promise<Page[]> => {
  const response = await axiosServices.get(`/businesses/${businessId}/pages/unassigned_pages`, {
    params: { activeStatus }
  })

  return camelCaseKeys(response.data) as Page[]
}

export const useFetchUnassignedPages = (activeStatus?: 'active' | 'inactive') => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-unassigned-pages', user?.business_id, activeStatus],
    queryFn: () => fetchUnassignedPages(user?.business_id, activeStatus),
    staleTime: 600_000
  })
}
