import { useSelector } from 'react-redux'

import { Switch } from '@components/core/switch'

import { useDispatch } from '@store/index'
import { selectPivotMode, updateColumn } from '@store/slices/component/table-config'

import { ITableColumn } from '../../types/table-builder-types'

export const ShowInGroup = ({ item: column }: { item: ITableColumn }) => {
  const dispatch = useDispatch()

  const pivotMode = useSelector(selectPivotMode)
  const disabled = pivotMode

  const value = pivotMode ? false : !!column.customData?.showInGroup

  return (
    <div className='flex items-center'>
      <Switch
        disabled={disabled}
        checked={value}
        onCheckedChange={(checked) =>
          dispatch(updateColumn({ field: column.field, customData: { showInGroup: checked } }))
        }
      />
    </div>
  )
}
