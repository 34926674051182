import { ValueOf } from '../../../utils/obj-utils'
import { LAYOUT_TYPE } from './data'

export type PageIdentifier = ValueOf<typeof LAYOUT_TYPE>

// export type ViewModeType = 'Edit' | 'View' | 'ParentPreview';

export enum ViewMode {
  edit = 'edit',
  view = 'view',
  parentView = 'parent-view'
}

export const isViewModeEdit = (viewMode: ViewMode): boolean => viewMode === ViewMode.edit
export const isViewModeParentView = (viewMode: ViewMode): boolean =>
  viewMode === ViewMode.parentView

// @deprecated - use PageStatus instead
export enum LayoutStatus {
  draft = 'draft',
  published = 'published'
}

export const isLayoutStatusDraft = (status: LayoutStatus): boolean => status === LayoutStatus.draft
export const isLayoutStatusPublished = (status: LayoutStatus): boolean =>
  status === LayoutStatus.published

export enum LayoutBuilderDetailsModes {
  // CHARGER_PAGES = 'charger-pages', # TODO: Enable this once we add feature to include charger pages in the grid
  PAGE_DETAIL = 'page-detail',
  UNASSIGNED = 'unassigned-pages'
}

export const isPageUnassignedMode = (mode: LayoutBuilderDetailsModes): boolean =>
  mode === LayoutBuilderDetailsModes.UNASSIGNED
export const isPageDetailMode = (mode: LayoutBuilderDetailsModes): boolean =>
  mode === LayoutBuilderDetailsModes.PAGE_DETAIL
