import { CSSObject, Theme } from '@mui/material/styles'

import { drawerWidth, rightDrawerWidth } from 'config'

export const commonMixin = (theme: Theme): CSSObject => ({
  padding: 0,
  '& > div': {
    padding: '0'
  },
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  background: theme.palette.primary.main,
  '& .MuiListItemText-root .MuiTypography-root': {
    color: '#fff'
  },
  '& .MuiListItemButton-root:hover': {
    background: 'rgba(0, 0, 0, 0.15)'
  },
  '& .MuiListItemButton-root.Mui-selected': {
    background: 'rgba(0, 0, 0, 0.15)'
  }
})

const openedMixin = (
  theme: Theme,
  isMobile: boolean = false,
  width: string = drawerWidth
): CSSObject => ({
  minWidth: isMobile ? '100%' : width,
  borderRight: `1px solid ${theme.palette.primary.main}`,
  ...commonMixin(theme),
  overflowX: 'hidden',
  boxShadow: theme.palette.mode === 'dark' ? theme.customShadows.z1 : 'none'
})

const closedMixin = (theme: Theme): CSSObject => ({
  ...commonMixin(theme),
  overflowX: 'hidden',
  width: 0,
  borderRight: 'none',
  boxShadow: theme.customShadows.z1
})

export const drawerStyles = (
  theme: Theme,
  open: boolean,
  isMobile: boolean,
  headerCollapsed: boolean
): CSSObject => ({
  width: isMobile ? '100%' : drawerWidth,
  top: '94px',
  position: 'relative',
  flexShrink: 0,
  transition: 'min-width 500ms ease-in-out',
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme, false, drawerWidth),
    '& .MuiDrawer-paper': openedMixin(theme, isMobile, drawerWidth),
    '& > .MuiPaper-elevation': {
      maxHeight: 'calc(100% - 2.625rem)',
      transition: 'margin 200ms',
      marginTop: headerCollapsed ? 0 : '2.55rem',
      padding: '0 0.5rem 0.5rem'
    }
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      transition: 'margin 200ms',
      marginTop: headerCollapsed ? 0 : '2.625rem'
    }
  })
})

export const rightDrawerStyles = (
  theme: Theme,
  open: boolean,
  isMobile: boolean,
  headerCollapsed: boolean
): CSSObject => ({
  minWidth: isMobile ? '100%' : rightDrawerWidth,
  position: 'relative',
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme, false, rightDrawerWidth),
    backgroundColor: 'white',
    '& .MuiDrawer-paper': {
      ...openedMixin(theme, isMobile, rightDrawerWidth),
      backgroundColor: 'white',
      marginTop: headerCollapsed ? 0 : '2.625rem',
      border: `1px solid ${theme.palette.divider}`
    },
    '& > .MuiPaper-elevation': {
      maxHeight: 'calc(100% - 2.625rem)',
      top: '50px',
      zIndex: 8
    }
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': {
      ...closedMixin(theme),
      backgroundColor: 'white',
      marginTop: headerCollapsed ? 0 : '2.625rem',
      border: `1px solid ${theme.palette.divider}`
    },
    '& > .MuiPaper-elevation': {
      top: '50px',
      zIndex: 8
    }
  })
})
