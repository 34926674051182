import { TimeZoneList } from '@utils/TimezoneList'
import { ValueOf } from '@utils/obj-utils'

import {
  ArchiveStatusEnum,
  ArchiveToggleOptions,
  DataExportTypes,
  DateFrequencyEnum,
  DateFrequencyOptions,
  DollarUnitEnum,
  DollarUnits,
  ItemVendorEnum,
  ItemVendorOptions,
  SalesQuantityToggleEnum,
  SalesQuantityToggleOptions,
  SelectControlModeEnum,
  TimePeriodEnum,
  TimePeriodOptions,
  TimeZoneDefault
} from '@components/control-panel/types'

import { SearchFilters } from '@pages/layout-view/types'

export const FILTER_IDENTIFIER = {
  DIMENSION_FILTERS: 'dimension-filters',
  VIEW_MORE_ITEMS: 'view-more-items',
  FONT_TOGGLE: 'font-toggle',
  GRID_TOGGLE: 'grid-toggle',
  DATA_EXPORTER: 'data-exporter',
  GRID_COL_ORDER: 'grid-col-order',
  DOLLAR_UNITS: 'dollar-units',
  DATE_FREQUENCY: 'date-frequency',
  DATE_FILTER: 'date-filter',
  ARCHIVE_TOGGLE: 'archiver-toggle',
  SALES_QUANTITY_TOGGLE: 'sales-quantity-toggle',
  TIME_ZONE: 'time-zone',
  TIME_PERIOD: 'time-period',
  ITEM_VENDOR_TYPE: 'item-vendor-type',
  SUBSIDIARIES: 'subsidiaries',
  DATA_LABELS_TOGGLE: 'data-labels-toggle',
  CHART_GRID_TOGGLE: 'chart-grid-toggle',
  AG_GRID_SEARCH_INPUT: 'ag-grid-search-input',
  STATUS_TOGGLE: 'status-toggle',
  REFRESH_DATA: 'refresh-data',
  ROUNDING_TOGGLE: 'rounding-toggle',
  CLEAR_ALL_FILTERS: 'clear-all-filters'
}

export type FilterIdentifier = ValueOf<typeof FILTER_IDENTIFIER>

// Order programmatically by specified order unless specified
export const FILTER_OPTIONS: SearchFilters = {
  'date-frequency-toggle': {
    // 'year' | 'quarter' | 'month' | 'week' | 'day'
    identifier: FILTER_IDENTIFIER.DATE_FREQUENCY,
    options: DateFrequencyOptions,
    exclusions: [],
    label: 'Date Frequency',
    defaultValue: DateFrequencyEnum.M,
    mode: SelectControlModeEnum.group,
    mobileView: true
  },
  'timezone-toggle': {
    // US/Pacific | US/Eastern | US/Central | US/Mountain
    identifier: FILTER_IDENTIFIER.TIME_ZONE,
    options: TimeZoneList,
    defaultValue: TimeZoneDefault,
    mode: SelectControlModeEnum.select,
    label: 'Time Zone',
    mobileView: true
  },
  'time-period-toggle': {
    // 'today' | '7days' | '4weeks';
    identifier: FILTER_IDENTIFIER.TIME_PERIOD,
    options: TimePeriodOptions,
    defaultValue: TimePeriodEnum.Today,
    mode: SelectControlModeEnum.group,
    label: 'Time Period',
    mobileView: true
  },
  'sales-quantity-toggle': {
    // 'sales' | 'quantity'
    identifier: FILTER_IDENTIFIER.SALES_QUANTITY_TOGGLE,
    options: SalesQuantityToggleOptions,
    defaultValue: SalesQuantityToggleEnum.$,
    mode: SelectControlModeEnum.group,
    label: 'Sales / Quantity',
    mobileView: true
  },
  'item-vendor-toggle': {
    // 'item' | 'vendor'
    identifier: FILTER_IDENTIFIER.ITEM_VENDOR_TYPE,
    options: ItemVendorOptions,
    defaultValue: ItemVendorEnum.Item,
    mode: SelectControlModeEnum.group,
    label: 'Item / Vendor',
    mobileView: true
  },
  'dollar-units-toggle': {
    //'K' | 'M' | 'E'
    identifier: FILTER_IDENTIFIER.DOLLAR_UNITS,
    options: DollarUnits,
    defaultValue: DollarUnitEnum.E,
    mode: SelectControlModeEnum.group,
    label: 'Dollar Unit',
    mobileView: true
  },
  'date-filter-control': {
    identifier: FILTER_IDENTIFIER.DATE_FILTER,
    label: 'Date',
    showFrequencyToggle: true,
    showSecondaryFrequencyToggle: true,
    mobileView: false
  },
  'expand-collapse-control': {
    // Expand/Collapse
    identifier: FILTER_IDENTIFIER.GRID_TOGGLE,
    label: 'Grid Expand By 1, Restore Default, Collapse By 1',
    defaultValue: true,
    mobileView: true
  },
  'dimension-filters': {
    // dimensionFilter, Multi types
    identifier: FILTER_IDENTIFIER.DIMENSION_FILTERS,
    label: 'Dimension filters',
    defaultValue: true,
    exclusions: ['region', 'location'],
    mobileView: false
  },
  'font-size-toggle': {
    // +/- Font size
    identifier: FILTER_IDENTIFIER.FONT_TOGGLE,
    label: '+/- Font size',
    defaultValue: 12,
    mobileView: true
  },
  'view-more-items': {
    // +/- Font size
    identifier: FILTER_IDENTIFIER.VIEW_MORE_ITEMS,
    label: 'View more items',
    options: [
      { group_name: 'primary', group_val: [{ label: 'Default Hidden Data', value: 'false' }] },
      {
        group_name: 'secondary',
        group_val: [
          { label: 'Duplicate GL ACCT.', value: 'false' },
          { label: 'Heading Zero-Rows', value: 'false' },
          { label: 'GL ACCT Zero-Rows', value: 'false' }
        ]
      }
    ],
    defaultValue: [],
    mobileView: true
  },
  'reverse-column-order-control': {
    identifier: FILTER_IDENTIFIER.GRID_COL_ORDER,
    defaultValue: false,
    label: 'Reverse Column Order',
    order: 11,
    mobileView: true
  },
  'refresh-data-control': {
    identifier: FILTER_IDENTIFIER.REFRESH_DATA,
    label: 'Refresh Data',
    mobileView: true,
    order: 12
  },
  'archiver-toggle': {
    //'archived' | 'not_archived'
    identifier: FILTER_IDENTIFIER.ARCHIVE_TOGGLE,
    options: ArchiveToggleOptions,
    defaultValue: ArchiveStatusEnum['Not Archived'],
    label: 'Archive Status',
    mode: SelectControlModeEnum.group,
    mobileView: true
  },
  'subsidiaries-filter': {
    identifier: FILTER_IDENTIFIER.SUBSIDIARIES,
    defaultValue: [],
    label: 'Subsidiaries',
    mobileView: true
  },
  'data-labels-toggle': {
    identifier: FILTER_IDENTIFIER.DATA_LABELS_TOGGLE,
    label: 'Toggle Data Labels',
    mobileView: true
  },
  'chart-grid-toggle': {
    identifier: FILTER_IDENTIFIER.CHART_GRID_TOGGLE,
    label: 'Toggle Grid Lines',
    mobileView: true
  },
  'ag-grid-search-input': {
    identifier: FILTER_IDENTIFIER.AG_GRID_SEARCH_INPUT,
    label: 'Search',
    mobileView: false
  },
  'status-toggle': {
    identifier: FILTER_IDENTIFIER.STATUS_TOGGLE,
    label: 'Status',
    mobileView: true
  },
  'rounding-toggle': {
    identifier: FILTER_IDENTIFIER.ROUNDING_TOGGLE,
    label: 'Toggle Rounding',
    mobileView: true
  },
  'clear-all-filters': {
    identifier: FILTER_IDENTIFIER.CLEAR_ALL_FILTERS,
    label: 'Clear All',
    order: 99,
    mobileView: true
  },
  'data-exporter': {
    identifier: FILTER_IDENTIFIER.DATA_EXPORTER,
    options: DataExportTypes,
    label: 'Export Data',
    order: 100,
    mobileView: true
  }
}

const assignOrderValues = (filterOptions: SearchFilters): SearchFilters => {
  _.forEach(filterOptions, (option, key, collection) => {
    if (option.order === undefined) {
      // Assign order based on the index of the key in the collection
      option.order = _.indexOf(_.keys(collection), key)
    }
  })

  return filterOptions
}

// Computed property to get ordered filter options
export const orderedFilterOptions = (() => {
  // The function is immediately invoked and its result is stored
  const assignedOrders = assignOrderValues(FILTER_OPTIONS)
  const sortedPairs = _.sortBy(_.toPairs(assignedOrders), ([, value]) => value.order)
  return _.fromPairs(sortedPairs)
})()
