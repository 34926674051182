import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Box } from '@mui/system'

import {
  CellClassParams,
  ColDef,
  ColGroupDef,
  FilterChangedEvent,
  FirstDataRenderedEvent,
  GetMainMenuItemsParams,
  GridApi,
  GridReadyEvent,
  IRowNode,
  MenuItemDef,
  RowDataUpdatedEvent,
  RowGroupOpenedEvent,
  RowModelType,
  StateUpdatedEvent
} from 'ag-grid-community'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react'

import { restoreExpansionWithClick } from '@utils/ag_grid/restore-expansion-with-click'
import { sanitizeColumnState } from '@utils/ag_grid/sanitize-column-state'
import { containsOnlyDigitsOrDashes } from '@utils/string-utils'

import { autoFitAllColumns } from '@components/ag-grid/utils'
import {
  DEFAULT_MOST_EXPANDED_LEVEL,
  DEFAULT_MAX_EXPANSION_LEVEL as INITIAL_MAX_EXPANSION_LEVEL
} from '@components/control-panel/expand-collapse-control'

import { useMaxRows } from '@hooks/grid/useMaxRows'
import { useFirstRowWithNumbersId } from '@hooks/useFirstRowWithNumbersIndex'
import useRouteBasedID from '@hooks/useRouteBasedID'

import { usePageDispatch, usePageSelector } from '@store/index'
import {
  selectDisplayExpansionControls,
  selectHasPageBeenLoaded,
  setDisplayExpansionControls,
  setPageLoaded,
  updateExpandLevels,
  updateInitialMostExpandedLevel,
  updateMaxExpandLevel
} from '@store/slices/action-bar'
import { selectExpandedGroups } from '@store/slices/expanded-groups'
import { clearGridState, selectGridState, setGridState } from '@store/slices/grid-state'

import {
  getGroupId,
  useExpandLevelHandler
} from 'pages/financial-statements/hooks/expand-level-handler'
import { RootState } from 'store/index'

import { CHARGER_MONOSPACE_CLASS } from '../../config'
import SkeletonTable from './skeleton-table'
import TooltipComponent from './tooltip-component'
import { AG_RIGHT_ALIGNED_CELL, ColumnTypes } from './types'

const INITIAL_MOST_EXPANDED_LEVEL = 0

// Dynamic variables to store the current levels
let currentMostExpandedLevel = INITIAL_MOST_EXPANDED_LEVEL
let currentMaxExpansionLevel = INITIAL_MAX_EXPANSION_LEVEL

export const AUTO_FIT_TIMEOUT_MS = 300
const DEBOUNCE_DELAY = 300

export const getMostExpandedLevel = (): number => currentMostExpandedLevel
export const getMaxExpansionLevel = (): number => currentMaxExpansionLevel
export const setMostExpandedLevel = (level: number): void => {
  currentMostExpandedLevel = level
}
export const setMaxExpansionLevel = (level: number): void => {
  currentMaxExpansionLevel = level
}

interface CustomProps {
  id?: string
  style?: React.CSSProperties
  loadingOverlayComponentParams?: AgGridReactProps['loadingOverlayComponentParams'] & {
    showHeader?: boolean
    topOffset?: string
  }
  wrapperRef?: React.RefObject<HTMLDivElement>

  persistFilterState?: boolean
  persistColumnState?: boolean
  persistExpandedGroups?: boolean
  refreshCellsOnRowGroupOpened?: boolean
  enableDollarSignOnFirstRow?: boolean
  suppressAutoFitColumns?: boolean
  initialMostExpandedLevel?: number
}

type Props = CustomProps & AgGridReactProps

const extractAgGridProps = (props: Props): AgGridReactProps => {
  const agGridProps = { ...props } as Omit<Props, keyof CustomProps>
  return agGridProps
}

export const DEFAULT_TOOLTIP_SHOW_DELAY = 1000

export enum MenuItem {
  AutoSizeAll = 'autoSizeAll'
}

function getColIdsFromColDef(colDef: ColDef<any, any> | ColGroupDef<any>): string[] {
  if ('children' in colDef && Array.isArray(colDef.children)) {
    // It's a ColGroupDef; recursively extract colIds from its children
    return colDef.children.flatMap(getColIdsFromColDef)
  } else {
    // It's a ColDef; extract colId or field
    const colId = 'colId' in colDef ? colDef.colId : 'field' in colDef ? colDef.field : undefined
    return colId ? [colId] : []
  }
}

export const getMainMenuItems = (params: GetMainMenuItemsParams) => {
  const newItems: (string | MenuItemDef)[] = [...params.defaultItems]

  const idx = newItems.findIndex((d) => d === MenuItem.AutoSizeAll)!
  if (idx >= 0) {
    newItems[idx] = {
      name: 'Auto Size All',
      tooltip: 'Default Action: Fits to Grid Width',
      action: () => {
        params.api.sizeColumnsToFit()
      },
      subMenu: [
        {
          name: 'Fit to Grid Width',
          action: () => {
            params.api.sizeColumnsToFit()
          }
        },
        {
          name: 'Fit to Cell Contents',
          action: () => {
            params.api.autoSizeAllColumns()
          }
        }
      ]
    }
  }
  return newItems
}

const AgGrid = React.forwardRef((props: Props, ref: any) => {
  const gridRef = useRef<AgGridReact<any> | null>(null)
  const dispatch = useDispatch()

  const routeBasedID = useRouteBasedID()
  const combinedId = props.id ? `${props.id}_${routeBasedID}` : routeBasedID

  const displayExpansionControls = usePageSelector(selectDisplayExpansionControls)
  const emptyArray = useMemo(() => [], [])
  const expandedGroups = useSelector(
    (state: RootState) => selectExpandedGroups(combinedId)(state) || emptyArray
  )
  const gridState = useSelector((state: RootState) => selectGridState(combinedId)(state) || {})

  const { maxRows } = useMaxRows(props.wrapperRef)

  const {
    persistFilterState = false,
    persistColumnState = true,
    persistExpandedGroups = false,
    onFirstDataRendered = null,
    onRowDataUpdated = null,
    onRowGroupOpened = null,
    onStateUpdated = null,
    onFilterChanged = null,
    onGridReady = null,
    refreshCellsOnRowGroupOpened = false,
    enableDollarSignOnFirstRow = false,
    suppressAutoFitColumns = false,
    initialMostExpandedLevel = DEFAULT_MOST_EXPANDED_LEVEL
  } = props

  const { onRowDataUpdated: onRowDataUpdatedInternal, onRowGroupOpened: onRowGroupOpenedInternal } =
    useExpandLevelHandler({
      persistExpandedGroups,
      combinedId
    })

  const applyExpandedGroups = useCallback(() => {
    if (gridRef.current) {
      const api = gridRef.current.api
      const queue: IRowNode[] = []

      // Start with the root nodes
      api.forEachNode((node: IRowNode<any>) => {
        if (node.group) {
          queue.push(node)
        }
      })

      while (queue.length > 0) {
        const currentNode = queue.shift()!
        const groupId = getGroupId(currentNode)
        if (expandedGroups.includes(groupId)) {
          currentNode.setExpanded(true)
        }

        // Add child nodes to the queue
        if (currentNode.childrenAfterGroup) {
          queue.push(...currentNode.childrenAfterGroup)
        }
      }
    }
  }, [expandedGroups])

  const pageDispatch = usePageDispatch()
  const hasPageBeenLoaded = useSelector((state: RootState) =>
    selectHasPageBeenLoaded(combinedId)(state)
  )

  const { firstRowWithNumbersId, computeFirstRowWithNumbersId } = useFirstRowWithNumbersId(
    gridRef,
    enableDollarSignOnFirstRow
  )

  useEffect(() => {
    pageDispatch(updateInitialMostExpandedLevel({ initialMostExpandedLevel }))
  }, [pageDispatch, initialMostExpandedLevel, combinedId])

  useEffect(() => {
    if (enableDollarSignOnFirstRow && gridRef.current && gridRef.current.api) {
      gridRef.current.api.refreshCells({ force: true })
    }
  }, [firstRowWithNumbersId, enableDollarSignOnFirstRow, gridRef])

  // Define the function to check and apply column state
  const checkAndApplyColumnState = useCallback(
    (gridApi: GridApi) => {
      if (gridState.columnState && persistColumnState && !suppressAutoFitColumns) {
        // Get current column ids from props.columnDefs
        const dataColumns = props.columnDefs?.flatMap(getColIdsFromColDef) ?? []

        // Get stored column ids from gridState.columnState
        const storedColumns = gridState.columnState.map((colState) => colState.colId)

        // Check if they match
        const columnsMatch = _.isEqual(_.sortBy(dataColumns), _.sortBy(storedColumns))

        if (columnsMatch) {
          gridApi.applyColumnState({ state: gridState.columnState, applyOrder: true })
        } else {
          // Column ids do not match, clear gridState.columnState
          dispatch(clearGridState({ id: combinedId }))
        }
        return columnsMatch
      }
      return false
    },
    [gridState, props.columnDefs, persistColumnState, suppressAutoFitColumns, dispatch, combinedId]
  )

  const onFirstDataRenderedInternal = useCallback(
    (event: FirstDataRenderedEvent) => {
      const gridApi = gridRef.current?.api
      if (!gridApi) return

      if (gridState) {
        checkAndApplyColumnState(gridApi)
        if (gridState.filterState && persistFilterState) {
          gridApi.setFilterModel(gridState.filterState)
        }
      }

      applyExpandedGroups()

      if (!hasPageBeenLoaded) {
        for (let i = 0; i < initialMostExpandedLevel; i++) {
          restoreExpansionWithClick(pageDispatch, gridRef, initialMostExpandedLevel)
        }
        pageDispatch(setPageLoaded(combinedId))
      }

      // Some serverSide row models have non-standard data loading and need to do their own auto sizing
      if (
        !suppressAutoFitColumns &&
        (gridApi.getGridOption('rowModelType') as RowModelType) === 'serverSide'
      ) {
        setTimeout(() => {
          autoFitAllColumns(event)
        }, AUTO_FIT_TIMEOUT_MS)
      }
    },
    [
      applyExpandedGroups,
      hasPageBeenLoaded,
      combinedId,
      pageDispatch,
      gridState,
      persistFilterState,
      suppressAutoFitColumns,
      checkAndApplyColumnState,
      initialMostExpandedLevel
    ]
  )

  const mergedOnFirstDataRendered = useCallback(
    (event: FirstDataRenderedEvent) => {
      onFirstDataRenderedInternal(event)
      if (enableDollarSignOnFirstRow) {
        computeFirstRowWithNumbersId()
      }
      if (onFirstDataRendered) {
        onFirstDataRendered(event)
      }
    },
    [
      onFirstDataRenderedInternal,
      onFirstDataRendered,
      computeFirstRowWithNumbersId,
      enableDollarSignOnFirstRow
    ]
  )

  const mergedOnRowGroupOpened = useCallback(
    (event: RowGroupOpenedEvent) => {
      if (refreshCellsOnRowGroupOpened) {
        event.api.refreshCells({ force: true, rowNodes: [event.node] })
      }
      if (enableDollarSignOnFirstRow) {
        computeFirstRowWithNumbersId()
      }
      if (event.event) {
        onRowGroupOpenedInternal(event)
      }
      onRowGroupOpened?.(event)
    },
    [
      computeFirstRowWithNumbersId,
      enableDollarSignOnFirstRow,
      onRowGroupOpened,
      onRowGroupOpenedInternal,
      refreshCellsOnRowGroupOpened
    ]
  )

  const mergedOnRowDataUpdated = useCallback(
    (event: RowDataUpdatedEvent) => {
      onRowDataUpdatedInternal(event)
      onRowDataUpdated?.(event)
      applyExpandedGroups()

      // Check if the current value is false before dispatching true
      if (!displayExpansionControls) {
        pageDispatch(setDisplayExpansionControls({ displayExpansionControls: true }))
      }

      if (enableDollarSignOnFirstRow) {
        computeFirstRowWithNumbersId()
      }

      const gridApi = gridRef.current?.api
      if (gridApi) {
        if (gridState) {
          checkAndApplyColumnState(gridApi)
        }
        if ((gridApi.getGridOption('rowModelType') as RowModelType) !== 'serverSide') {
          setTimeout(() => {
            autoFitAllColumns(event)
          }, AUTO_FIT_TIMEOUT_MS)
        }
      }
    },
    [
      onRowDataUpdatedInternal,
      onRowDataUpdated,
      applyExpandedGroups,
      displayExpansionControls,
      enableDollarSignOnFirstRow,
      pageDispatch,
      computeFirstRowWithNumbersId,
      gridState,
      checkAndApplyColumnState
    ]
  )

  const debouncedDispatchSetGridState = useMemo(
    () =>
      _.debounce((args) => {
        dispatch(setGridState(args))
      }, DEBOUNCE_DELAY),
    [dispatch]
  )

  const onStateUpdatedInternal = useCallback(
    (event: StateUpdatedEvent) => {
      if (!gridRef.current) return
      let isRelevantStateChange =
        _.intersection(event.sources as string[], [
          'filterChanged',
          'filter',
          'sortChanged',
          'sort',
          'columnSizing'
        ]).length > 0
      if (_.includes(event.sources, 'columnSizing') && !_.eq(event.sources.length, 1)) {
        isRelevantStateChange = false
      }

      if (!isRelevantStateChange) return

      const api = gridRef.current.api

      const isFilterStateChange =
        _.intersection(event.sources as string[], ['filterChanged', 'filter']).length > 0
      const isColumnStateChange =
        _.intersection(event.sources as string[], ['sortChanged', 'sort', 'columnSizing']).length >
        0

      const rawColumnState =
        persistColumnState && isColumnStateChange ? api.getColumnState() : undefined
      const columnState = rawColumnState ? sanitizeColumnState(rawColumnState) : undefined
      const filterState =
        persistFilterState && isFilterStateChange ? api.getFilterModel() : undefined

      const gridStateArgs = {
        id: combinedId,
        ...(persistColumnState && { columnState }),
        ...(persistFilterState && { filterState })
      }

      // Use debounced dispatch for columnSizing events so that we don't spam the store with an update for every pixel drag
      if (_.eq(event.sources.length, 1) && _.eq(event.sources[0], 'columnSizing')) {
        debouncedDispatchSetGridState(gridStateArgs)
      } else {
        debouncedDispatchSetGridState.cancel()
        dispatch(setGridState(gridStateArgs))
      }
    },
    [dispatch, combinedId, persistFilterState, persistColumnState, debouncedDispatchSetGridState]
  )

  const mergedOnStateUpdated = useCallback(
    (event: StateUpdatedEvent) => {
      onStateUpdatedInternal(event)
      if (enableDollarSignOnFirstRow) {
        computeFirstRowWithNumbersId()
      }
      onStateUpdated?.(event)
    },
    [
      onStateUpdatedInternal,
      onStateUpdated,
      computeFirstRowWithNumbersId,
      enableDollarSignOnFirstRow
    ]
  )

  const hasPersistedColumnState =
    gridState && gridState.columnState && persistColumnState && !suppressAutoFitColumns

  const autoSizeStrategy = hasPersistedColumnState
    ? undefined
    : (props.autoSizeStrategy ?? { type: 'fitGridWidth' })

  const mergedContext = useMemo(() => {
    return {
      ...props.context,
      ...(enableDollarSignOnFirstRow && { firstRowWithNumbersId, enableDollarSignOnFirstRow }),
      hasPersistedColumnState,
      gridContainerRef: props.wrapperRef
    }
  }, [
    props.context,
    props.wrapperRef,
    firstRowWithNumbersId,
    enableDollarSignOnFirstRow,
    hasPersistedColumnState
  ])

  const captureRef = (node: AgGridReact) => {
    if (ref) {
      if (typeof ref === 'function') {
        ref(node)
      } else {
        ref.current = node
      }
    }
    if (gridRef) {
      gridRef.current = node
    }
  }

  const columnDefs = useMemo(() => {
    return props.columnDefs?.map((col: ColDef) => ({
      ...col,
      cellClass:
        col.cellClass ??
        ((cellClassParams: CellClassParams) => {
          const cellClasses: string[] = []
          if (_.isEqual(col.type, ColumnTypes.RIGHT_ALIGNED)) {
            cellClasses.push(AG_RIGHT_ALIGNED_CELL)
          }
          if (
            _.isNumber(cellClassParams.value) ||
            containsOnlyDigitsOrDashes(`${cellClassParams.value}`)
          ) {
            cellClasses.push(CHARGER_MONOSPACE_CLASS)
          }
          return cellClasses
        })
    }))
  }, [props.columnDefs])

  const defaultColDef = useMemo(
    () => ({
      tooltipComponent: TooltipComponent,
      ...props.defaultColDef,
      suppressHeaderMenuButton: true
    }),
    [props.defaultColDef]
  )

  const defaultColGroupDef = useMemo(
    () => ({
      tooltipComponent: TooltipComponent,
      ...props.defaultColGroupDef
    }),
    [props.defaultColGroupDef]
  )

  const onFilterChangedInternal = useCallback(
    (event: FilterChangedEvent) => {
      if (_.eq(event.source, 'api')) return

      const allFilters = event.api.getFilterModel()
      const isAnyFilterActive = Object.keys(allFilters).length > 0

      restoreExpansionWithClick(pageDispatch, gridRef)

      if (isAnyFilterActive) {
        let mostExpandedLevel = 0
        let maxLevel = 0

        event.api.forEachNode((node: IRowNode) => {
          if (node.group) {
            const childrenAfterFilter = node.childrenAfterFilter || []
            const filterEvent = { type: 'filter' } as MouseEvent

            if (childrenAfterFilter.length > 0) {
              node.setExpanded(true, filterEvent)
              mostExpandedLevel = Math.max(mostExpandedLevel, node.level + 1)
              maxLevel = Math.max(maxLevel, node.level + 1)
            } else {
              node.setExpanded(false, filterEvent)
            }
          }
        })

        // If no nodes are found, fallback to the default level
        mostExpandedLevel = Math.max(0, mostExpandedLevel)
        maxLevel = Math.max(0, maxLevel)

        pageDispatch(updateExpandLevels({ mostExpandedLevel }))
        pageDispatch(updateMaxExpandLevel({ maxExpandLevel: maxLevel }))

        setMostExpandedLevel(mostExpandedLevel)
        setMaxExpansionLevel(maxLevel)
      }
      autoFitAllColumns(event)
    },
    [pageDispatch]
  )

  const mergedOnFilterChanged = useCallback(
    (event: FilterChangedEvent) => {
      onFilterChangedInternal(event)
      onFilterChanged?.(event)
    },
    [onFilterChangedInternal, onFilterChanged]
  )

  const mergedOnGridReady = useCallback(
    (event: GridReadyEvent) => {
      if (displayExpansionControls) {
        pageDispatch(setDisplayExpansionControls({ displayExpansionControls: false }))
      }
      onGridReady?.(event)
    },
    [displayExpansionControls, onGridReady, pageDispatch]
  )

  return (
    <Box
      ref={props.wrapperRef}
      component='div'
      style={props.style}
      className={(props.className || '') + ' ag-theme-alpine'}
      sx={{
        '& .ag-overlay-wrapper': {
          backgroundColor: 'transparent'
        }
      }}
    >
      <AgGridReact
        ref={captureRef}
        loading={false}
        loadingOverlayComponent={SkeletonTable}
        tooltipShowDelay={DEFAULT_TOOLTIP_SHOW_DELAY}
        enableRangeSelection
        stopEditingWhenCellsLoseFocus={true}
        getMainMenuItems={getMainMenuItems}
        {...extractAgGridProps(props)}
        autoSizeStrategy={autoSizeStrategy}
        defaultColDef={defaultColDef}
        defaultColGroupDef={defaultColGroupDef}
        columnDefs={columnDefs}
        loadingOverlayComponentParams={{
          rowLength: maxRows,
          top:
            props.loadingOverlayComponentParams?.showHeader &&
            props.loadingOverlayComponentParams?.topOffset
              ? `${props.loadingOverlayComponentParams.topOffset}`
              : void 0,
          ...props.loadingOverlayComponentParams
        }}
        onRowDataUpdated={mergedOnRowDataUpdated}
        onRowGroupOpened={mergedOnRowGroupOpened}
        onFirstDataRendered={mergedOnFirstDataRendered}
        animateRows={false}
        onStateUpdated={mergedOnStateUpdated}
        context={mergedContext}
        groupDefaultExpanded={props.groupDefaultExpanded || INITIAL_MOST_EXPANDED_LEVEL}
        onFilterChanged={mergedOnFilterChanged}
        onGridReady={mergedOnGridReady}
        localeText={{
          blank: 'Null',
          notBlank: 'Non-Null'
        }}
      />
    </Box>
  )
})

export default AgGrid
