import { EnumValueUnion } from '@utils/obj-utils'

import { GeneralLedgerAccountNodeIdentifier } from 'components/financial/types'

type SingleEntry = number

type DoubleEntry = {
  debit: number
  credit: number
  amount: number
}

export interface FinancialReport {
  id: number
  gl_source_system_account_id: string
  label: string
  identifier: GeneralLedgerAccountNodeIdentifier
  path: string
  sort_order: number
  data: Record<string, SingleEntry | DoubleEntry>
}

export enum FinancialStatementPurpose {
  INCOME_STATEMENT = 'income_statement',
  BALANCE_SHEET = 'balance_sheet',
  CASH_FLOW = 'cash_flow'
}

// NOTE: This tracks the routes for these reports not the actual hierarchy.purpose columns
// TODO: Refactor adjustment-account and adjustment-transaction are not financial statement types, those should not be here.
// Try not to re-use this enum if possible. Users can modify the page-route from Page Builder.
// This should potentially be replaced in favor of FinancialStatementPurpose
export enum FinancialStatementTypeEnum {
  IncomeStatement = 'income-statement',
  BalanceSheet = 'balance-sheet',
  CashFlow = 'cash-flow',
  Account = 'adjustment-account',
  Transaction = 'adjustment-transaction'
}

export const FinancialStatementTypes: string[] = Object.values(FinancialStatementTypeEnum)

// export type FinancialStatementType = 'income-statement' | 'balance-sheet' | 'cash-flow';
// NOTE: This tracks the routes for these reports not the actual hierarchy.purpose columns
export type FinancialStatementType = EnumValueUnion<typeof FinancialStatementTypeEnum>
