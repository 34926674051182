import React, { useCallback, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router'

import { ColDef, ICellRendererParams, IRowNode } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { humanizeCron } from '@utils/cron-utils'

import { Button } from '@core/button'
import { Popover, PopoverContent, PopoverTrigger } from '@core/popover'
import { Separator } from '@core/separator'

import AgGrid from '@components/ag-grid'
import { applyHighlightRenderer } from '@components/ag-grid/custom-cell-renderer/highlight-cell-renderer'
import OpenInNewTabCellRenderer from '@components/ag-grid/custom-cell-renderer/open-in-new-tab-cell-renderer'
import AgGridSearchInput from '@components/control-panel/ag-grid-search-input'
import { closePopover } from '@components/form/charger-form'
import { Icon, Pencil } from '@components/icons'

import {
  IJobSchedule,
  JobScheduleSelectType,
  useFetchJobSchedules
} from '@layout-components/general/data-connections/queries/fetch-job-schedules'
import CustomRustOnlyHeaderCheckbox from '@layout-components/general/data-connections/shared/job-schedules/shared/custom-rust-only-header-checkbox'
import { UpdateMultipleSchedulesForm } from '@layout-components/general/data-connections/shared/job-schedules/update-multiple-schedules-form'
import { DataConnectionMode } from '@layout-components/general/data-connections/types'

import useAuth from '@hooks/useAuth'

import { RootState } from '@store/index'
import { selectGridSearchTerm } from '@store/slices/grid'

import { useRegisterAgGridRefEffect } from '../../../../../contexts/grid-ref-context'
import {
  multiTextColumnDef,
  numericColumnDef,
  openInNewTabColumnDef,
  textSearchColumnDef
} from '../../utils/col-defs'
import { UpdateScheduleForm } from './update-schedule-form'

export const JobSchedules = ({ mode }: { mode: DataConnectionMode }) => {
  const { id } = useParams<{ id: string }>()

  const { isPending, data } = useFetchJobSchedules(id)
  const jobSchedules = useMemo(() => data ?? [], [data])
  const { isPrimaryBusinessCharger } = useAuth()

  const wrapperRef = useRef<HTMLDivElement>(null)
  const gridRef = useRef<AgGridReact>(null)
  const searchTerm = useSelector((state: RootState) => selectGridSearchTerm(state, undefined))
  const popoverTriggerRef = useRef<HTMLButtonElement>(null)
  const multiUpdatePopoverTriggerRef = useRef<HTMLButtonElement>(null)

  const [isBulkUpdateMode, setIsBulkUpdateMode] = useState(false)
  const [selectedRows, setSelectedRows] = useState<IJobSchedule[]>([])
  const [selectedType, setSelectedType] = useState<JobScheduleSelectType | null>(null)

  const handleBulkUpdateClick = () => setIsBulkUpdateMode(true)
  const handleBulkUpdateCancel = () => {
    setIsBulkUpdateMode(false)
    setSelectedType(null)
  }

  const handleUpdateSelectedClick = () => {
    const selected = gridRef.current?.api.getSelectedRows() as IJobSchedule[]
    setSelectedRows(selected || [])
  }

  useRegisterAgGridRefEffect(gridRef)

  const actionCellRenderer = (params: ICellRendererParams) => {
    return (
      <div id='action-cell-renderer' className='flex size-full items-center'>
        <Popover>
          <PopoverTrigger asChild>
            <Button
              ref={popoverTriggerRef}
              variant={params.node.isSelected() ? 'primary' : 'text'}
              className='p-0'
            >
              <Icon icon={<Pencil />} />
            </Button>
          </PopoverTrigger>

          <PopoverContent className='w-[550px]'>
            <UpdateScheduleForm
              data={params.data}
              onSuccess={() => {
                closePopover(popoverTriggerRef.current)
              }}
              onCancel={() => closePopover(popoverTriggerRef.current)}
            />
          </PopoverContent>
        </Popover>
      </div>
    )
  }

  const onRowSelected = () => {
    if (!isBulkUpdateMode) {
      return
    }

    const selectedNodes = gridRef.current?.api.getSelectedNodes()
    const selectedData = selectedNodes?.map((node) => node.data)

    if (selectedData && selectedData.length > 0) {
      const hasRustBased = selectedData.some((row: IJobSchedule) => row.name.endsWith('_rs'))
      const hasNonRustBased = selectedData.some((row: IJobSchedule) => !row.name.endsWith('_rs'))

      if (hasRustBased && !hasNonRustBased) {
        setSelectedType(JobScheduleSelectType.Rust)
      } else if (!hasRustBased && hasNonRustBased) {
        setSelectedType(JobScheduleSelectType.NonRust)
      } else {
        setSelectedType(null)
      }
    } else {
      setSelectedType(null)
    }

    gridRef.current?.api.refreshCells({ force: true })
  }

  const isRowSelectable = useCallback(
    (row: IJobSchedule) => {
      if (!selectedType) return true
      if (selectedType === JobScheduleSelectType.Rust) return row.name.endsWith('_rs')
      if (selectedType === JobScheduleSelectType.NonRust) return !row.name.endsWith('_rs')
      return false
    },
    [selectedType]
  )

  const columnDefs: ColDef[] = useMemo(() => {
    const checkboxColumn = {
      colId: 'checkbox',
      headerCheckboxSelection: false,
      checkboxSelection: (params: { data: IJobSchedule }) => isRowSelectable(params.data),
      headerComponent:
        selectedType !== JobScheduleSelectType.NonRust ? CustomRustOnlyHeaderCheckbox : null,
      headerComponentParams: {
        selectedType: JobScheduleSelectType.Rust,
        setSelectedType
      },
      width: 50,
      suppressMenu: true,
      suppressMovable: true,
      filter: false
    }

    const singleColumnEdit = isPrimaryBusinessCharger()
      ? {
          colId: 'actions',
          field: 'id',
          headerName: '',
          minWidth: 50,
          maxWidth: 50,
          sortable: false,
          filter: false,
          cellRenderer: actionCellRenderer,
          getQuickFilterText: () => ''
        }
      : null

    return [
      ...(isBulkUpdateMode ? [checkboxColumn] : []),
      openInNewTabColumnDef('openInNewTab', 'id', {
        cellRendererParams: {
          onActionButtonClick: (params: ICellRendererParams) => {
            window.open(`${window.location.href}/${params.node.data.connectedSourceSystemId}`)
            params.api.deselectAll()
          }
        },
        suppressHeaderMenuButton: true
      }),
      ...(isBulkUpdateMode ? [] : singleColumnEdit ? [singleColumnEdit] : []),
      textSearchColumnDef('name', 'Job Schedule Name', {
        minWidth: 200,
        getQuickFilterText: (params) => params.data.name,
        cellRenderer: isPrimaryBusinessCharger() ? 'openInNewTabCellRenderer' : undefined,
        cellRendererParams: {
          displayType: 'text',
          displayText: (params: ICellRendererParams) => params.node.data.name,
          onActionButtonClick: (params: ICellRendererParams) => {
            const name = params.node.data.name
            const url = `https://us-west-2.console.aws.amazon.com/scheduler/home?region=us-west-2#schedules/data-connector/${name}`
            window.open(url)
            params.api.deselectAll()
          }
        }
      }),
      textSearchColumnDef('connectedSourceSystemId', 'CSS ID'),
      textSearchColumnDef('connectedSourceSystemName', 'CSS Name'),
      textSearchColumnDef('awsEventbridgeScheduleArn', 'Eventbridge Schedule ARN', {
        minWidth: 300
      }),
      // textSearchColumnDef('awsEventbridgeTargetArn', 'Eventbridge Target ARN', { minWidth: 300}),
      // textSearchColumnDef('groupName', 'Group Name'),
      // textSearchColumnDef('businessTimezone', 'Business Timezone'),
      {
        colId: 'scheduleExpression',
        field: 'scheduleExpression',
        headerName: 'Schedule Expression'
      },
      {
        colId: 'scheduleExpressionTimezone',
        field: 'scheduleExpressionTimezone',
        headerName: 'Timezone'
      },
      {
        colId: 'humanizedCron',
        headerName: 'Scheduled Time',
        valueGetter: (params) =>
          humanizeCron(params.data.scheduleExpression, params.data.scheduleExpressionTimezone)
      },
      multiTextColumnDef('state', 'State'),
      numericColumnDef('parallelism', 'Parallelism'),
      numericColumnDef('replicationJobIdFromTargetInput', 'Replication Job ID')
    ]
  }, [isBulkUpdateMode, isPrimaryBusinessCharger, selectedType, isRowSelectable])

  const finalColumnDefs = useMemo(
    () => applyHighlightRenderer(columnDefs, searchTerm, ['openInNewTab', 'name']),
    [columnDefs, searchTerm]
  )

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex min-h-11 w-full items-center space-x-2'>
        <AgGridSearchInput />
        {isPrimaryBusinessCharger() && (
          <>
            {isBulkUpdateMode ? (
              <>
                <div id='bulk-update-form' className='flex items-center'>
                  <Popover>
                    <PopoverTrigger asChild>
                      <Button
                        onClick={handleUpdateSelectedClick}
                        ref={multiUpdatePopoverTriggerRef}
                      >
                        Update
                      </Button>
                    </PopoverTrigger>

                    <PopoverContent className='w-[650px]'>
                      <UpdateMultipleSchedulesForm
                        scheduleTypeIsRust={selectedType === JobScheduleSelectType.Rust}
                        data={selectedRows}
                        onSuccess={() => {
                          closePopover(multiUpdatePopoverTriggerRef.current)
                          setIsBulkUpdateMode(false)
                        }}
                        onCancel={() => closePopover(multiUpdatePopoverTriggerRef.current)}
                      />
                    </PopoverContent>
                  </Popover>
                </div>
              </>
            ) : (
              <Button onClick={handleBulkUpdateClick}>Bulk Update Job Schedules</Button>
            )}
            {isBulkUpdateMode && <Button onClick={handleBulkUpdateCancel}>Cancel</Button>}
          </>
        )}
      </div>
      <Separator className='w-full' />
      <div className='h-[calc(100vh-12rem)] w-full' ref={wrapperRef}>
        <AgGrid
          style={{ height: '100%' }}
          ref={gridRef}
          autoSizeStrategy={{ type: 'fitCellContents' }}
          rowData={jobSchedules}
          columnDefs={finalColumnDefs}
          rowSelection={isBulkUpdateMode ? 'multiple' : 'single'}
          suppressRowClickSelection={isBulkUpdateMode}
          suppressRowDeselection={!isBulkUpdateMode}
          defaultColDef={{
            resizable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            filterParams: {
              buttons: ['clear']
            }
          }}
          editType={'fullRow'}
          loading={isPending}
          isRowSelectable={(node: IRowNode) => isRowSelectable(node.data)}
          onRowSelected={onRowSelected}
          gridOptions={{
            components: {
              getRowNodeId: (data: { id: any }) => data.id,
              openInNewTabCellRenderer: OpenInNewTabCellRenderer
            }
          }}
        />
      </div>
    </div>
  )
}
