import { shortUniqueID } from '@utils/string-utils'

export const FONT_SIZE_MIN = 7
export const FONT_SIZE_MAX = 20

export const ID_UNDEFINED = 99999999 // NOTE: This is just a placeholder ID for our static pages that dont come from DB

// @deprecated
// Use PageType in types/pages.ts
export const LAYOUT_TYPE = {
  PAGE: 'page', // Created using layout builder
  STATIC_PAGE: 'static_page', // Registered using layout builder
  UNREGISTERED: 'unregistered',
  PAGE_GROUP: 'page_group',
  COLLECTION: 'collection'
}

export const GROUP = {
  BASIC: 'Basic Components',
  GRID: 'Grid Components',
  WIDGET: 'Widgets'
}

export const SOURCE = {
  STATIC: 'static', // Component resides in source-code
  TEMPLATE: 'template', // Component is just a template
  EXTERNAL: 'external' // Component resides in DB
}
export const isWidgetSourceStatic = (itemType: string): boolean => itemType === SOURCE.STATIC
export const isWidgetSourceExternal = (itemType: string): boolean => itemType === SOURCE.EXTERNAL

export const SCOPE = {
  GENERAL: 'general',
  BUSINESS_SPECIFIC: 'business-specific'
}

export const EXTERNAL_SOURCE_TYPE = {
  DB: 'DB'
}

export const SIDEBAR_ITEM = 'sidebarItem'

export const GRID = 'grid'

export const COMP_CATEGORY = {
  ROW: 'row',
  COLUMN: 'column',
  WIDGET: 'widget'
}

export const COMP = {
  ROW: {
    SPECIFIC: {
      FREE_STYLE_ROW: {
        CATEGORY: COMP_CATEGORY.ROW,
        NAME: 'freestyle-row',
        TITLE: 'Free Style Row'
      }
    }
  },
  COLUMN: {
    CATEGORY: 'column',
    NAME: 'basic-column',
    TITLE: 'Column'
  },
  WIDGET: {
    CATEGORY: 'widget',
    NAME: 'widget',
    SPECIFIC: {
      KPI: {
        GENRE: 'kpi'
        // NAME: 'kpi',
        // TITLE: 'KPI'
      },
      IMAGE: {
        GENRE: 'image'
        // NAME: 'image',
        // TITLE: 'Image'
      },
      CHART: {
        GENRE: 'chart'
        // NAME: 'chart',
        // TITLE: 'Chart'
      },
      TABLE: {
        GENRE: 'table'
        // NAME: 'table',
        // TITLE: 'Table'
      },
      EMBEDDING: {
        GENRE: 'embedding'
        // NAME: 'embedding',
        // TITLE: 'Embedding'
      },
      DIVIDER: {
        GENRE: 'divider'
        // NAME: 'divider',
        // TITLE: 'Divider'
      },
      FINANCIAL_STATEMENT: {
        GENRE: 'table',
        NAME: 'financial_statement',
        TITLE: 'Financial Statement'
      }
    }
  }
}

export const FILTER_PRIORITY = {
  GLOBAL_FIRST: 'global-first',
  LOCAL_FIRST: 'local-first',
  GLOBAL_ONLY: 'global-only',
  LOCAL_ONLY: 'local-only'
}

export const FILTER_ACTION_ON = {
  EXISTING_DATA: 'existing-data',
  NEW_DATA: 'new-data'
}

export const FILTER_CATEGORY = {
  GRID: 'grid',
  GENERAL: 'general'
}

export const MINIMAL_LAYOUT = [
  {
    id: shortUniqueID('row-'),
    category: COMP.ROW.SPECIFIC.FREE_STYLE_ROW.CATEGORY,
    name: COMP.ROW.SPECIFIC.FREE_STYLE_ROW.NAME,
    children: [
      {
        id: shortUniqueID('column-'),
        category: COMP.COLUMN.CATEGORY,
        children: []
      }
    ]
  }
]
