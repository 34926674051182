import { useCallback, useRef, useState } from 'react'

import { AgGridReact } from 'ag-grid-react'

import { UniqueIdentifier, useDndMonitor } from '@dnd-kit/core'

import AgGrid from '@components/ag-grid'
import { DraggableItems } from '@components/draggable/draggable-items'

import { textSearchColumnDef } from '@layout-components/general/data-connections/utils/col-defs'

import { DetailsPanel } from '@pages/admin/layouts/components/manager/details-panel'
import { useFetchUnassignedPages } from '@pages/admin/layouts/queries/fetch-unassigned-pages'
import {
  LayoutBuilderDetailsModes,
  isPageDetailMode,
  isPageUnassignedMode
} from '@pages/admin/layouts/types'

import { Page } from 'types/page'

export const ModeDetailsPanel = ({
  selectedPageId,
  selectedNode,
  mode
}: {
  selectedPageId: UniqueIdentifier | undefined
  selectedNode: any
  mode: LayoutBuilderDetailsModes
}) => {
  return (
    <div className='my-4 flex-1'>
      {isPageDetailMode(mode) &&
        (selectedPageId ? (
          <DetailsPanel selectedNode={selectedNode} />
        ) : (
          <div>Select a page to view details</div>
        ))}

      {isPageUnassignedMode(mode) && <UnassignedPagesDetails />}
    </div>
  )
}

export const UnassignedPagesDetails = () => {
  const { isLoading, data: unassignedPages } = useFetchUnassignedPages()
  const gridRef = useRef<AgGridReact | null>(null)

  const [selectedPages, setSelectedPages] = useState<Page[]>([])

  const columnDefs = [
    {
      checkboxSelection: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      showDisabledCheckboxes: true,
      minWidth: 40,
      maxWidth: 40,
      pinned: true
    },
    textSearchColumnDef('id', 'ID'),
    textSearchColumnDef('title', 'Title', { pinned: 'left' }),
    textSearchColumnDef('pageRoute', 'Page Route'),
    textSearchColumnDef('slug', 'Slug'),
    textSearchColumnDef('layoutType', 'Layout Type'),
    textSearchColumnDef('path', 'Path'),
    textSearchColumnDef('status', 'Status')
  ]

  const handleSelectionChanged = useCallback(() => {
    const selectedRows = gridRef.current!.api.getSelectedRows()
    setSelectedPages(selectedRows)
  }, [])

  const getPageDragData = (pages: Page[]) => ({ movingItems: selectedPages })

  const renderPageText = (pages: Page[]) =>
    pages.length > 0 ? (
      <>
        <b>{pages.length}</b> Pages Selected. Drag This To a Target
      </>
    ) : (
      <>Select Pages below and drag this button to a target.</>
    )

  useDndMonitor({
    onDragEnd(event) {
      if (event.over) {
        setSelectedPages([])
      }
    }
  })

  return (
    <div className='flex w-full flex-col'>
      <div className='my-3 flex items-center gap-2'>
        <DraggableItems
          items={selectedPages}
          id='draggable-pages'
          getDragData={getPageDragData}
          renderText={renderPageText}
        />
      </div>

      <div className='ag-theme-alpine h-[calc(100vh-230px)] w-full'>
        <AgGrid
          style={{ height: '100%' }}
          ref={gridRef}
          rowData={unassignedPages || []}
          columnDefs={columnDefs}
          rowSelection='multiple'
          suppressRowClickSelection={true}
          enableRangeSelection={true}
          autoSizeStrategy={{ type: 'fitCellContents' }}
          onSelectionChanged={handleSelectionChanged}
          defaultColDef={{
            resizable: true
          }}
          loading={isLoading}
        />
      </div>
    </div>
  )
}
