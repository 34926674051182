import { useEffect, useMemo, useState } from 'react'

import {
  SortDimensionFilters,
  dimensionKeyToDimensionTypeMap,
  selectedDimensionKeyFromDimensionType
} from '@utils/dimension-utils'
import { countItemsNotInSelection, countTotalSelectedItems } from '@utils/obj-utils'
import { DisplayNameSchema, getDisplayName } from '@utils/schema-renaming'
import { capitalizeFirstLetter } from '@utils/string-utils'
import { cn } from '@utils/style-utils'

import { Badge } from '@components/core/badge'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@components/core/collapsible'
import { Separator } from '@components/core/separator'
import { Text } from '@components/core/text'

import { usePageDispatch, usePageSelector } from '@store/index'
import { replaceDimensionsAction, selectDimensions } from '@store/slices/dimension'

import { useFetchRenamings } from '@pages/configuration/queries/fetch-renamings'

import pluralize from 'pluralize'
import { useFetchDimensionsParallel } from 'queries/fetch-dimensions'

import { SkeletonRow } from '../../ag-grid/skeleton-table'
import { Button } from '../../core/button'
import {
  DimensionFilterEnum,
  DimensionFilterType,
  EmptySelectedDimensions,
  SelectedDimensions,
  SelectedDimensionsType
} from '../types'
import { OpenedDimensionView } from './opened-dimension-view'

export const convertDataToOptions = (data: Record<string, string>) => {
  return _.map(data, (label: string, id: string | number) => ({ value: id, label }))
}

const DimensionExplorer = ({
  onClose,
  classNames = ''
}: {
  onClose: () => void
  classNames?: string
}) => {
  const pageDispatch = usePageDispatch()

  const [{ data: dimensions, isLoading }, { data: subsidiaries }] = useFetchDimensionsParallel()

  const appliedDimensions = usePageSelector(selectDimensions)

  const filteredDimensionsList = useMemo(() => {
    return Object.entries(appliedDimensions || {})
      .filter(([_key, value]) => {
        return value?.length > 0
      })
      .sort((a, b) =>
        SortDimensionFilters(
          dimensionKeyToDimensionTypeMap[a[0] as keyof typeof dimensionKeyToDimensionTypeMap],
          dimensionKeyToDimensionTypeMap[b[0] as keyof typeof dimensionKeyToDimensionTypeMap]
        )
      )
  }, [appliedDimensions])

  useEffect(() => {
    const thereIsSubsidiaryWithNoConsolidation = subsidiaries?.some(
      (sub) => !sub.include_in_consolidation && sub.is_visible
    )

    if (
      thereIsSubsidiaryWithNoConsolidation &&
      appliedDimensions.subsidiary_name_ids.length === 0
    ) {
      const subsidiariesIds = _.map(
        _.filter(
          subsidiaries,
          (sub) =>
            sub?.include_in_consolidation &&
            sub?.is_visible &&
            sub?.name_id !== undefined &&
            sub?.name_id !== null
        ),
        (sub) => sub?.name_id?.toString()
      )

      const parsedSubsidiariesIds = [...new Set(subsidiariesIds)]

      pageDispatch(
        replaceDimensionsAction({
          dimensionType: selectedDimensionKeyFromDimensionType(DimensionFilterEnum.subsidiaries),
          items: parsedSubsidiariesIds ?? []
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- we need to not trigger when selectedDimensions change
  }, [subsidiaries, pageDispatch])

  const [firstFilteredDimension = null] = _.first(filteredDimensionsList) || []

  const [openedDimension, setOpenedDimension] = useState<string | null>(
    dimensionKeyToDimensionTypeMap[
      firstFilteredDimension as keyof typeof dimensionKeyToDimensionTypeMap
    ] || null
  )

  const [openedFilters, setOpenedFilters] = useState<string[]>(
    filteredDimensionsList?.map(
      ([key]) => dimensionKeyToDimensionTypeMap[key as keyof typeof dimensionKeyToDimensionTypeMap]
    ) || []
  )

  const [selectedFilters, setSelectedFilters] = useState<SelectedDimensions>(appliedDimensions)
  const [deletedFilters, setDeletedFilters] = useState<SelectedDimensions>(EmptySelectedDimensions)
  const [isDirty, setIsDirty] = useState(false)

  const handleFilterChange = (identifier: string, selectedItems: string[]) => {
    const dimensionType = identifier as SelectedDimensionsType

    setSelectedFilters((prevSelectedFilters) => {
      return { ...prevSelectedFilters, [dimensionType]: selectedItems }
    })

    const itemsToDelete = _.difference(appliedDimensions[dimensionType], selectedItems)

    setDeletedFilters((prevDeletedFilters) => {
      const updatedDeletedFilters: SelectedDimensions = { ...prevDeletedFilters }
      updatedDeletedFilters[dimensionType] = itemsToDelete
      return updatedDeletedFilters
    })

    setIsDirty(true)
  }

  const { data: renamingsData } = useFetchRenamings(true)
  const renamingHeaders = useMemo(() => {
    const renamings = (renamingsData || {}) as DisplayNameSchema
    const headers: Record<string, string> = {}
    if (dimensions) {
      Object.keys(dimensions).forEach((key) => {
        const formattedKey = _.split(pluralize.singular(key), '_')
          .map((k) => capitalizeFirstLetter(k))
          .join('')
        const selected = getDisplayName(renamings, formattedKey)
        headers[key] = pluralize(selected || _.join(_.split(key, '_'), ' ')).toString()
      })
    }
    return headers
  }, [dimensions, renamingsData])

  const onCancel = () => {
    clearStage()

    if (!isDirty) {
      onClose()
    }
  }

  const clearStage = () => {
    setSelectedFilters(EmptySelectedDimensions)
    setDeletedFilters(EmptySelectedDimensions)
    setIsDirty(false)
  }

  const applyFilters = () => {
    Object.entries(selectedFilters).forEach(([key, value]) => {
      const dimensionType = key as DimensionFilterType
      const itemsToAdd: string[] = value
      const itemsToDelete =
        deletedFilters[dimensionType as unknown as keyof SelectedDimensions] || []

      if (_.isEmpty(itemsToAdd) && _.isEmpty(itemsToDelete)) return
      pageDispatch(
        replaceDimensionsAction({ dimensionType: key as DimensionFilterType, items: value })
      )
    })

    setDeletedFilters(EmptySelectedDimensions)
    setIsDirty(false)
  }

  if (isLoading) return <SkeletonRow columnsLength={1} />
  if (!dimensions || Object.keys(dimensions).length === 0) return null

  const totalSelected = countItemsNotInSelection(selectedFilters, appliedDimensions)
  const totalDeleted = countTotalSelectedItems(deletedFilters) || 0

  const totalCount = totalSelected + totalDeleted
  const isDisabled = totalCount <= 0 || !isDirty

  return (
    <div className='flex h-[calc(100%-64px)] max-w-[778px]'>
      <div
        className={cn(
          'flex h-full transition-all',
          openedDimension ? 'w-[300px] blur-0' : 'w-0 blur-md'
        )}
      >
        {openedDimension && (
          <OpenedDimensionView
            dimensionKey={openedDimension}
            filterValue={dimensions[openedDimension as keyof typeof dimensions]}
            header={renamingHeaders[openedDimension]}
            subsidiaries={(subsidiaries as any) || []}
            selectedValues={_.difference(
              _.union(
                appliedDimensions[
                  selectedDimensionKeyFromDimensionType(openedDimension as DimensionFilterType)
                ] || [],
                selectedFilters[
                  selectedDimensionKeyFromDimensionType(openedDimension as DimensionFilterType)
                ] || []
              ),
              deletedFilters[
                selectedDimensionKeyFromDimensionType(openedDimension as DimensionFilterType)
              ] || []
            )}
            onSelectedValuesChange={(selectedIds) => {
              handleFilterChange(
                selectedDimensionKeyFromDimensionType(openedDimension as DimensionFilterType),
                selectedIds
              )
            }}
          />
        )}
      </div>
      {openedDimension && <Separator orientation='vertical' className='mx-2' />}
      <div
        className={cn(
          'z-10 mb-[100px] flex h-full w-[478px] flex-col bg-white px-6 py-4',
          classNames
        )}
      >
        <div className='flex items-center justify-between pt-3'>
          <Text variant='h5' className='text-black-dark'>
            Dimension Filters
          </Text>
          <div className='flex items-center justify-between'>
            <Button variant='outline' color='primary' onClick={onCancel}>
              {isDirty ? 'Cancel' : 'Close'}
            </Button>
            <Button
              variant={isDisabled ? 'disabled' : 'primary'}
              onClick={applyFilters}
              disabled={isDisabled}
              className='ml-4'
            >
              {totalCount ? `(${totalCount}) Apply` : 'Apply'}
            </Button>
          </div>
        </div>
        <Separator className='my-2' />
        {Object.entries(dimensions)
          .sort((a, b) => {
            const [keyA] = a
            const [keyB] = b
            return SortDimensionFilters(keyA, keyB)
          })
          .map(([key, filterValue]) => {
            const dimensionFilterKey: SelectedDimensionsType =
              selectedDimensionKeyFromDimensionType(key as DimensionFilterType)
            const header = renamingHeaders[key]

            const totalSelectedValues = isDirty
              ? _.difference(
                  _.union(
                    appliedDimensions[dimensionFilterKey] || [],
                    selectedFilters[dimensionFilterKey] || []
                  ),
                  deletedFilters[dimensionFilterKey] || []
                )
              : appliedDimensions[dimensionFilterKey] || []

            return (
              <div className='dimension-filter-item-container mb-2 w-full' key={key}>
                <Collapsible
                  open={openedFilters.includes(key)}
                  onOpenChange={() => {
                    // Check if this dimension filter is currently open
                    const isOpen = openedFilters.includes(key)

                    if (!isOpen) {
                      // If not open, add it to openedFilters and set as current dimension
                      setOpenedFilters([...openedFilters, key])
                      setOpenedDimension(key)
                    } else if (openedDimension !== key) {
                      // If open but not the current dimension, update current dimension
                      setOpenedDimension(key)
                    } else {
                      // If open and is current dimension, close it
                      setOpenedFilters(openedFilters.filter((k) => k !== key))
                      setOpenedDimension(null)
                    }
                  }}
                >
                  <CollapsibleTrigger asChild>
                    <div
                      className='flex cursor-pointer select-none items-center justify-between py-4'
                      onClick={(e) => e.stopPropagation()}
                    >
                      <Text
                        variant='h5'
                        className={cn(
                          'font-medium',
                          openedDimension === key ? 'text-black' : 'text-primary'
                        )}
                      >
                        {header}
                      </Text>
                    </div>
                  </CollapsibleTrigger>
                  <CollapsibleContent>
                    <div
                      className='my-2 flex flex-wrap gap-2 rounded-md border border-solid border-grey-light bg-white p-2 transition-colors focus-within:border-primary focus-within:ring-1 focus-within:ring-primary'
                      tabIndex={0}
                      onClick={() => {
                        setOpenedDimension(key)
                      }}
                    >
                      {totalSelectedValues.length === 0 ? (
                        <div className='text-grey-dark'>No {header} selected</div>
                      ) : (
                        <>
                          {totalSelectedValues.slice(0, 7).map((value) => (
                            <Badge key={value} variant='squareGrey'>
                              {filterValue[value]}
                            </Badge>
                          ))}
                          {totalSelectedValues.length > 7 && (
                            <Badge variant='squareGrey'>
                              +{totalSelectedValues.length - 7} more
                            </Badge>
                          )}
                        </>
                      )}
                    </div>
                  </CollapsibleContent>
                </Collapsible>
              </div>
            )
          })}
      </div>
    </div>
  )
}

export default DimensionExplorer
