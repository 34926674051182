import React, { useEffect, useMemo, useRef, useState } from 'react'
import { createPortal } from 'react-dom'

import { DndContext, DragEndEvent, DragOverEvent, DragOverlay, DragStartEvent } from '@dnd-kit/core'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'

import { restrictToCustomParentElement } from '@utils/dnd-utils'
import { cn } from '@utils/style-utils'

import { DataPill } from '@components/component-management/DataPill'
import { DraggableDataPill } from '@components/component-management/DraggableDataPill'
import { Checkbox } from '@components/core/checkbox'
import { Separator } from '@components/core/separator'
import { Text } from '@components/core/text'
import { Eye, EyeOff, Icon } from '@components/icons'

import useChangeDetection from '@hooks/use-change-detection'

import { IModelFieldDataType } from 'pages/component-management/types/query-builder-types'

import { ITableColumn } from '../../types/table-builder-types'
import { DragOverLine } from './drag-over-line'
import { DroppableContainer } from './droppable-container'
import {
  Containers,
  SectionLayoutType,
  isContainerAllColumns,
  isContainerColumnLabels,
  isContainerRowGroups,
  isContainerValues,
  isWithinOrganizableContainers
} from './types'
import {
  createSectionLayoutFromColumns,
  fieldIdForAllColumnsContainer,
  getColumnField,
  isIdInAllColumnsContainer,
  onColumnRemovedFromContainers,
  onColumnVisibilityChange,
  onSectionLayoutAndColumnOrderChange
} from './utils'
import { WithContainerColumnsSettings } from './with-container-columns-settings'

export const ColumnOrganization = ({
  columns: initialColumns,
  onChangeColumns,
  showHidden,
  paginated
}: {
  columns: ITableColumn[]
  onChangeColumns: (newColumns: ITableColumn[]) => void
  showHidden: boolean
  paginated: boolean
}) => {
  const parentRef = useRef<HTMLDivElement>(null)

  const [columns, setColumns] = useState(initialColumns)
  useEffect(() => setColumns(initialColumns), [initialColumns])

  const [sectionLayout, setSectionLayout] = useState(createSectionLayoutFromColumns(columns))
  useEffect(() => setSectionLayout(createSectionLayoutFromColumns(columns)), [columns])

  const columnsMap = useMemo(() => _.keyBy(columns, 'field'), [columns])

  // Note: When an item is dragged from the all_columns container to another container, the item's ID is copied (transiently, only when its in the process of dragging).
  // As soon as the DndContext identifies a new DataPill with the same ID, it invalidates the previous one.
  // When the item is moved back to the all_columns container without releasing the event, the previous item is not recognized.
  // Therefore, it is necessary to re-bind the DataPill with the same ID, which requires triggering a re-render.
  const [refreshAllColumnsKey, triggerRefreshAllColumns] = useChangeDetection()

  const [allColumnsDragOverPosition, setAllColumnsDragOverPosition] = useState<number | null>(null)
  const resetAllColumnsDragOverPosition = () => setAllColumnsDragOverPosition(null)
  const [currentActiveId, setCurrentActiveId] = useState<string | null>(null)
  const resetCurrentActiveId = () => setCurrentActiveId(null)
  const currentActiveField = useMemo(
    () => (currentActiveId ? getColumnField(currentActiveId) : null),
    [currentActiveId]
  )
  const currentActiveColumn = currentActiveField ? columnsMap[currentActiveField] : null
  const [currentActiveContainer, setCurrentActiveContainer] = useState<string | null>(null)
  const resetCurrentActiveContainer = () => setCurrentActiveContainer(null)
  const [currentOverContainer, setCurrentOverContainer] = useState<string | null>(null)
  const resetCurrentOverContainer = () => setCurrentOverContainer(null)
  const [dropDisabledOnCurrentOverContainer, setDropDisabledOnCurrentOverContainer] =
    useState<boolean>(false)

  const [columnLabelsControlSelected, setColumnLabelsControlSelected] = useState<string | null>(
    null
  )

  const disableActions = !!columnLabelsControlSelected

  const handleSectionLayoutAndColumnOrderChange = (
    sectionLayout: SectionLayoutType,
    columns: ITableColumn[]
  ) => {
    const newColumns = onSectionLayoutAndColumnOrderChange(sectionLayout, columns)
    onChangeColumns(newColumns)
  }

  const handleColumnVisibilityChange = (field: string, checked: boolean) => {
    const newColumns = onColumnVisibilityChange(field, checked, columns, sectionLayout)
    setColumns(newColumns)
    onChangeColumns(newColumns)
  }

  const createRemoveColumnHandler = (field: string, container: string) => () => {
    const newColumns = onColumnRemovedFromContainers(field, container, columns, sectionLayout)
    setColumns(newColumns)
    onChangeColumns(newColumns)
  }

  const getContainer = (id?: string) => {
    // first look at the name of containers
    if (_.keys(sectionLayout).includes(`${id}`)) return `${id}`

    return (
      _.findKey(sectionLayout, (fields) => _.includes(fields, `${id}`)) || // then try to find the container by field
      // Note: The "isIdInAllColumnsContainer" check should be performed after checking other containers,
      // because when dragging from the all_columns container, there is a transient state (when dragging has not yet been released)
      // in which the same ID is used in the organizable containers.
      (isIdInAllColumnsContainer(id) ? Containers.ALL_COLUMNS : null)
    )
  }

  const columnIdsInAllColumnsContainer = useMemo(
    () =>
      _.map(
        showHidden ? columns : _.filter(columns, (column) => !column.hide), // filter out hidden columns
        (column) => fieldIdForAllColumnsContainer(column.field)
      ),
    [columns, showHidden]
  )

  const handleDragStart = (event: DragStartEvent) => {
    const { active } = event
    const activeId = active.id.toString()
    setCurrentActiveId(activeId)
    setCurrentActiveContainer(getContainer(activeId))
  }

  const handleDragOver = (event: DragOverEvent) => {
    const { over } = event
    const overId = over?.id.toString()

    const currentOverContainer = getContainer(overId)
    setCurrentOverContainer(currentOverContainer)

    if (!currentActiveContainer || !currentOverContainer) return

    if (
      isContainerAllColumns(currentActiveContainer) &&
      _.chain(sectionLayout)
        .values()
        .flatten()
        .includes(currentActiveField as string)
        .value()
    ) {
      // prevent dragging from all_columns container to organizable containers if the field is already present
      return
    }

    setDropDisabledOnCurrentOverContainer(false)

    if (currentActiveContainer !== currentOverContainer) {
      if (
        (!currentActiveColumn?.enableRowGroup && isContainerRowGroups(currentOverContainer)) ||
        (!currentActiveColumn?.enableValue && isContainerValues(currentOverContainer)) ||
        (!currentActiveColumn?.enablePivot && isContainerColumnLabels(currentOverContainer))
      ) {
        // Only allow dragging if the field is enabled for the target container
        setDropDisabledOnCurrentOverContainer(true)
        return
      }

      const finalSectionLayout = _.cloneDeep(sectionLayout)

      if (!isContainerAllColumns(currentActiveContainer)) {
        _.assign(finalSectionLayout, {
          [currentActiveContainer]: _.without(
            sectionLayout[currentActiveContainer],
            currentActiveId
          )
        })
      }

      if (isContainerAllColumns(currentOverContainer)) {
        _.assign(finalSectionLayout, {
          [currentActiveContainer]: _.without(
            sectionLayout[currentActiveContainer],
            currentActiveId
          )
        })
      } else {
        _.assign(finalSectionLayout, {
          [currentOverContainer]: [...sectionLayout[currentOverContainer], currentActiveId]
        })
      }

      if (isContainerAllColumns(currentOverContainer)) {
        triggerRefreshAllColumns()
      }
      setCurrentActiveContainer(currentOverContainer)
      setSectionLayout(finalSectionLayout)
    }
  }

  const handleDragEnd = (event: DragEndEvent) => {
    const { over } = event
    const overId = over?.id.toString()
    if (!overId) return

    const currentOverContainer = getContainer(overId)
    const currentOverField = getColumnField(overId)

    let newColumns = columns
    let newSectionLayout = sectionLayout

    if (
      isContainerAllColumns(currentActiveContainer) &&
      isContainerAllColumns(currentOverContainer)
    ) {
      const oldIndex = _.findIndex(newColumns, (column) =>
        _.isEqual(column.field, currentActiveField)
      )
      const newIndex = _.findIndex(newColumns, (column) =>
        _.isEqual(column.field, currentOverField)
      )

      newColumns = arrayMove(newColumns, oldIndex, newIndex)

      setColumns(newColumns)
    } else if (
      _.isEqual(currentActiveContainer, currentOverContainer) &&
      isWithinOrganizableContainers(currentActiveContainer) &&
      isWithinOrganizableContainers(currentOverContainer)
    ) {
      newSectionLayout = _.cloneDeep(sectionLayout)

      const items = newSectionLayout[currentActiveContainer!]
      const oldIndex = items.indexOf(currentActiveId!)
      const newIndex = items.indexOf(overId)

      newSectionLayout[currentActiveContainer!] = arrayMove(items, oldIndex, newIndex)

      // change transient field ID (that was picked from all_columns container) to actual field ID
      newSectionLayout = _.mapValues(newSectionLayout, (fields) =>
        _.map(fields, (field) => getColumnField(field))
      ) as SectionLayoutType

      setSectionLayout(newSectionLayout)
    }

    // trigger
    handleSectionLayoutAndColumnOrderChange(newSectionLayout, newColumns)

    // reset
    resetAllColumnsDragOverPosition()
    resetCurrentActiveId()
    resetCurrentActiveContainer()
    resetCurrentOverContainer()
    setDropDisabledOnCurrentOverContainer(false)
    setTimeout(() => triggerRefreshAllColumns(), 0)
  }

  return (
    <div ref={parentRef}>
      <DndContext
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
        modifiers={[restrictToCustomParentElement(parentRef)]}
      >
        <div className='flex flex-col gap-2' key={refreshAllColumnsKey}>
          <SortableContext
            items={columnIdsInAllColumnsContainer}
            strategy={verticalListSortingStrategy}
          >
            {columnIdsInAllColumnsContainer.map((columnId, index) => {
              const column = columnsMap[getColumnField(columnId)]

              return (
                <div className={cn('relative flex items-center gap-4')} key={column.field}>
                  {isContainerAllColumns(currentActiveContainer) &&
                    allColumnsDragOverPosition !== null &&
                    _.isEqual(Math.abs(allColumnsDragOverPosition), index + 1) && (
                      <DragOverLine dragOverPosition={allColumnsDragOverPosition} />
                    )}
                  <Checkbox
                    disabled={disableActions}
                    checked={!column.hide}
                    onCheckedChange={(checked) =>
                      handleColumnVisibilityChange(column.field, checked as boolean)
                    }
                  />
                  <Icon
                    icon={column.hide ? <EyeOff /> : <Eye />}
                    className={cn(column.hide && 'text-grey')}
                  />

                  <DraggableDataPill
                    id={columnId}
                    currentActiveField={currentActiveField}
                    setDragOverPosition={setAllColumnsDragOverPosition}
                    label={column.headerName}
                    dataType={column.cellDataType}
                    disabled={disableActions}
                  />
                </div>
              )
            })}
          </SortableContext>
        </div>

        {!paginated && <Separator className='my-2' />}

        {!paginated &&
          _.keys(sectionLayout).map((section) => (
            <React.Fragment key={section}>
              <Text
                variant='details'
                weight='normal'
                className='my-2 not-italic text-primary-darker'
              >
                {section}
              </Text>
              <DroppableContainer
                id={section}
                isOverContainer={_.isEqual(currentOverContainer, section)}
                dropDisabled={dropDisabledOnCurrentOverContainer}
              >
                {_.size(sectionLayout[section]) ? (
                  <SortableContext
                    items={sectionLayout[section]}
                    strategy={verticalListSortingStrategy}
                  >
                    {sectionLayout[section].map((field) => {
                      // temporary state where field is in process of dragging from all_columns container
                      const column = columnsMap[getColumnField(field)]
                      if (!column) return null // When column is removed in the data preview, it will not be present in the columnsMap

                      return (
                        <WithContainerColumnsSettings
                          key={field}
                          {...{
                            column,
                            section,
                            columns,
                            setColumns,
                            onChangeColumns,
                            isOverContainer: _.isEqual(currentOverContainer, section),
                            columnLabelsControlSelected,
                            setColumnLabelsControlSelected
                          }}
                        >
                          <DraggableDataPill
                            key={field}
                            id={field}
                            animateDragOver
                            onDelete={createRemoveColumnHandler(field, section)}
                            label={column.headerName}
                            dataType={column.cellDataType}
                            disabled={disableActions}
                          />
                        </WithContainerColumnsSettings>
                      )
                    })}
                  </SortableContext>
                ) : (
                  <DataPill label='Example' dataType={IModelFieldDataType.INTEGER} disabled />
                )}
              </DroppableContainer>
            </React.Fragment>
          ))}

        {createPortal(
          <DragOverlay>
            {currentActiveColumn ? (
              <DataPill
                label={currentActiveColumn.headerName}
                dataType={currentActiveColumn.cellDataType}
                selected
              />
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </DndContext>
    </div>
  )
}
