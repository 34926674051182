import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { Box, Grid, Stack } from '@mui/material'

import { ColDef, GetMainMenuItemsParams, GridReadyEvent } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'

import { useQueryClient } from '@tanstack/react-query'

import { useToggle } from '@uidotdev/usehooks'
import { isNotEqual } from '@utils/lodash'

import Button from '@core/button'
import { ToggleGroup, ToggleGroupItem } from '@core/toggle-group'

import { getSkeletonColumns } from '@components/ag-grid/utils'
import { JSONEditor, SQLEditor } from '@components/code-editors'

import { useDispatch, usePageSelector, useSelector } from '@store/index'
import {
  removeFormula,
  selectParameterizedConfigAsHeaders,
  selectQueryData,
  setFilters,
  setHiddenFilters,
  setSorts,
  updateParameterName
} from '@store/slices/component/query-config'
import { selectExpandedDateFilterInfo } from '@store/slices/date-time-filter'
import { selectDimensions } from '@store/slices/dimension'

import useAuth from 'hooks/useAuth'

import { useComponentAttrRef } from '../../hooks/use-component-attr-refs'
import {
  IClientComponentParameters,
  IComponentSortItem,
  IDataType
} from '../../types/component-types'
import AddFormulaModal from './add-formula-modal'
import { AddParameterModal } from './add-parameter-modal'
import { CopyConfig } from './copy-config'
import ServerSideDatasource from './datasource'
import RunQueryButton from './run-query-button'

import '../../common/query-builder-ag-grid.css'

enum DisplayType {
  JSON = 'json',
  SQL = 'sql'
}

const isDisplayTypeJSON = (value?: DisplayType) => _.isEqual(value, DisplayType.JSON)
const isDisplayTypeSQL = (value?: DisplayType) => _.isEqual(value, DisplayType.SQL)

export default function RunQueryDetail() {
  const dispatch = useDispatch()
  const {
    queryDataRef,
    limitRef,
    filtersRef,
    hiddenFiltersRef,
    sortsRef,
    formulasRef,
    parameterizedConfigRef
  } = useComponentAttrRef()
  const query = useSelector(selectQueryData())
  const [display, setDisplay] = useState<DisplayType>()
  const [sqlQuery, setSqlQuery] = useState<string>('')
  const [formulaModalOpen, setFormulaModalOpen] = useState(false)
  const [parameterizeColumnModalOpen, setParameterizeColumnModalOpen] = useState(false)
  const [selectedFormulaName, setSelectedFormulaName] = useState<string | null>(null)
  const [selectedParameterHeader, setSelectedParameterHeader] = useState<{
    name: string
    dataType: IDataType
  } | null>(null)
  const queryClient = useQueryClient()
  const dateRange = usePageSelector(selectExpandedDateFilterInfo)
  const { startDate, endDate, frequency } = dateRange || {}
  const dimensionFilters = usePageSelector(selectDimensions)
  const parameterizedConfigAsHeaders = useSelector(selectParameterizedConfigAsHeaders)
  const parametersRef = useRef<Partial<IClientComponentParameters>>({
    start_date: startDate,
    end_date: endDate,
    date_truncation: frequency,
    dimension_filters: dimensionFilters
  })
  const { user } = useAuth()
  const businessIdRef = useRef(user?.business_id!)
  const gridRef = useRef<AgGridReact>(null)
  const hiddenGridRef = useRef<AgGridReact>(null)
  const columnDefs = useMemo<ColDef[]>(() => getSkeletonColumns(), [])
  const [showHidden, toggleHidden] = useToggle(false)
  const [hiddenColumnsDefs, setHiddenColumsDefs] = useState<ColDef[]>([])

  useEffect(() => {
    parametersRef.current = {
      start_date: startDate,
      end_date: endDate,
      date_truncation: frequency,
      dimension_filters: dimensionFilters
    }

    if (gridRef.current?.api) {
      gridRef.current.api.refreshServerSide({ purge: true })
    }
  }, [startDate, endDate, frequency, dimensionFilters])

  const defaultColDef = useMemo<ColDef>(
    () => ({
      filter: true,
      sortable: true,
      floatingFilter: false
    }),
    []
  )

  const onGridReady = (params: GridReadyEvent) => {
    const datasource = new ServerSideDatasource(
      queryClient,
      businessIdRef,
      queryDataRef,
      parametersRef,
      hiddenFiltersRef,
      filtersRef,
      sortsRef,
      formulasRef,
      parameterizedConfigRef,
      limitRef,
      setSqlQuery,
      setHiddenColumsDefs
    )
    params.api.setGridOption('serverSideDatasource', datasource)
  }

  const refreshData = () => {
    if (gridRef.current?.api) {
      gridRef.current.api.refreshServerSide({ purge: true })
    }
  }

  const handleRunQuery = () => {
    refreshData()
  }

  const saveFilters = () => {
    if (gridRef.current?.api) {
      const filterModel = gridRef.current.api.getAdvancedFilterModel()
      dispatch(setFilters(filterModel))
    }
  }

  const saveHiddenFitlers = () => {
    if (hiddenGridRef.current?.api) {
      const filterModel = hiddenGridRef.current.api.getFilterModel()
      if (isNotEqual(filterModel, hiddenFiltersRef.current)) {
        dispatch(setHiddenFilters(filterModel))
        refreshData()
      }
    }
  }

  const saveSort = () => {
    if (gridRef.current?.api) {
      const colState = gridRef.current.api.getColumnState()
      const sortState = colState
        .filter(function (s) {
          return s.sort != null
        })
        .map(function (s) {
          return { colId: s.colId, sort: s.sort, sortIndex: s.sortIndex }
        })
      dispatch(setSorts(sortState as IComponentSortItem[]))

      // refresh data
      // Sort change is not automatically detected in this component, so we need to manually trigger a refresh.
      refreshData()
    }
  }

  const onAddFormulaClick = () => {
    setSelectedFormulaName(null)
    setFormulaModalOpen(true)
  }

  const getMainMenuItems = useCallback(
    (params: GetMainMenuItemsParams) => {
      const colDef = params.column.getColDef()
      const formulaColumn = _.includes(colDef.headerClass as string, 'formula')

      const paramterizedConfigForHeader = parameterizedConfigAsHeaders[colDef.field as string]
      const paramterizedConfigForHeaderPresent = !!paramterizedConfigForHeader

      let dataType = IDataType.TEXT
      if (typeof colDef.cellDataType === 'string') {
        dataType = colDef.cellDataType as IDataType
      }

      const field = colDef?.field

      if (!field) return []

      return _.concat(
        [
          {
            name: paramterizedConfigForHeaderPresent
              ? 'Edit Parameterized Value'
              : 'Parameterize Column',
            action: () => {
              setSelectedParameterHeader({
                name: field,
                dataType
              })
              setParameterizeColumnModalOpen(true)
            }
          }
        ],
        paramterizedConfigForHeaderPresent
          ? [
              {
                name: 'Remove Parameterized Value',
                action: () => {
                  dispatch(updateParameterName({ parameterName: '', headerName: field }))
                  refreshData()
                }
              }
            ]
          : [],
        formulaColumn
          ? [
              {
                name: 'Edit Formula',
                action: () => {
                  setSelectedFormulaName(field)
                  setFormulaModalOpen(true)
                }
              },
              {
                name: 'Remove Formula',
                action: () => {
                  dispatch(removeFormula(field))
                  refreshData()
                }
              }
            ]
          : []
      )
    },
    [dispatch, parameterizedConfigAsHeaders]
  )

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack direction='row' spacing={2}>
          <RunQueryButton handleRunQuery={handleRunQuery} />
          <ToggleGroup
            type='single'
            onValueChange={(value) => {
              setDisplay(value as DisplayType)
            }}
          >
            <ToggleGroupItem
              size='button'
              value={DisplayType.JSON}
              selected={isDisplayTypeJSON(display)}
            >
              JSON
            </ToggleGroupItem>
            <ToggleGroupItem
              size='button'
              value={DisplayType.SQL}
              selected={isDisplayTypeSQL(display)}
            >
              SQL
            </ToggleGroupItem>
          </ToggleGroup>
          {_.gt(_.size(hiddenColumnsDefs), 0) && (
            <Button variant='outline' onClick={() => toggleHidden()}>
              Show Hidden
            </Button>
          )}
          <Button onClick={onAddFormulaClick}>Add Formula</Button>
          <CopyConfig />
        </Stack>
      </Grid>
      {isDisplayTypeJSON(display) && (
        <Grid item xs={12}>
          <JSONEditor value={JSON.stringify(query, null, 2)} readOnly />
        </Grid>
      )}
      {isDisplayTypeSQL(display) && (
        <Grid item xs={12}>
          <SQLEditor value={sqlQuery || '-- SQL Query Not Found'} readOnly />
        </Grid>
      )}
      {formulaModalOpen && (
        <Grid item xs={12}>
          <AddFormulaModal
            open={formulaModalOpen}
            handleClose={() => setFormulaModalOpen(false)}
            refreshData={refreshData}
            selectedFormulaName={selectedFormulaName}
          />
        </Grid>
      )}
      {parameterizeColumnModalOpen && (
        <AddParameterModal
          open={parameterizeColumnModalOpen}
          onOpenChange={setParameterizeColumnModalOpen}
          selectedHeader={selectedParameterHeader}
          onSubmit={() => refreshData()}
        />
      )}
      <Grid item xs={12}>
        <div className='flex flex-row'>
          <Box
            className='ag-theme-alpine ag-component-query-builder'
            style={{ height: 'calc(100vh - 180px)', width: '100%', flex: 1 }}
          >
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              onGridReady={onGridReady}
              suppressServerSideFullWidthLoadingRow
              // serverside
              rowModelType='serverSide'
              rowBuffer={0}
              cacheBlockSize={5000}
              cacheOverflowSize={5}
              maxConcurrentDatasourceRequests={1}
              infiniteInitialRowCount={5000}
              maxBlocksInCache={10}
              // filters
              enableAdvancedFilter
              includeHiddenColumnsInAdvancedFilter={false}
              onFilterChanged={saveFilters}
              // sort
              onSortChanged={saveSort}
              // menu
              getMainMenuItems={getMainMenuItems}
            />
          </Box>

          {showHidden && (
            <div
              className='ag-theme-alpine ag-component-hidden-fields'
              style={{ width: '250px', height: 'calc(100vh - 180px)' }}
            >
              <AgGridReact
                ref={hiddenGridRef}
                columnDefs={hiddenColumnsDefs}
                rowData={[]}
                sideBar={{ toolPanels: ['filters'], defaultToolPanel: 'filters' }}
                onFilterChanged={saveHiddenFitlers}
                onGridReady={(event) => event.api.setFilterModel(hiddenFiltersRef.current)}
              />
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  )
}
