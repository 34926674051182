import { useState } from 'react'

import { getDisplayName } from '@utils/schema-renaming'

import { useDispatch, useSelector } from '@store/index'
import {
  selectFieldConfigs,
  selectIsFieldSelected,
  setFieldConfigs
} from '@store/slices/component/query-config'

import { useFetchRenamings } from '@pages/configuration/queries/fetch-renamings'

import { IColumnData, IFieldAttribute } from '../../types/query-builder-types'
import { ColumnAliasSection } from './column-alias-section'

export function ColumnSection({
  modelName,
  field,
  parent
}: {
  modelName: string
  field: IColumnData
  parent: string
}) {
  const { field_type, name } = field
  const dispatch = useDispatch()
  const isFieldSelected = useSelector(selectIsFieldSelected(name, parent))
  const fieldConfigs = useSelector(selectFieldConfigs(name, parent))

  const { data: renamingsData } = useFetchRenamings(true)
  const renamings = renamingsData || {}

  const renamedFieldName = getDisplayName(renamings, modelName, name)

  const [configs, setConfigs] = useState(fieldConfigs)

  const addConfig = () => {
    const currentName = renamedFieldName ? _.snakeCase(renamedFieldName) : name
    let newNameAlias = `${currentName}_clone`
    let counter = 1
    const isAliasUsed = (alias: string) => (config: Partial<IFieldAttribute>) =>
      config.name_alias === alias

    while (configs.some(isAliasUsed(newNameAlias))) {
      newNameAlias = `${currentName}_clone_${counter}`
      counter++
    }

    const newConfigs = [...configs, { name_alias: newNameAlias }]
    setConfigs(newConfigs)
    dispatch(setFieldConfigs({ fieldName: name, path: parent, configs: newConfigs }))
  }

  const removeConfig = (index: number) => {
    const newConfigs = configs.filter((_, i) => i !== index)
    setConfigs(newConfigs)
    dispatch(setFieldConfigs({ fieldName: name, path: parent, configs: newConfigs }))
  }

  const updateConfig = (index: number, changes: Partial<IFieldAttribute>) => {
    const newConfigs = [...configs]
    newConfigs[index] = { ...newConfigs[index], ...changes }
    setConfigs(newConfigs)
    dispatch(setFieldConfigs({ fieldName: name, path: parent, configs: newConfigs }))
  }

  if (!isFieldSelected) return null

  return (
    <>
      {fieldConfigs.map((config, index) => (
        <ColumnAliasSection
          key={`${name}_${index}`}
          {...{
            fieldName: name,
            renamedFieldName,
            fieldType: field_type,
            config,
            addConfig,
            removeConfig: () => removeConfig(index),
            updateConfig: (changes) => updateConfig(index, changes)
          }}
        />
      ))}
    </>
  )
}
