import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Stack } from '@mui/material'

import { ICellRendererParams } from 'ag-grid-community'

import { IconButton } from '@components/core/icon-button'
import Tooltip from '@components/core/tooltip'
import { Icon } from '@components/icons'
import { Copy } from '@components/icons/copy'
import { Eye } from '@components/icons/eye'
import { Pencil } from '@components/icons/pencil'
import { Trash } from '@components/icons/trash'

import { useCloneComponent } from '../queries/clone-component'
import { useDeleteComponent } from '../queries/delete-component'
import { IComponentData } from '../types'
import DeleteConfirmation from './delete-confirmation'
import PreviewComponent from './preview-component'

const openComponentEditView = (data: IComponentData) => (e: React.MouseEvent<HTMLElement>) => {
  e.preventDefault()
  const id = _.get(data, 'id')
  const source = _.get(data, 'source')
  const relativeUrl = _.isEqual(source, 'static') ? `register-component/${id}/edit` : `${id}`
  const url = `${window.location.href}/${relativeUrl}`
  window.open(url)
}

export default function ActionCellRenderer(props: ICellRendererParams) {
  const { mutate: clone, isPending: clonePending } = useCloneComponent()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [openPreview, setOpenPreview] = useState(false)
  const { mutate: deleteComponent, isPending: deleteLoading } = useDeleteComponent(
    props.value as number
  )
  const { data, value } = props

  return (
    <Stack
      direction='row'
      spacing={1}
      alignItems='center'
      justifyContent='center'
      sx={{ height: '100%' }}
    >
      <PreviewComponent
        open={openPreview}
        setOpen={setOpenPreview}
        id={value}
        genre={_.get(data, 'genre')}
        label={_.get(data, 'title')}
      />
      <Tooltip title='Edit'>
        <IconButton onClick={openComponentEditView(data)}>
          <Icon icon={<Pencil />} />
        </IconButton>
      </Tooltip>
      <Tooltip title='Preview'>
        <IconButton onClick={() => setOpenPreview(true)}>
          <Icon icon={<Eye />} />
        </IconButton>
      </Tooltip>
      <Tooltip title='Clone'>
        <IconButton
          disabled={clonePending}
          onClick={() =>
            clone(
              { id: props.value },
              {
                onSuccess: (data) => {
                  navigate(`${_.get(data, 'data.id')}`)
                }
              }
            )
          }
        >
          <Icon icon={<Copy />} />
        </IconButton>
      </Tooltip>
      <DeleteConfirmation
        id={props.value as number}
        label={props.data?.title}
        open={open}
        setOpen={setOpen}
        onConfirm={() => {
          deleteComponent(undefined, {
            onSuccess: () => {
              props.api.refreshServerSide({ purge: true })
              setOpen(false)
            }
          })
        }}
      />
      <Tooltip title='Delete'>
        <IconButton disabled={deleteLoading} onClick={() => setOpen(true)}>
          <Icon icon={<Trash />} />
        </IconButton>
      </Tooltip>
    </Stack>
  )
}
