import { useState } from 'react'

import { Checkbox } from '@components/core/checkbox'
import { Input } from '@components/core/input'
import { Label } from '@components/core/label'

import useDelayedUpdate from '@hooks/use-delayed-update'

import { useDispatch, useSelector } from '@store/index'
import { selectColumns, updateColumn } from '@store/slices/component/table-config'

import { ITableColumn } from '../../types/table-builder-types'
import { whenPivotColumnIsValueOrPivot } from '../utils'

export const Broadcast = ({ item: column }: { item: ITableColumn }) => {
  const [enabled, setEnabled] = useState(!!column.customData?.broadcastValue)
  const dispatch = useDispatch()
  const columns = useSelector(selectColumns)

  const disabled = whenPivotColumnIsValueOrPivot(column, columns) || column.hide

  const [broadcastValue, setBroadcastValue] = useDelayedUpdate(
    column.customData?.broadcastValue || '',
    (value) => dispatch(updateColumn({ ...column, customData: { broadcastValue: value } })),
    500
  )

  return (
    <div className='flex flex-col gap-2'>
      <div className='flex items-center gap-2'>
        <Label className='text-submenu font-normal' htmlFor={`broadcast-enabled-${column.field}`}>
          Enable
        </Label>
        <Checkbox
          disabled={disabled}
          id={`broadcast-enabled-${column.field}`}
          checked={enabled}
          onCheckedChange={(value) => {
            setEnabled(value as boolean)
            if (!value) {
              dispatch(updateColumn({ ...column, customData: { broadcastValue: '' } }))
            }
          }}
        />
      </div>
      {enabled && (
        <Input
          placeholder='Parameter Key'
          value={broadcastValue}
          onChange={(e) => setBroadcastValue(e.target.value)}
        />
      )}
    </div>
  )
}
