import { useQuery } from '@tanstack/react-query'

import { FinancialHierarchyGlAccountNode } from 'components/financial/types'
import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'

const fetchGlAccountsInHierarchy = async (
  businessId: number | undefined,
  hierarchyId: number
): Promise<FinancialHierarchyGlAccountNode[]> => {
  const response = await axiosServices.get(
    `/businesses/${businessId}/general_ledger_account_hierarchies/${hierarchyId}/gl_accounts`,
    { params: { with_transaction_count: true } }
  )
  return response.data
}

export const useFetchGlAccountsInHierarchy = (hierarchyId: number) => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-gl-accounts-in-hierarchy', user?.business_id, hierarchyId],
    queryFn: () => fetchGlAccountsInHierarchy(user?.business_id, hierarchyId),
    staleTime: 0,
    enabled: !!hierarchyId
  })
}
