import { useEffect } from 'react'

import { useLatestRef } from '@hooks/useLatestRef'

import getConsumer from './consumer'

export const useWebSocketSubscription = <T>({
  channelName,
  subscriptionParams = {},
  onReceived
}: {
  channelName: string
  subscriptionParams?: Record<string, any>
  onReceived: (data: T) => void
}) => {
  const consumer = getConsumer()
  const onReceivedRef = useLatestRef(onReceived)

  useEffect(() => {
    const channel = consumer.subscriptions.create(
      {
        channel: channelName,
        ...subscriptionParams
      },
      {
        received: (data: T) => {
          console.log(`Received data from channel ${channelName}:`, data)
          onReceivedRef.current(data)
        },
        connected: () => {
          console.log(`Connected to channel: ${channelName}`)
        },
        disconnected: () => {
          console.log(`Disconnected from channel: ${channelName}`)
        },
        rejected: () => {
          console.error(`Subscription to channel ${channelName} was rejected.`)
        }
      }
    )

    return () => {
      channel.unsubscribe()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps -- DO NOT add onReceivedRef to deps
  }, [channelName, consumer, subscriptionParams])
}
