import { useQueries, useQuery } from '@tanstack/react-query'

import { Dimensions } from 'components/control-panel/types'
import useAuth from 'hooks/useAuth'
import axiosServices from 'utils/axios'

import { QUERY_STALE_TIME_DEFAULT } from './constants'
import { fetchSubsidiaries } from './fetch-subsidiaries'

const fetchDimensions = async (businessId: number | undefined): Promise<Dimensions> => {
  const response = await axiosServices.get(`/businesses/${businessId}/dimensions`)
  return response.data
}

export const useFetchDimensions = () => {
  const { user } = useAuth()

  return useQuery({
    queryKey: ['fetch-dimensions', user?.business_id],
    queryFn: () => fetchDimensions(user?.business_id),
    staleTime: QUERY_STALE_TIME_DEFAULT
  })
}

export const useFetchDimensionsParallel = () => {
  const { user } = useAuth()

  return useQueries({
    queries: [
      {
        queryKey: ['fetch-dimensions-parallel', user?.business_id],
        queryFn: () => fetchDimensions(user?.business_id),
        staleTime: QUERY_STALE_TIME_DEFAULT,
        enabled: !!user?.business_id
      },
      {
        queryKey: ['fetch-subsidiaries-parallel', user?.business_id],
        queryFn: () => fetchSubsidiaries(user?.business_id),
        enabled: !!user?.business_id,
        staleTime: QUERY_STALE_TIME_DEFAULT
      }
    ]
  })
}
