import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import {
  ColDef,
  GetDataPath,
  IRowNode,
  RowSelectedEvent,
  SelectionChangedEvent
} from 'ag-grid-community'

import { useDebounce } from '@uidotdev/usehooks'

import AgGrid from '@components/ag-grid'
import { Input } from '@components/core/input'
import { Separator } from '@components/core/separator'
import { Text } from '@components/core/text'

import { Subsidiary } from 'queries/fetch-subsidiaries'

import { useDimensionDataSource } from './dimension-data-source'

import './opened-dimension-view.css'

interface OpenedDimensionViewProps {
  dimensionKey: string
  filterValue: Record<string, string>
  header: string
  selectedValues: string[]
  onSelectedValuesChange: (selectedIds: string[]) => void
  subsidiaries: Subsidiary[]
}

export const OpenedDimensionView = ({
  dimensionKey,
  filterValue,
  header,
  selectedValues,
  onSelectedValuesChange,
  subsidiaries
}: OpenedDimensionViewProps) => {
  const gridApiRef = useRef<any>(null)

  const shouldBeServerSide = useMemo(() => {
    return dimensionKey !== 'subsidiaries' && Object.keys(filterValue).length > 10_000
  }, [filterValue, dimensionKey])

  const isSubsidiaries = useMemo(() => dimensionKey === 'subsidiaries', [dimensionKey])

  const visibleSubsidiaries = useMemo(() => {
    return subsidiaries.filter((sub) => sub.is_visible)
  }, [subsidiaries])

  const [searchText, setSearchText] = useState('')
  const [quickFilterText, setQuickFilterText] = useState('')
  const debouncedSearchText = useDebounce(searchText, 300)

  const [isAltPressed, setIsAltPressed] = useState(false)

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Alt') {
        setIsAltPressed(true)
      }
    }

    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === 'Alt') {
        setIsAltPressed(false)
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    window.addEventListener('keyup', handleKeyUp)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
      window.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  const rowData = useMemo(() => {
    if (dimensionKey === 'subsidiaries') {
      return Object.entries(filterValue)
        .map(([id, name]) => {
          const subsidiary = visibleSubsidiaries.find((sub) => id === sub.name_id?.toString())
          return {
            id,
            name,
            path: subsidiary?.path ?? '',
            is_visible: subsidiary?.is_visible
          }
        })
        .filter((sub) => sub.is_visible)
    }

    return Object.entries(filterValue).map(([id, name]) => ({
      id,
      name
    }))
  }, [filterValue, dimensionKey, visibleSubsidiaries])

  const columnDefs = useMemo<ColDef[]>(
    () => [
      isSubsidiaries
        ? {
            field: 'name',
            headerName: '',
            flex: 1,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            cellRenderer: 'agGroupCellRenderer',
            sortable: true,
            resizable: false,
            suppressMenu: true,
            cellRendererParams: {
              suppressCount: true
            }
          }
        : {
            field: 'name',
            headerName: '',
            flex: 1,
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            sortable: true,
            resizable: false,
            suppressMenu: true
          }
    ],
    [isSubsidiaries]
  )

  useEffect(() => {
    if (!gridApiRef?.current?.api) return

    updateSelection(gridApiRef.current.api)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dimensionKey, shouldBeServerSide])

  const updateSelection = useCallback(
    (params: any) => {
      if (!params.api) return

      if (shouldBeServerSide) {
        const selectAll = selectedValues.length === Object.keys(filterValue).length
        params.api.setServerSideSelectionState({
          selectAll,
          toggledNodes: selectAll ? [] : [...selectedValues]
        })
        return
      }

      selectedValues.forEach((value) => {
        const node = params.api?.getRowNode(value)
        if (node) {
          node.setSelected(true, false)
        }
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldBeServerSide]
  )
  const onSelectionChangedClientSide = useCallback(
    (params: SelectionChangedEvent) => {
      const selectedRows = params.api.getSelectedRows()
      const selectedIds = selectedRows.map((row: any) => row.id)
      onSelectedValuesChange(selectedIds)
    },
    [onSelectedValuesChange]
  )

  const onSelectionChangedServerSide = useCallback(
    (params: any) => {
      const { selectAll, toggledNodes } = params.api.getServerSideSelectionState()

      let selectedIds
      if (selectAll) {
        // If selectAll is true, start with all values from filterValue
        const allIds = Object.keys(filterValue)
        // Remove any toggled nodes as they represent deselections
        selectedIds = allIds.filter((id) => !toggledNodes.includes(id))
      } else {
        // If selectAll is false, only include explicitly toggled nodes
        selectedIds = toggledNodes
      }
      onSelectedValuesChange(selectedIds)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSelectedValuesChange]
  )

  useEffect(() => {
    setQuickFilterText(debouncedSearchText)
  }, [debouncedSearchText])

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }

  const [hasAutoFitRun, setHasAutoFitRun] = useState(false)

  const dataSource = useDimensionDataSource({
    dimensionKey,
    gridRef: gridApiRef,
    hasAutoFitRun,
    setHasAutoFitRun,
    selectedValues,
    shouldBeServerSide,
    quickFilterText
  })

  const getDataPath = useCallback<GetDataPath>((data) => {
    if (!data.path) {
      return [data?.name?.toLowerCase().replace(/ /g, '_')]
    }

    return data?.path?.split('.') || []
  }, [])

  const gridKey = useMemo(() => {
    return `${shouldBeServerSide ? 'server' : 'client'}-${dimensionKey}`
  }, [shouldBeServerSide, dimensionKey])

  return (
    <div className='flex size-full flex-col border-l'>
      <div className='flex flex-col border-b p-3'>
        <Text variant='h5' className='font-medium'>
          {header}
        </Text>
        <Separator className='my-2' />
        <Input
          type='text'
          placeholder='Search'
          className='w-full'
          value={searchText}
          onChange={handleSearchChange}
        />
      </div>
      {/* eslint-disable-next-line tailwindcss/no-custom-classname */}
      <div className='ag-dimensions-view-table h-full flex-1 px-3'>
        <AgGrid
          key={gridKey}
          className='h-full border-none'
          containerStyle={{ border: 'none' }}
          rowModelType={shouldBeServerSide ? 'serverSide' : 'clientSide'}
          ref={gridApiRef}
          serverSideDatasource={shouldBeServerSide ? dataSource : undefined}
          columnDefs={columnDefs}
          rowData={!shouldBeServerSide ? rowData : undefined}
          rowSelection='multiple'
          suppressRowClickSelection={false}
          rowMultiSelectWithClick={true}
          quickFilterText={!shouldBeServerSide ? quickFilterText : undefined}
          onFirstDataRendered={updateSelection}
          onGridReady={updateSelection}
          onSelectionChanged={
            shouldBeServerSide ? onSelectionChangedServerSide : onSelectionChangedClientSide
          }
          onRowSelected={
            !isSubsidiaries
              ? undefined
              : (param: RowSelectedEvent<any, any>) => {
                  if (!isAltPressed && param.node?.childrenAfterFilter) {
                    param.node.childrenAfterFilter?.forEach((childNode: IRowNode) => {
                      childNode.setSelected(param.node?.isSelected() ?? false, false)
                    })
                  }
                }
          }
          autoSizeStrategy={{ type: 'fitGridWidth' }}
          suppressRowHoverHighlight
          suppressCellFocus
          enableCellTextSelection={false}
          cacheBlockSize={100}
          maxBlocksInCache={10}
          serverSideInitialRowCount={500}
          getRowId={(params) => params.data.id}
          groupDisplayType={'custom'}
          getDataPath={isSubsidiaries ? getDataPath : undefined}
          treeData={isSubsidiaries}
          groupDefaultExpanded={-1}
        />
      </div>
    </div>
  )
}
