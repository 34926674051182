import React from 'react'

import { UniqueIdentifier } from '@dnd-kit/core'

import Tooltip from '@core/tooltip'

import { Badge } from '@components/core/badge'
import { CollectionIcon } from '@components/icons/page/collection-icon'
import { DynamicPageIcon } from '@components/icons/page/dynamic-page-icon'
import { PageGroupIcon } from '@components/icons/page/page-group-icon'
import { StaticPageIcon } from '@components/icons/page/static-page-icon'
import LogoIcon from '@components/logo/LogoIcon'
import { SortableTree } from '@components/tree/sortable-tree'
import {
  TreeItem,
  TreeItem as TreeItemType,
  TreeSource,
  TreeSourceEnum
} from '@components/tree/types'

import useAuth from '@hooks/useAuth'

import {
  PAGE_DISABLED_COLOR,
  PAGE_DRAFT_COLOR,
  PAGE_PUBLISHED_COLOR,
  isCollection,
  isDynamicPage,
  isInternalPage,
  isPageGroup,
  isStaticPage
} from 'types/page'

import { useCreatePage } from '../../queries/create-page'
import { useDeletePage } from '../../queries/delete-page'
import { useReorderPage } from '../../queries/reorder-page'
import { useUpdatePage } from '../../queries/update-page'
import { isLayoutStatusDraft, isLayoutStatusPublished } from '../../types'

export const PagesTreePanel = ({
  isEditMode,
  pages,
  selectedPageId,
  showSearchInput,
  onPageClick
}: {
  isEditMode: boolean
  pages: any[]
  selectedPageId: number
  showSearchInput?: boolean
  onPageClick: (id: UniqueIdentifier) => void
}) => {
  const { mutate: createPage } = useCreatePage()
  const { mutate: updatePage } = useUpdatePage()
  const { mutate: reorder } = useReorderPage()
  const { mutate: deletePage } = useDeletePage()

  const { user } = useAuth()

  return (
    <SortableTree
      collapsible
      indicator
      source={TreeSourceEnum.Page}
      sortable={isEditMode}
      editable={isEditMode}
      defaultItems={pages}
      onItemClick={onPageClick}
      showSearchInput={showSearchInput}
      persistedCollapsedIdsKey={`pages-hierarchy-${user?.business_id}`}
      onNodeEdit={(nodeId: number, item: TreeItem) =>
        updatePage({ pageId: nodeId, title: item.label })
      }
      treeItemClasses={(item: TreeItemType) => {
        if (!item?.enabled) {
          return PAGE_DISABLED_COLOR
        }

        if (isLayoutStatusPublished(item?.status)) {
          return PAGE_PUBLISHED_COLOR
        }

        if (isLayoutStatusDraft(item?.status)) {
          return PAGE_DRAFT_COLOR
        }

        return ''
      }}
      onNodeCreate={({ parentNodeId, item }: { parentNodeId?: number; item: TreeItem }) =>
        createPage({ parentPageId: parentNodeId, title: item.label, type: item.identifier })
      }
      onNodeDelete={(nodeId: number) => {
        deletePage({ pageNodeId: nodeId })
      }}
      onDragEnd={({
        parentNodeId,
        sortedNodeIds
      }: {
        parentNodeId?: number
        sortedNodeIds: number[]
      }) => reorder({ parentPageId: parentNodeId, sortedNodeIds: sortedNodeIds })}
      selectedNodeId={selectedPageId}
      treeItemSecondaryUtils={(item: TreeItemType, source: TreeSource | undefined) => {
        return (
          <>
            {isLayoutStatusDraft(item?.status) && (
              <div className='mr-2 flex items-center justify-center'>
                <Badge variant='outline' className='font-normal text-primary'>
                  draft
                </Badge>
              </div>
            )}

            {isInternalPage(item.identifier) && (
              <Badge variant='outline' className='font-normal'>
                Internal
              </Badge>
            )}

            {(item?.parent_page_id || user?.business_friendly_name === 'charger') && (
              <TooltipIcon title='Page is maintained by Charger' IconComponent={LogoIcon} />
            )}

            {isStaticPage(item.identifier) && (
              <TooltipIcon title='Static Page' IconComponent={StaticPageIcon} />
            )}
            {isDynamicPage(item.identifier) && (
              <TooltipIcon title='Layout Builder Page' IconComponent={DynamicPageIcon} />
            )}
            {isPageGroup(item.identifier) && (
              <TooltipIcon title='Page Group' IconComponent={PageGroupIcon} />
            )}
            {isCollection(item.identifier) && (
              <TooltipIcon title='Collection' IconComponent={CollectionIcon} />
            )}
          </>
        )
      }}
    />
  )
}

const TooltipIcon = ({
  title,
  IconComponent
}: {
  title: string
  IconComponent: React.ElementType
}) => (
  <div className='mr-2 flex items-center justify-center'>
    <Tooltip title={title}>
      <div className='flex items-center justify-center'>
        <IconComponent color={'black'} fontSize={'0.75rem'} />
      </div>
    </Tooltip>
  </div>
)
