import { ExecutionStage } from '@components/directed-graph'

export const enum OpsCopilotWorkflow {
  KPI_ASSIGNMENT = 'kpi_assignment',
  TB_UPLOAD = 'tb_upload',
  COA_CREATE = 'coa_create',
  AUTO_MAP_GL_ACCOUNTS = 'auto_map_gl_accounts'
}

export enum OpsCopilotNodeStage {
  START = 'start',
  COMPLETE = 'complete',
  ERROR = 'error'
}

export enum OpsCopilotExecutionStatus {
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  FAILED = 'failed'
}

export interface OpsCopilotGraphNode {
  id: string | number
  name: string
  stage: ExecutionStage
  state?: Record<string, any>
  output?: Record<string, any>
  error?: Record<string, any>
}

export interface OpsCopilotGraphEdge {
  source: string | number
  target: string | number
  isConditional: boolean
}

export interface OpsCopilotExecution {
  id: number
  workflow: OpsCopilotWorkflow
  status: OpsCopilotExecutionStatus
  metadata: Record<string, any>
  createdAt: string
  updatedAt: string
  nodes: Array<OpsCopilotGraphNode>
  edges: Array<OpsCopilotGraphEdge>
}
