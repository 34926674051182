import { useToggle } from '@uidotdev/usehooks'

import { Label } from '@components/core/label'
import { Separator } from '@components/core/separator'
import { Switch } from '@components/core/switch'

import { useDispatch, useSelector } from '@store/index'
import { selectPaginated } from '@store/slices/component/query-config'
import {
  replaceColumns,
  selectColumns,
  selectServersideTransformation,
  toggleServersideTransformation
} from '@store/slices/component/table-config'

import { Description } from '../common/description'
import { GenreDropdownWithLabel } from '../common/genre-dropdown-with-label'
import { Title } from '../common/title'
import { ColumnOrganization } from './column-organization'
import { GrandTotals } from './grand-totals'

export const TableDataSettings = () => {
  const columns = useSelector(selectColumns)
  const [showHidden, toggleShowHidden] = useToggle(true)
  const paginated = useSelector(selectPaginated)
  const showServersideTransformation = useSelector(selectServersideTransformation)
  const dispatch = useDispatch()

  return (
    <div>
      <GenreDropdownWithLabel />
      <Separator className='my-2' />

      <div className='flex gap-2'>
        <div className='flex-1'>
          <Title>Column Data</Title>
          <Description>Adjust which fields are visible and how they are organized.</Description>
        </div>
        <div className='flex items-center gap-2'>
          <Label className='text-grey-dark'>Show hidden Fields</Label>
          <Switch checked={showHidden} onCheckedChange={() => toggleShowHidden()} />
        </div>
      </div>

      <div className='my-2 flex gap-2'>
        <Label className='text-grey-dark'>Serverside Transformation</Label>
        <Switch
          checked={showServersideTransformation}
          onCheckedChange={() => dispatch(toggleServersideTransformation())}
        />
      </div>

      <Separator className='my-2' />

      <ColumnOrganization
        columns={columns}
        onChangeColumns={(columns) => dispatch(replaceColumns(columns))}
        showHidden={showHidden}
        paginated={paginated}
      />

      <Separator className='my-2' />

      <GrandTotals />
    </div>
  )
}
