import { forwardRef } from 'react'
import * as React from 'react'

import { cn } from '@utils/style-utils'

import { Input, InputSize } from '@core/input'

import { Icon } from '@components/icons'
import { Cancel } from '@components/icons/cancel'
import { Search } from '@components/icons/search'

interface SearchInputProps {
  value: string
  onChange: (value: string) => void
  clearable?: boolean
  className?: string
  fullWidth?: boolean
  inputSize?: InputSize
  leadingIcon?: boolean
}

export const SearchInput = forwardRef<HTMLInputElement, SearchInputProps>(
  (
    {
      value,
      onChange,
      clearable = false,
      className = '',
      fullWidth = false,
      inputSize = 'default',
      leadingIcon = true
    },
    ref
  ) => {
    return (
      <Input
        ref={ref}
        inputSize={inputSize}
        layout={fullWidth ? 'fullWidth' : 'inline'}
        type='text'
        placeholder='Search'
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={cn(
          fullWidth ? 'w-full' : 'w-56',
          'rounded border border-gray-light p-2 text-button font-light',
          className
        )}
        leadingIcon={
          leadingIcon ? (
            <Icon
              icon={<Search />}
              className='opacity-50'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          ) : null
        }
        trailingIcon={
          clearable ? (
            <Icon
              icon={<Cancel />}
              onClick={() => onChange('')}
              className='mt-1 size-6 cursor-pointer opacity-50 hover:opacity-70'
              strokeWidth='2'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          ) : null
        }
      />
    )
  }
)
