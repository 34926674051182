import { createConsumer } from '@rails/actioncable'

let actionCableConsumer: any = null

// TODO: Should we grab current_business_id from local storage and validate in the backend like the user token?
export default function getConsumer() {
  if (!actionCableConsumer) {
    const token = localStorage.getItem('ServiceToken')
    const socket_url = `${import.meta.env.VITE_SOCKET_URL}/cable?token=${token}`
    actionCableConsumer = createConsumer(socket_url)
  }

  return actionCableConsumer
}
