import { useMemo, useState } from 'react'

import { Active, DndContext, UniqueIdentifier, pointerWithin } from '@dnd-kit/core'

import { capitalize } from '@utils/string-utils'

import SkeletonTable from '@components/ag-grid/skeleton-table'
import { EnumTabToggle } from '@components/tab/enum-tab-toggle'
import { findItemDeep, isDragEventType } from '@components/tree/utilities'

import { ModeDetailsPanel } from '@pages/admin/layouts/components/manager/mode-details-panel'
import { useFetchUnassignedPages } from '@pages/admin/layouts/queries/fetch-unassigned-pages'
import { useReorderPage } from '@pages/admin/layouts/queries/reorder-page'
import { LayoutBuilderDetailsModes, isPageUnassignedMode } from '@pages/admin/layouts/types'

import { clsx } from 'clsx'
import { PAGE_DISABLED_COLOR, PAGE_DRAFT_COLOR, PAGE_PUBLISHED_COLOR, Page } from 'types/page'

import MainCard from '../../../components/MainCard'
import useDefaultSensors from '../../../components/tree/useDefaultSensors'
import { LayoutViewProps } from '../../layout-view/types'
import { PagesTreePanel } from './components/manager/pages-tree-panel'
import { useFetchPagesTree } from './queries/fetch-pages-tree'

const LayoutManager = ({ pageSettings }: LayoutViewProps) => {
  const { isLoading: fetchingPages, data: pages } = useFetchPagesTree()
  const { data: unassignedPages } = useFetchUnassignedPages()

  const [selectedPageId, setSelectedPageId] = useState<UniqueIdentifier>()
  const [active, setActive] = useState<Active | null>(null)
  const sensors = useDefaultSensors()
  const { mutate: reorder } = useReorderPage()

  const [mode, setMode] = useState<LayoutBuilderDetailsModes>(LayoutBuilderDetailsModes.PAGE_DETAIL)

  const selectedNode: any | undefined = useMemo(() => {
    if (!pages) return undefined

    return findItemDeep(pages, selectedPageId as UniqueIdentifier)
  }, [pages, selectedPageId])

  return (
    <MainCard
      pageSettings={pageSettings}
      content={true}
      title={' '}
      secondary={
        <div className='flex flex-row space-x-4'>
          <StatusIndicator label='Published' bgColor={PAGE_PUBLISHED_COLOR} />
          <StatusIndicator label='Draft' bgColor={PAGE_DRAFT_COLOR} />
          <StatusIndicator label='Disabled' bgColor={PAGE_DISABLED_COLOR} />
        </div>
      }
    >
      <DndContext
        collisionDetection={active?.data?.current?.collisionDetection || pointerWithin}
        sensors={active?.data?.current?.sensors || sensors}
        measuring={active?.data?.current?.measuring}
        accessibility={{ announcements: active?.data?.current?.announcements ?? undefined }}
        onDragStart={(event) => {
          setActive(event?.active ?? null)
          console.log('Drag Start on Working Hierarchy...', event)
        }}
        onDragEnd={(event) => {
          setActive(null)
          const overId = event.over?.id ? Number(event.over.id) : null
          if (!overId || isDragEventType(event, 'sort')) return

          const movingItems = event.active.data.current?.movingItems // This type is added manually

          if (movingItems) {
            const movingItemIds = movingItems.map((item: Page) => item.id)
            reorder({ parentPageId: overId, sortedNodeIds: movingItemIds })
          }
        }}
      >
        <div className='flex h-[calc(100vh-100px)] gap-2 py-2'>
          {/* Left Panel */}
          <div className='w-2/5 overflow-auto rounded-md py-1'>
            {fetchingPages ? (
              <div className='flex h-96'>
                <div className='relative h-full flex-1'>
                  <SkeletonTable />
                </div>
              </div>
            ) : (
              <PagesTreePanel
                showSearchInput={true}
                isEditMode={true}
                pages={pages || []}
                onPageClick={(id: UniqueIdentifier) => {
                  if (id === selectedPageId) {
                    setSelectedPageId(undefined)
                  } else {
                    setSelectedPageId(id)
                  }
                }}
                selectedPageId={selectedPageId as number}
              />
            )}
          </div>

          {/* Right Panel */}
          <div className='w-3/5 overflow-auto pl-4'>
            <EnumTabToggle
              id='mode-toggle'
              enumObject={LayoutBuilderDetailsModes}
              value={mode}
              onChange={(event) => {
                setMode(event as LayoutBuilderDetailsModes)
              }}
              renderLabel={(value, label) => {
                const text = capitalize(label.toLowerCase())
                return isPageUnassignedMode(value)
                  ? `${text} (${unassignedPages ? unassignedPages.length : 0})`
                  : text
              }}
            />

            <ModeDetailsPanel
              selectedPageId={selectedPageId}
              selectedNode={selectedNode}
              mode={mode}
            />
          </div>
        </div>
      </DndContext>
    </MainCard>
  )
}

const StatusIndicator = ({ label, bgColor }: { label: string; bgColor: string }) => (
  <div className='flex items-center'>
    <div className={clsx('size-5 border border-solid border-grey-light', bgColor)}></div>
    <div className='ml-2'>
      <span>{label}</span>
    </div>
  </div>
)

export default LayoutManager
