import { useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router'

import { usePageSelector, useSelector } from '@store/index'
import { selectGenre } from '@store/slices/component/basic-config'
import { selectParameterizedFilters } from '@store/slices/component/parameterized-filters'
import {
  selectFilters,
  selectFormulas,
  selectHiddenFilters,
  selectLimit,
  selectPaginated,
  selectParameterizedConfig,
  selectQueryData,
  selectSorts
} from '@store/slices/component/query-config'
import { selectFilterModel, selectSortModel } from '@store/slices/component/table-parameters'
import { selectExpandedDateFilterInfo } from '@store/slices/date-time-filter'
import { selectDimensions } from '@store/slices/dimension'

import { useActiveDrilldownDimensions } from 'contexts/drilldown-context'
import { useLatestRef } from 'hooks/useLatestRef'

import {
  StagedComponentBody,
  useFetchComponentStagedData
} from '../queries/fetch-component-staged-data'
import { IDataType, IDrilldowns, IHeader, IMetadata } from '../types/component-types'
import { IResultRow } from '../types/shared-types'
import useActiveConfig from './useActiveConfig'
import useServersideTransformKey from './useServersideTransformKey'

export default function useResultData({
  applyTransformations = false
}: { applyTransformations?: boolean } = {}) {
  const componentId = useParams<{ id: string }>().id as string
  const dateFilter = usePageSelector(selectExpandedDateFilterInfo)
  const location = useLocation()
  const limit = useSelector(selectLimit)
  const paginated = useSelector(selectPaginated)
  const query = useSelector(selectQueryData())
  const filters = useSelector(selectFilters)
  const sorts = useSelector(selectSorts)
  const parameterizedConfig = useSelector(selectParameterizedConfig)
  const hiddenFilters = useSelector(selectHiddenFilters)
  const dimensionFilters = usePageSelector(selectDimensions)
  const parameterizedFilters = usePageSelector(selectParameterizedFilters)
  const formulas = useSelector(selectFormulas)
  const genre = useSelector(selectGenre)
  const genreRef = useLatestRef(genre)
  const configRef = useActiveConfig()
  const activeDrilldownDimensions = useActiveDrilldownDimensions()
  const serversideTransformKey = useServersideTransformKey({ applyTransformations })
  const filterModel = useSelector(selectFilterModel(componentId))
  const sortModel = useSelector(selectSortModel(componentId))

  const [body, setBody] = useState<StagedComponentBody>()

  useEffect(() => {
    setBody({
      query,
      parameters: _.assign(
        {
          start_date: dateFilter?.startDate,
          end_date: dateFilter?.endDate,
          date_truncation: dateFilter?.frequency,
          active_drilldowns: activeDrilldownDimensions,
          dimension_filters: dimensionFilters,
          parameterized_filters: _.pick(parameterizedFilters, _.keys(parameterizedConfig)),
          offset: 0
        },
        applyTransformations && {
          filter_model: filterModel,
          sort_model: sortModel
        }
      ),
      filters,
      sorts,
      hidden_filters: hiddenFilters,
      limit,
      paginated,
      formulas,
      apply_transformations: applyTransformations,
      parameterized_config: parameterizedConfig,
      genre: genreRef.current,
      config: configRef.current
    })
  }, [
    query,
    dateFilter?.startDate,
    dateFilter?.endDate,
    dateFilter?.frequency,
    filters,
    sorts,
    hiddenFilters,
    limit,
    paginated,
    formulas,
    applyTransformations,
    genreRef,
    configRef,
    activeDrilldownDimensions,
    serversideTransformKey,
    dimensionFilters,
    parameterizedFilters,
    parameterizedConfig,
    filterModel,
    sortModel
  ])

  const enabled = applyTransformations
    ? // should be executed only in builder or visualize page
      ['builder', 'visualize'].some((path) => location.pathname.endsWith(path))
    : // should not be executed in query or data-selection page
      !['query', 'data-selection'].some((path) => location.pathname.endsWith(path))

  const {
    data: result,
    isFetching,
    isPending,
    isSuccess,
    isError,
    error
  } = useFetchComponentStagedData(body, enabled)

  const headers = useMemo(() => _.get(result, 'headers', []) as IHeader[], [result])
  const visibleHeaders = useMemo(() => _.reject(headers, { is_hidden: true }), [headers])
  const dimensions = useMemo(
    () =>
      _.filter(visibleHeaders, (header) =>
        _.includes([IDataType.BOOLEAN, IDataType.NUMBER, IDataType.TEXT], header.data_type)
      ),
    [visibleHeaders]
  ) as IHeader[]
  const measures = useMemo(
    () => _.filter(visibleHeaders, { data_type: IDataType.NUMBER }),
    [visibleHeaders]
  ) as IHeader[]
  const dates = useMemo(
    () => _.filter(visibleHeaders, { data_type: IDataType.DATE_STRING }),
    [visibleHeaders]
  ) as IHeader[]

  const data = useMemo(() => _.get(result, 'results', []), [result]) as IResultRow[]
  const dataRef = useLatestRef(data)
  const resultsCount = useMemo(() => _.get(result, 'results_count', 0), [result])
  const metadata = useMemo(() => _.get(result, 'metadata', {}), [result]) as IMetadata
  const drilldowns = useMemo(() => _.get(result, 'drilldowns', []), [result]) as IDrilldowns

  return {
    componentConfig: body,
    dataRef,
    data,
    resultsCount,
    headers,
    visibleHeaders,
    metadata,
    drilldowns,
    dimensions,
    measures,
    dates,
    isFetching: isFetching || isPending, // isPending is used to show the loading spinner when the query is yet to be sent
    isSuccess,
    isError,
    errorMessage: error?.message || ''
  }
}
