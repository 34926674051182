import React from 'react'

import { Month } from '@utils/date-utils'

import DayPicker from '@components/control-panel/calendar-filter-popover/day-picker'
import MonthlyPicker from '@components/control-panel/calendar-filter-popover/monthly-picker'
import QuarterPicker from '@components/control-panel/calendar-filter-popover/quarter-picker'
import WeeklyPicker from '@components/control-panel/calendar-filter-popover/weekly-picker'
import { YearPicker } from '@components/control-panel/calendar-filter-popover/year-picker'

import { DateInfo, Frequency } from '../../../types/filter'

export const ComponentMap: Record<Frequency, React.FC<any>> = {
  [Frequency.Yearly]: YearPicker,
  [Frequency.Quarterly]: QuarterPicker,
  [Frequency.Monthly]: MonthlyPicker,
  [Frequency.Weekly]: WeeklyPicker,
  [Frequency.Daily]: DayPicker,
  [Frequency.MonthlyQuarterly]: QuarterPicker
}

interface DateComponentProps {
  frequency: Frequency
  fiscalYearStart?: Month
  value?: DateInfo
  onChange: (value: DateInfo) => void
  render?: (value: DateInfo) => React.ReactNode
}

export const DateComponentPicker = (props: DateComponentProps) => {
  const { frequency = Frequency.Monthly, ...rest } = props
  const Component = ComponentMap[frequency]
  return <Component {...rest} />
}
