import React, { useEffect, useState } from 'react'
import ReactJson from 'react-json-view'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Stack
} from '@mui/material'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize
} from '@mui/material'
import IconButton from '@mui/material/IconButton'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core'

import { PageCleanIcon } from '@components/icons/page/page-clean-icon'
import { PageDivergedIcon } from '@components/icons/page/page-diverged-icon'
import LogoIcon from '@components/logo/LogoIcon'

import usePageData from '@hooks/usePageData'

import FilterPanel from 'components/control-panel/filter-panel'
import { useLayoutContext } from 'contexts/LayoutContext'
import { isCollection, isDynamicPage, isPageGroup, isStaticPage } from 'types/page'

import MainCard from '../../../components/MainCard'
import ScrollX from '../../../components/ScrollX'
import { dispatch } from '../../../store'
import { openSnackbar } from '../../../store/reducers/snackbar'
import { shortUniqueID } from '../../../utils/string-utils'
import { LayoutViewProps } from '../../layout-view/types'
import DragPanel from './components/builder/drag-panel'
import FilterInput from './components/builder/filter-input'
import GeneralPagePreview from './components/builder/general-page-preview'
import LayoutSettings from './components/builder/layout-settings'
import FreeStyleSection from './components/container_components/freestyle-section'
import PageSelector from './components/page-selector'
import LayoutViewComp from './components/view/layout-view-comp'
import { COMP, SIDEBAR_ITEM } from './data'
import {
  addWidgetToFreeStyleSection,
  extractLayoutAttributes,
  findHighestValues,
  handleRemoveItemFromFreeStyleRow,
  updateFreeStyleLayoutWidgetPosition
} from './helpers'
import useFetchPage from './queries/fetch-page'
import { useUpdatePage } from './queries/update-page'
import { LayoutStatus, ViewMode } from './types'

import './layout-builder.css'

const generateSlug = (title: string | '') => {
  return title
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-zA-Z0-9-]/g, '')
}

const LayoutBuilder = ({ pageSettings }: LayoutViewProps) => {
  const { id: pageID } = useParams()
  const navigate = useNavigate()
  const [previewMode, setPreviewMode] = useState(false)
  const [currentTab, setCurrentTab] = useState(0)
  const [gridLine, setGridLine] = useState(true)
  const [devMode, setDevMode] = useState(false)
  const sensors = useSensors(useSensor(PointerSensor), useSensor(KeyboardSensor))
  const { layoutData, setLayoutData, layoutStructure, setLayoutStructure, selectedWidget } =
    useLayoutContext()
  const [previewGeneralPage, setPreviewGeneralPage] = useState(false)

  const { isLoading, data } = useFetchPage(pageID as string)
  const { mutate: updatePage } = useUpdatePage()

  const searchFilters = _.get(layoutData, 'settings.search_filters', {})
  const cssClassName = _.get(layoutData, 'settings.className', '')

  useEffect(() => {
    setLayoutData(data)
    setLayoutStructure(data.layout_structure)
    // Note: If you are working with this component, please fix react-hooks/exhaustive-deps warning
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const handleUpdateFilters = (newFilters: any) => {
    try {
      setLayoutData((prevLayoutData: any) => ({
        ...prevLayoutData,
        settings: {
          ...prevLayoutData.settings,
          search_filters: newFilters
        }
      }))
    } catch (error) {
      console.error('Error parsing JSON for search filters:', error)
    }
  }

  const onComponentRemove = (path: string, layout_item: any) => {
    const newLayoutStructure = handleRemoveItemFromFreeStyleRow(
      layoutStructure,
      path,
      layout_item.i
    )
    setLayoutStructure(newLayoutStructure)
  }

  const onLayoutChange = (newLayout: any, layouts: any, props: any) => {
    setLayoutStructure(updateFreeStyleLayoutWidgetPosition(layoutStructure, props, newLayout))
  }

  const handlePreviewToggle = () => setPreviewMode(!previewMode)

  const handleGridLineToggle = (newGridLineState: boolean) => setGridLine(newGridLineState)

  const handleDevModeToggle = (newDevModel: boolean) => setDevMode(newDevModel)

  const handlePublishedModeToggle = (newMode: boolean) => {
    setLayoutData((prevLayoutData: any) => ({
      ...prevLayoutData,
      status: newMode ? LayoutStatus.published : LayoutStatus.draft
    }))
  }

  const handleInputChange = (fieldName: any, value: any) => {
    if (fieldName === 'className') {
      setLayoutData((prevLayoutData: any) => ({
        ...prevLayoutData,
        settings: {
          ...prevLayoutData.settings,
          [fieldName]: value
        }
      }))
    } else if (fieldName === 'title') {
      const slug = generateSlug(value)

      setLayoutData((prevLayoutData: any) => ({
        ...prevLayoutData,
        [fieldName]: value,
        slug: slug,
        page_route: `/${slug}`
      }))
    } else if (fieldName === 'component_path' || fieldName === 'type') {
      setLayoutData((prevLayoutData: any) => ({
        ...prevLayoutData,
        meta_data: {
          ...prevLayoutData.meta_data,
          [fieldName]: value
        }
      }))
    } else if (fieldName === 'display_mode') {
      setLayoutData((prevLayoutData: any) => ({
        ...prevLayoutData,
        settings: {
          ...prevLayoutData.settings,
          [fieldName]: value
        }
      }))
    } else if (fieldName === 'use_new_filters') {
      setLayoutData((prevLayoutData: any) => ({
        ...prevLayoutData,
        settings: {
          ...prevLayoutData.settings,
          search_filters: {}
        },
        meta_data: {
          ...prevLayoutData.meta_data,
          [fieldName]: value
        }
      }))
    } else {
      setLayoutData((prevLayoutData: any) => ({
        ...prevLayoutData,
        [fieldName]: value
      }))
    }
  }

  const handleSaveOrUpdate = () => {
    const {
      custom_css,
      description,
      meta_data,
      permissions,
      settings,
      title,
      status,
      slug,
      page_route,
      enabled,
      sidebar_item,
      layout_type
    } = layoutData
    if (!title) {
      showMessage('error', 'Title is missing.')
      return
    }

    // TODO: this is validating the minimal structure
    // on every update can we validate that the layoutStructure is a valid json??
    if (
      isDynamicPage(layout_type) &&
      (!layoutStructure ||
        layoutStructure.length < 1 ||
        !layoutStructure[0].children ||
        layoutStructure[0].children[0].category !== COMP.COLUMN.CATEGORY)
    ) {
      showMessage('error', 'Invalid Layout.')
      return
    }

    const newLayoutData = {
      custom_css,
      description: description?.trim(),
      layout_structure: layoutStructure,
      meta_data,
      permissions,
      settings,
      status,
      slug,
      page_route,
      enabled,
      sidebar_item,
      layout_type
    }

    updatePage({ pageId: Number(pageID), title: title.trim(), properties: { ...newLayoutData } })
  }

  const onSubmit = (data: any) => console.log('Form Data:', data)

  const handleDragStart = (event: any) => {}

  const handleDragEnd = (event: any) => {
    if (!event.active.data || !event.over || !event.over.data) {
      return
    }

    const source = event.active.data.current // { category: 'widget', id: 'widget-lgpDjEMuaZ', data: {…}, path: '0-0-1' }
    const destination = event.over.data.current // { sectionIndex: undefined, columnIndex: undefined, widgetIndex: undefined, widgetId: 'widget-l4AAdWmISY', path: undefined}

    const newItem = { id: source.id, category: source.category, children: {} }
    if (source.category === COMP.COLUMN.CATEGORY) {
      newItem.children = source.children
    }

    // If Component from Sidebar
    if (source.category === SIDEBAR_ITEM) {
      onNewSideBarComponentDrop(destination.name, source.component)

      return
    }
  }

  const onNewSideBarComponentDrop = (destination_name: string, component: any) => {
    // {id: 'sidebar-comp-image', category: 'widget', name: 'image', title: 'Image', imgSrc: '/static/media/grid-1-cols.1894ec4f1ad8d1f3a5f5.png'}
    const newComponent = {
      ...component, // Copy all properties from the existing component
      id: component.id.startsWith('widget-') ? component.id : shortUniqueID('widget-')
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { imgSrc, ...finalComponent } = newComponent
    if (destination_name === COMP.ROW.SPECIFIC.FREE_STYLE_ROW.NAME) {
      const splitDropZonePath = ['0'] // We are assuming we only have one free style section
      const layoutData = extractLayoutAttributes(layoutStructure[0])

      const highestX = findHighestValues(layoutData, 'x')
      const highestY = findHighestValues(layoutData, 'y')

      finalComponent.component_attributes = {
        ...finalComponent.component_attributes,
        layoutAttributes: {
          x: highestX < 10 ? highestX : 0,
          y: highestY + 2,
          h: finalComponent.static ? 2 : 4,
          w: finalComponent.static ? 12 : 4, // Note: Static means a divider, so set it to full width
          i: finalComponent.id,
          static: finalComponent.static ? finalComponent.static : false
        }
      }

      setLayoutStructure(
        addWidgetToFreeStyleSection(layoutStructure, splitDropZonePath, finalComponent)
      )
    }
  }

  const onComponentsManuallySelected = (components: any[]) => {
    components.forEach((component: any) => {
      onNewSideBarComponentDrop(COMP.ROW.SPECIFIC.FREE_STYLE_ROW.NAME, component)
    })
  }

  const handleTabChange = (event: any, newValue: any) => {
    setCurrentTab(newValue)
  }

  const handleJsonEditorChange = (newJson: any) => {
    try {
      const parsedJson = JSON.parse(newJson)
      setLayoutStructure(parsedJson)
    } catch (error) {
      console.error('Error parsing JSON:', error)
    }
  }

  const showMessage = (type: string, message: string): void => {
    dispatch(
      openSnackbar({
        open: true,
        message: message,
        variant: 'alert',
        alert: {
          color: type
        },
        close: true
      })
    )
  }

  const onPreviewGeneralPageClick = () => {
    setPreviewGeneralPage(true)
  }

  const onCancelGeneralPageClick = () => {
    setPreviewGeneralPage(false)
  }

  if (isLoading) {
    return <CircularProgress />
  }

  const onLayoutBuilderBackClick = () => {
    navigate(-1)
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { layout_structure } = usePageData('', layoutStructure)

  return (
    <>
      <MainCard
        pageSettings={pageSettings}
        content={false}
        title={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <IconButton style={{ marginRight: '5px' }} onClick={onLayoutBuilderBackClick}>
              <ArrowBackIcon />
            </IconButton>
            {/*<span>{pageID ? `Layout Builder: ${layoutData.title}` : 'Layout Builder'}</span>*/}
            {pageID && layoutData?.parent_page_id && (
              <Tooltip title='Page is owned and maintained by Charger'>
                <Stack alignItems='center' justifyContent='center' mr={1}>
                  <LogoIcon style={{ marginLeft: '20px', fontSize: '1.35rem' }} />
                </Stack>
                {/*<Chip*/}
                {/*  label={layoutData?.parent_page_id ? 'General Page' : 'Custom Page'}*/}
                {/*  color={layoutData?.parent_page_id ? 'primary' : 'secondary'}*/}
                {/*  size="small"*/}
                {/*  variant="outlined"*/}
                {/*  style={{ borderRadius: '12px', marginLeft: '8px' }}*/}
                {/*/>*/}
              </Tooltip>
            )}
          </div>
        }
        secondaryAlign='right'
        secondary={
          <>
            <LayoutSettings
              layout_type={layoutData?.layout_type}
              gridLine={gridLine}
              onGridLineToggle={handleGridLineToggle}
              devMode={devMode}
              published={layoutData.status === 'published'}
              onPublishedToggle={handlePublishedModeToggle}
              onDevModeToggle={handleDevModeToggle}
              onSaveOrUpdate={handleSaveOrUpdate}
              previewMode={previewMode}
              onPreviewToggle={handlePreviewToggle}
              searchFilters={searchFilters}
            />
          </>
        }
      >
        {previewMode ? (
          <LayoutViewComp layoutStructure={layout_structure} />
        ) : (
          <ScrollX>
            <Grid container style={{ display: 'flex' }}>
              <React.Fragment>
                <DndContext
                  sensors={sensors}
                  onDragStart={handleDragStart}
                  onDragEnd={handleDragEnd}
                  autoScroll={true}
                >
                  <Grid
                    item
                    xs={isDynamicPage(layoutData?.layout_type) ? 10 : 12}
                    style={{ flex: 1 }}
                  >
                    <Stack
                      direction='row'
                      justifyContent='flex-end'
                      alignItems='center'
                      spacing={2}
                    >
                      <Tabs value={currentTab} onChange={handleTabChange} centered>
                        {isDynamicPage(layoutData?.layout_type) && <Tab label='Layout Builder' />}
                        {isDynamicPage(layoutData?.layout_type) && <Tab label='JSON Editor' />}
                        <Tab label='Page Attributes' />
                      </Tabs>
                    </Stack>

                    {isDynamicPage(layoutData?.layout_type) && currentTab === 0 && (
                      <Paper
                        elevation={3}
                        style={{ minHeight: '400px', padding: '20px', marginBottom: '400px' }}
                      >
                        {previewGeneralPage ? (
                          <GeneralPagePreview
                            parent_page_id={layoutData.parent_page_id}
                            onCancelGeneralPageClick={onCancelGeneralPageClick}
                            devMode={devMode}
                            gridLine={gridLine}
                            onComponentRemove={onComponentRemove}
                            onComponentsManuallySelected={onComponentsManuallySelected}
                            onLayoutChange={onLayoutChange}
                            layoutStructure={layoutStructure}
                          />
                        ) : (
                          <div style={{ flex: 1 }}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}
                            >
                              <h3>Stage</h3>

                              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                {layoutData?.parent_page_id ? (
                                  <>
                                    {layoutData?.is_clean ? (
                                      ''
                                    ) : (
                                      <>
                                        <Button
                                          onClick={onPreviewGeneralPageClick}
                                          variant='contained'
                                          color='secondary'
                                        >
                                          Preview General Layout
                                        </Button>
                                      </>
                                    )}
                                    <Tooltip
                                      title={
                                        layoutData?.is_clean
                                          ? 'You are all clear'
                                          : "Your Page has diverged from the General layout structure. Click 'Preview General Layout' to see what has changed."
                                      }
                                    >
                                      <Stack alignItems='center' justifyContent='center' mr={1}>
                                        {layoutData?.is_clean ? (
                                          <PageCleanIcon />
                                        ) : (
                                          <PageDivergedIcon />
                                        )}
                                      </Stack>
                                      {/*<Chip*/}
                                      {/*  label={'Layout Status: ' + (layoutData?.is_clean ? 'Clean' : 'Diverged')}*/}
                                      {/*  color={'primary'}*/}
                                      {/*  size="small"*/}
                                      {/*  variant="outlined"*/}
                                      {/*  style={{ borderRadius: '12px', marginLeft: '8px' }}*/}
                                      {/*/>*/}
                                    </Tooltip>
                                  </>
                                ) : (
                                  ''
                                )}
                              </div>
                            </div>

                            <Stack
                              direction='row'
                              justifyContent='flex-end'
                              alignItems='center'
                              spacing={2}
                            >
                              <FilterPanel searchFilters={searchFilters} />
                            </Stack>

                            <div className='col-config-container'>
                              {devMode && (
                                <label className='dev-info-text'>DropContainer | Droppable</label>
                              )}
                              <div className='drop-container'>
                                {layoutStructure.map((row: any, index: any) => {
                                  const currentPath = `${index}`

                                  return (
                                    <React.Fragment key={row.id}>
                                      <FreeStyleSection
                                        mode={ViewMode.edit}
                                        gridLine={gridLine}
                                        devMode={devMode}
                                        // onComponentSelect={onComponentSelect}
                                        onComponentRemove={onComponentRemove}
                                        onLayoutChange={onLayoutChange}
                                        path={currentPath}
                                        key={row.id}
                                        id={row.id}
                                        section={row}
                                      />
                                    </React.Fragment>
                                  )
                                })}
                              </div>
                            </div>
                          </div>
                        )}
                      </Paper>
                    )}

                    {isDynamicPage(layoutData?.layout_type) && currentTab === 1 && (
                      <Paper elevation={3} style={{ minHeight: '400px', padding: '20px' }}>
                        <Typography variant='h6' gutterBottom>
                          JSON Editor
                        </Typography>
                        <ReactJson
                          src={layoutStructure}
                          theme='rjv-default'
                          onEdit={({ updated_src }) =>
                            handleJsonEditorChange(JSON.stringify(updated_src, null, 2))
                          }
                          onAdd={({ updated_src }) =>
                            handleJsonEditorChange(JSON.stringify(updated_src, null, 2))
                          }
                          onDelete={({ updated_src }) =>
                            handleJsonEditorChange(JSON.stringify(updated_src, null, 2))
                          }
                          style={{ width: '100%', minHeight: '700px' }}
                        />
                      </Paper>
                    )}

                    {((isDynamicPage(layoutData?.layout_type) && currentTab === 2) ||
                      (!isDynamicPage(layoutData?.layout_type) && currentTab === 0)) && (
                      <Paper elevation={3} style={{ minHeight: '400px', padding: '20px' }}>
                        <Typography variant='h6' gutterBottom>
                          Page Attributes
                        </Typography>
                        <div>
                          <form onSubmit={onSubmit}>
                            <Grid container spacing={2}>
                              <Grid item xs={4}>
                                <TextField
                                  label='Title'
                                  variant='outlined'
                                  fullWidth
                                  value={layoutData.title || ''}
                                  onChange={(e) => handleInputChange('title', e.target.value)}
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <TextField
                                  label='Slug'
                                  variant='outlined'
                                  fullWidth
                                  value={layoutData.slug || ''}
                                  onChange={(e) => handleInputChange('slug', e.target.value)}
                                  disabled={
                                    isPageGroup(layoutData?.layout_type) ||
                                    isCollection(layoutData?.layout_type)
                                  }
                                />
                              </Grid>

                              <Grid item xs={4}>
                                <TextField
                                  label='Page Route'
                                  variant='outlined'
                                  fullWidth
                                  value={layoutData.page_route || ''}
                                  onChange={(e) => handleInputChange('page_route', e.target.value)}
                                  disabled={
                                    isPageGroup(layoutData?.layout_type) ||
                                    isCollection(layoutData?.layout_type)
                                  }
                                />
                              </Grid>

                              <Grid item xs={4}>
                                <TextField
                                  label='CSS Classname'
                                  variant='outlined'
                                  fullWidth
                                  value={cssClassName}
                                  onChange={(e) => handleInputChange('className', e.target.value)}
                                  disabled={
                                    isPageGroup(layoutData?.layout_type) ||
                                    isCollection(layoutData?.layout_type)
                                  }
                                />
                              </Grid>

                              {isDynamicPage(layoutData?.layout_type) && (
                                <Grid item xs={12}>
                                  <TextareaAutosize
                                    minRows={3}
                                    maxRows={10}
                                    placeholder='Description'
                                    style={{ width: '100%', padding: '10px', marginTop: '15px' }}
                                    value={layoutData.description || ''}
                                    onChange={(e) =>
                                      handleInputChange('description', e.target.value)
                                    }
                                  />
                                </Grid>
                              )}

                              <Grid item xs={3}>
                                <FormControl fullWidth variant='outlined'>
                                  <InputLabel>Display Mode</InputLabel>
                                  <Select
                                    label='Display Mode'
                                    value={
                                      layoutData.settings && layoutData.settings.display_mode
                                        ? layoutData.settings.display_mode
                                        : 'full-width'
                                    }
                                    onChange={(e) =>
                                      handleInputChange('display_mode', e.target.value)
                                    }
                                  >
                                    <MenuItem value='auto'>Auto</MenuItem>
                                    <MenuItem value='full-width'>Full Width</MenuItem>
                                    <MenuItem value='actual-size'>Actual Size</MenuItem>
                                  </Select>
                                </FormControl>
                              </Grid>
                              <Grid item xs={3}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={layoutData.sidebar_item || false}
                                      onChange={(e) =>
                                        handleInputChange('sidebar_item', e.target.checked)
                                      }
                                    />
                                  }
                                  label='Sidebar Item'
                                />
                              </Grid>
                              <Grid item xs={3}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={layoutData.enabled || false}
                                      onChange={(e) =>
                                        handleInputChange('enabled', e.target.checked)
                                      }
                                    />
                                  }
                                  label='Enabled'
                                />
                              </Grid>

                              {isStaticPage(layoutData?.layout_type) && (
                                <Grid item xs={4}>
                                  <PageSelector
                                    meta_data={layoutData?.meta_data}
                                    defaultSelected={layoutData?.meta_data?.component_path}
                                    onChange={handleInputChange}
                                  />
                                </Grid>
                              )}

                              {(isDynamicPage(layoutData?.layout_type) ||
                                isStaticPage(layoutData?.layout_type)) && (
                                <FilterInput
                                  defaultFilters={searchFilters}
                                  onUpdateFilters={handleUpdateFilters}
                                />
                              )}
                            </Grid>
                          </form>
                        </div>
                      </Paper>
                    )}
                  </Grid>

                  {isDynamicPage(layoutData?.layout_type) && (
                    <Grid item xs={2} style={{ flex: 1 }}>
                      <Paper elevation={3} style={{ minHeight: '400px', padding: '10px' }}>
                        <div style={{ flex: 1 }}>
                          {isDynamicPage(layoutData?.layout_type) && (
                            <DragPanel
                              onComponentsManuallySelected={onComponentsManuallySelected}
                              devMode={devMode}
                            />
                          )}

                          <div>
                            <h3>Custom Styling</h3>
                            <Accordion>
                              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant='h6'>Custom CSS</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <TextareaAutosize
                                  minRows={4}
                                  placeholder='Add your custom css'
                                  style={{ width: '100%', padding: '10px', marginTop: '15px' }}
                                  value={layoutData.custom_css || ''}
                                  onChange={(e) => handleInputChange('custom_css', e.target.value)}
                                />
                              </AccordionDetails>
                            </Accordion>
                          </div>
                          {isDynamicPage(layoutData?.layout_type) && (
                            <div className='selected-component'>
                              <h3>Current Component:</h3>
                              <Accordion>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                  {selectedWidget && selectedWidget?.id}
                                  {/*<Typography variant="h6">Custom CSS</Typography>*/}
                                </AccordionSummary>
                                <AccordionDetails></AccordionDetails>
                              </Accordion>
                            </div>
                          )}
                        </div>
                      </Paper>
                    </Grid>
                  )}
                </DndContext>
              </React.Fragment>
            </Grid>
          </ScrollX>
        )}
      </MainCard>
    </>
  )
}

export default LayoutBuilder
