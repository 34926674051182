export type DisplayNameSchema = Record<string, { newName?: string; columns?: DisplayName }>
export type DisplayName = Record<string, string>

export function getDisplayName(
  schemaRenamings: Record<string, { newName?: string; columns?: Record<string, string> }>,
  modelName: string,
  columnName?: string
): string {
  if (columnName) {
    return schemaRenamings[modelName]?.columns?.[columnName] || columnName
  }
  return schemaRenamings[modelName]?.newName || modelName
}
