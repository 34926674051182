import { Dispatch, MutableRefObject, SetStateAction } from 'react'

import { ColDef, ICellRendererParams, IServerSideGetRowsParams } from 'ag-grid-community'

import { QueryClient } from '@tanstack/react-query'

import { humanizeFieldName } from '@utils/string-utils'

import { AG_RIGHT_ALIGNED_CELL } from '@components/ag-grid/types'
import { autoFitColumnsWithAPI } from '@components/ag-grid/utils'

import { MODEL_DOES_NOT_EXIST } from '@store/slices/component/query-config'

import { CHARGER_MONOSPACE_CLASS } from 'config'
import { QUERY_STALE_TIME_SHORT } from 'queries/constants'

import {
  QUERY_KEY_PREFIX,
  StagedComponentBody,
  fetchComponentStagedData
} from '../queries/fetch-component-staged-data'
import { IComponentResultsData } from '../types'
import { IDataType, IHeader } from '../types/component-types'

export class ServerSideDatasource {
  firstLoad = true

  constructor(
    private client: QueryClient,
    private businessIdRef: MutableRefObject<number | undefined>,
    private componentConfigRef: MutableRefObject<StagedComponentBody | undefined>,
    private setHiddenColumsDefs: Dispatch<SetStateAction<ColDef[]>>,
    private showFiltersAfterGridReadyRef: MutableRefObject<boolean>
  ) {}

  async getRows(params: IServerSideGetRowsParams) {
    let body: StagedComponentBody | undefined = this.componentConfig

    if (
      !body ||
      !body.query?.model ||
      body.query?.model === MODEL_DOES_NOT_EXIST ||
      !this.businessIdRef.current
    ) {
      params.api.setGridOption('columnDefs', [])
      params.success({ rowData: [], rowCount: 0 })
      return
    }

    const offset = params.request.startRow || 0

    body = _.cloneDeep(body)
    if (body.parameters) {
      body.parameters.offset = offset
    }

    let response = this.client.getQueryData([
      QUERY_KEY_PREFIX,
      this.businessIdRef.current,
      body
    ]) as IComponentResultsData

    try {
      if (!response) {
        response = await this.client.fetchQuery({
          queryKey: [QUERY_KEY_PREFIX, this.businessIdRef.current, body],
          queryFn: () => fetchComponentStagedData(body, this.businessIdRef.current as number),
          staleTime: QUERY_STALE_TIME_SHORT
        })
      }

      const { results, headers, results_count } = response
      const rowCount = results_count || results?.length || 0

      const columnDefs = this.getColumnDefs(headers)
      params.api.setGridOption('columnDefs', columnDefs)
      const hiddenColumnDefs = this.getHiddenColumnDefs(headers)
      this.setHiddenColumsDefs(hiddenColumnDefs)

      if (this.firstLoad) {
        this.firstLoad = false

        this.initializeSorts(params)
        this.initializeFilters(params)
      }

      if (this.showFiltersAfterGridReady) {
        this.showFiltersAfterGridReadyRef.current = false
        params.api.showAdvancedFilterBuilder()
      }

      params.success({ rowData: results, rowCount })
      autoFitColumnsWithAPI(params.api)
    } catch (_error) {
      params.fail()
    }
  }

  getColumnDefs(headers: IHeader[]) {
    return headers
      .filter((item) => !item.is_hidden)
      .map((item) => {
        const header: ColDef = {
          field: item.name,
          headerName: humanizeFieldName(item.name),
          filterParams: {},
          cellDataType: item.data_type,
          suppressHeaderMenuButton: true
        }

        if (item.data_type === IDataType.BOOLEAN) {
          header.filterParams.filterOptions = ['true', 'false', 'blank']
        }

        if (item.data_type === IDataType.NUMBER) {
          header.type = 'rightAligned'
          header.cellClass = `${CHARGER_MONOSPACE_CLASS} ${AG_RIGHT_ALIGNED_CELL}`
          header.valueGetter = (params) => {
            const value = _.get(params, ['data', item.name])
            if ([null, undefined, ''].includes(value)) {
              return null
            }
            return +value
          }
          header.valueFormatter = (params) => {
            return params.value === null ? '-' : params.value
          }
        }

        if (item.data_type === IDataType.DATE_STRING) {
          header.cellClass = CHARGER_MONOSPACE_CLASS
          header.cellRenderer = (params: ICellRendererParams) => params.value
        }

        if (item.previous_period) {
          header.headerClass = ' ag-header-cell-previous-period'
        } else if (item.is_aggregate) {
          header.headerClass = 'ag-header-cell-aggregate'
        } else if (item.formula) {
          header.headerClass = 'ag-header-cell-formula'
        }

        if (this.paginated && item.formula) {
          header.filter = false
          header.sortable = false
        }

        return header
      })
  }

  getHiddenColumnDefs(headers: IHeader[]) {
    return headers
      .filter((item) => item.is_hidden)
      .map((item) => {
        const header: ColDef = {
          field: item.name,
          hide: true,
          cellDataType: item.data_type,
          filterParams: {},
          suppressHeaderMenuButton: true
        }

        switch (item.data_type) {
          case IDataType.NUMBER:
            header.filter = 'agNumberColumnFilter'
            break
          case IDataType.BOOLEAN:
            header.filter = 'agSetColumnFilter'
            header.filterParams.values = ['true', 'false', 'blank']
            header.filterParams.suppressSorting = true
            header.cellDataType = 'text'
            break
          case IDataType.DATE_STRING:
            header.filter = 'agDateColumnFilter'
            break
          case IDataType.TEXT:
          default:
            header.filter = 'agTextColumnFilter'
            break
        }
        header.filterParams.debounceMs = 1000

        return header
      })
  }

  initializeFilters(params: IServerSideGetRowsParams) {
    const filtersState = this.componentConfig?.filters

    // This will trigger onFilterChanged event, and make another request to the server.
    // https://stackoverflow.com/a/52781490/6892277
    params.api.setAdvancedFilterModel(filtersState || null)
  }

  initializeSorts(params: IServerSideGetRowsParams) {
    // this re-triggers the grid to fetch data again
    params.api.applyColumnState({
      state: this.componentConfig?.sorts || [],
      defaultState: { sort: null }
    })
  }

  get componentConfig() {
    return this.componentConfigRef.current
  }

  get paginated() {
    return _.get(this.componentConfig, 'paginated', false)
  }

  get showFiltersAfterGridReady() {
    return this.showFiltersAfterGridReadyRef.current
  }
}
