import React, { useEffect, useMemo, useRef, useState } from 'react'
import ReactSelectMulti, {
  ClearIndicatorProps,
  MenuListProps,
  MultiValue,
  components,
  createFilter
} from 'react-select'
import { ValueContainerProps } from 'react-select/dist/declarations/src/components/containers'

import { VirtualItem, useVirtualizer } from '@tanstack/react-virtual'

import { isNotBlank } from '@utils/lodash'

import { Check, Icon } from '@components/icons'

import { Text } from './text'

const NON_VIRTUALIZED_ITEM_LIMIT = 1500
const AVERAGE_CHARACTER_WIDTH = 8
const PILL_MARGIN = 8
const PILL_HORIZONTAL_PADDING = 8
const LINE_HEIGHT = 32
const ALL = 'all'

export const MultiSelect = ({
  options,
  defaultSelectedValues,
  onBlur,
  onChange,
  ref
}: {
  options: MultiValue<{ label: string; value: string | number }>
  defaultSelectedValues?: Array<string | number>
  onBlur?: (
    selectedOptions: MultiValue<{ label: string; value: string | number }>,
    setSelectedOptions: any
  ) => void
  onChange?: (selectedOptions: MultiValue<{ label: string; value: string | number }>) => void
  ref?: any
}) => {
  const [menuListScrollOffset, setMenuListScrollOffset] = useState(0)

  const [selectedOptions, setSelectedOptions] = useState<
    MultiValue<{ label: string; value: string | number }>
  >([])

  const renderVirtualized = options.length > NON_VIRTUALIZED_ITEM_LIMIT

  useEffect(() => {
    if (!defaultSelectedValues) return

    const defaultOptions = options
      .filter((option) => defaultSelectedValues.includes(option.value))
      .sort((a, b) => a.label.localeCompare(b.label))

    setSelectedOptions(defaultOptions)
  }, [defaultSelectedValues, options])

  const allOptions = useMemo(() => {
    return [{ label: 'Select All', value: ALL }, ...options].sort((a, b) => {
      if (a.value === ALL) return -1
      if (b.value === ALL) return 1

      return a.label.localeCompare(b.label)
    })
  }, [options])

  const IconOption = (props: any) => {
    return (
      <components.Option {...props}>
        <div className='flex justify-between'>
          {props.data.label}
          {props.isSelected && <Icon icon={<Check />} />}
        </div>
      </components.Option>
    )
  }

  const ClearIndicator = (props: ClearIndicatorProps<any>) => {
    const {
      innerProps: { ref, ...restInnerProps }
    } = props

    return (
      <div {...restInnerProps} ref={ref} className='py-0 pr-2'>
        <Text variant='button' className='cursor-pointer text-grey-dark'>
          Clear All
        </Text>
      </div>
    )
  }

  const VirtualizedMenuList = (props: MenuListProps<any>) => {
    const { children, maxHeight, innerProps } = props

    const parentRef = useRef<HTMLDivElement>(null)

    const rowVirtualizer = useVirtualizer({
      count: React.Children.count(children), // Using props.options.length will not recalculate the height of the list when filtered
      getScrollElement: () => parentRef.current!,
      estimateSize: () => 36,
      overscan: 15,
      gap: 4,
      initialOffset: menuListScrollOffset
    })

    return (
      <div ref={parentRef} {...innerProps} style={{ maxHeight }} className='overflow-auto'>
        <div className='relative w-full' style={{ height: `${rowVirtualizer.getTotalSize()}px` }}>
          {rowVirtualizer.getVirtualItems().map((virtualItem: VirtualItem) => {
            return (
              <div
                key={virtualItem.key}
                onClick={() => setMenuListScrollOffset(parentRef.current?.scrollTop || 0)}
                className='absolute left-0 top-0 w-full border'
                style={{
                  transform: `translateY(${virtualItem.start}px)`,
                  height: `${virtualItem.size}px`
                }}
              >
                {(children as any)[virtualItem.index]}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <ReactSelectMulti
      ref={ref}
      onInputChange={(inputValue) => {
        setMenuListScrollOffset(0)
      }}
      controlShouldRenderValue={true}
      placeholder='Select Options'
      menuPortalTarget={document.body}
      menuPlacement={'bottom'}
      menuPosition={'fixed'}
      closeMenuOnSelect={false}
      blurInputOnSelect={false}
      hideSelectedOptions={false}
      isMulti
      value={selectedOptions}
      options={allOptions}
      backspaceRemovesValue={false}
      filterOption={createFilter({ ignoreAccents: false })} // Improves performance for large data when searching
      onBlur={(e) => {
        setMenuListScrollOffset(0)
        onBlur && onBlur(selectedOptions, setSelectedOptions)
      }}
      onChange={(selected) => {
        if (onChange) {
          onChange(selected)
          return
        }

        if (selected.length && selected.find((option) => option.value === ALL)) {
          setSelectedOptions(allOptions.slice(1).sort((a, b) => a.label.localeCompare(b.label)))
        } else {
          const sortedSelected = Array.from(selected).sort((a, b) => a.label.localeCompare(b.label))

          setSelectedOptions(sortedSelected)
        }
      }}
      components={{
        MenuList: VirtualizedMenuList,
        Option: IconOption,
        ClearIndicator: ClearIndicator,
        ValueContainer: renderVirtualized
          ? ValueContainerVirtualizedDynamic
          : components.ValueContainer,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null
      }}
      classNames={{
        valueContainer: () =>
          renderVirtualized ? 'w-full' : 'w-full max-h-[164px] overflow-y-auto',
        control: () => 'text-black relative flex flex-col items-start rounded-none',
        option: (state) =>
          state.isSelected
            ? 'bg-primary-lighter text-black'
            : state.isFocused
              ? 'bg-grey-lighter'
              : '',
        indicatorsContainer: () =>
          isNotBlank(selectedOptions)
            ? 'p-0 h-[20px] flex items-center justify-end border-t border-b-0 border-x-0  border-solid border-grey-light'
            : ''
      }}
    />
  )
}

// IMPORTANT: If you modify this component, verify that onBlur is triggered, when you select all or clear all option and then click outside the dropdown
const ValueContainerVirtualizedDynamic = ({ children, ...props }: ValueContainerProps<any>) => {
  const parentRef = useRef<HTMLDivElement>(null)
  const [containerWidth, setContainerWidth] = useState(0)

  // NOTE: When Multiple Values are Selected, the First Children is an Array of Selected Values, the Second Child is the Input Field
  // When no option is selected first child is the placeholder text | When no option is selected and input has search text, first child is null
  const firstChild = (children as any)[0]
  const inputChild = (children as any)[1]

  const hasSelectedOptions = Array.isArray(firstChild)
  const itemCount = hasSelectedOptions ? firstChild.length : 0

  // Update container width when parentRef.current changes
  useEffect(() => {
    if (!parentRef.current) return

    const handleResize = () => {
      const width = parentRef.current!.offsetWidth - PILL_HORIZONTAL_PADDING // Subtract left and right padding (8px each)
      setContainerWidth(width)
    }

    handleResize() // Initial width

    // Use ResizeObserver to watch for changes in container size
    const resizeObserver = new ResizeObserver(() => handleResize())
    resizeObserver.observe(parentRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  })

  // Estimate widths of each pill
  const itemWidths = useMemo(() => {
    if (!hasSelectedOptions) return []

    return firstChild.map((pill) => {
      // Extract the text from the pill component
      const text = pill.props.children
      const textWidth = AVERAGE_CHARACTER_WIDTH * text.length

      // Add padding and margins
      return textWidth + PILL_HORIZONTAL_PADDING + PILL_MARGIN
    })
  }, [firstChild, hasSelectedOptions])

  // Function to simulate wrapping within a row and calculate line count
  const simulateRowWrapping = (pillWidths: number[], containerWidth: number) => {
    let lineCount = 1
    let accumulatedWidth = 0

    for (let i = 0; i < pillWidths.length; i++) {
      const pillWidth = pillWidths[i]

      if (accumulatedWidth + pillWidth > containerWidth - PILL_MARGIN && accumulatedWidth > 0) {
        lineCount += 1
        accumulatedWidth = pillWidth
      } else {
        accumulatedWidth += pillWidth
      }
    }
    return { lineCount }
  }

  // Group items into rows and calculate line counts
  const itemsByRow = useMemo(() => {
    if (!hasSelectedOptions || containerWidth === 0) return []

    const rows = []
    let currentRowItems = []
    let currentRowWidths: number[] = []
    let currentRowWidth = 0

    for (let i = 0; i < itemCount; i++) {
      const pillWidth = itemWidths[i]

      // If adding the next pill exceeds container width, start a new row
      if (
        currentRowWidth + pillWidth > containerWidth - PILL_MARGIN &&
        currentRowItems.length > 0
      ) {
        // Before pushing the row, compute lineCount
        const { lineCount } = simulateRowWrapping(currentRowWidths, containerWidth)
        rows.push({ items: currentRowItems, lineCount })
        currentRowItems = [firstChild[i]]
        currentRowWidths = [pillWidth]
        currentRowWidth = pillWidth
      } else {
        currentRowItems.push(firstChild[i])
        currentRowWidths.push(pillWidth)
        currentRowWidth += pillWidth
      }
    }

    if (currentRowItems.length > 0) {
      const { lineCount } = simulateRowWrapping(currentRowWidths, containerWidth)
      rows.push({ items: currentRowItems, lineCount })
    }

    return rows
  }, [firstChild, itemWidths, containerWidth, itemCount, hasSelectedOptions])

  // Use virtualization for the rows with variable sizes
  const rowVirtualizer = useVirtualizer({
    count: itemsByRow.length,
    getScrollElement: () => parentRef.current!,
    estimateSize: () => LINE_HEIGHT,
    overscan: 5,
    measureElement: (el) => el.getBoundingClientRect().height
  })

  return (
    <components.ValueContainer {...props}>
      {/* Render placeholder when no options are selected */}
      {!hasSelectedOptions && <div>{firstChild}</div>}

      {/* Virtualized rows */}
      {hasSelectedOptions && (
        <div
          style={{ maxHeight: '164px' }}
          ref={parentRef}
          className='max-h-[164px] w-full overflow-y-auto'
        >
          <div style={{ height: `${rowVirtualizer.getTotalSize()}px`, position: 'relative' }}>
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = itemsByRow[virtualRow.index]
              const rowItems = row.items
              return (
                <div
                  key={virtualRow.key}
                  className='absolute left-0 top-0 flex w-full flex-wrap'
                  style={{
                    transform: `translateY(${virtualRow.start}px)`,
                    height: `${virtualRow.size}px`,
                    overflow: 'hidden' // Ensure content doesn't overflow the container
                  }}
                >
                  {rowItems.map((item, idx) => (
                    <div key={idx} className='mb-2 mr-2'>
                      {item}
                    </div>
                  ))}
                </div>
              )
            })}
          </div>
        </div>
      )}

      {/* Render the input field */}
      <div className='w-full'>{inputChild}</div>
    </components.ValueContainer>
  )
}
